import React, { useState, useEffect } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import { hasPermission } from '../../utils/Permissions';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import { Link, usePage } from '@inertiajs/inertia-react'
import PaginationList from '../../Shared/Components/PaginationList';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Inertia } from '@inertiajs/inertia'

const List = ({ items, companies}) => {
    const { flash } = usePage().props
    const data = items.data;

    const query = new URL(window.location).searchParams;
    const [filter_type, setFilterType] = useState(query.get("filter_type") || 'all');
    const [company_id, setCompanyId] = useState(query.get("company_id") || '');
    const [policy_number, setPolicyNumberText] = useState(query.get("policy_number") || '');

    useEffect(() => {
        setFilterType(query.get("filter_type") || 'all');
        setCompanyId(query.get("company_id") || '');
        setPolicyNumberText(query.get("policy_number") || '');
    }, []);

    function handleChangeFilterType(e) {
        setFilterType(e.target.value);
    }

    function handleChangeCompany(e) {
        setCompanyId(e.target.value);
    }

    function handleChangePolicyNumber(e) {
        setPolicyNumberText(e.target.value);
    }

    function search() {
        if(filter_type == 'company'){
            if(company_id == ''){
                Swal.fire({
                    icon: "warning",
                    title: "Oops...",
                    text: "Debes seleccionar un retenedor",
                });
                return;
            }
        }else if(filter_type == 'policy'){
            if(policy_number == ''){
                Swal.fire({
                    icon: "warning",
                    title: "Oops...",
                    text: "Debes escribir un número de póliza",
                });
                return;
            }
        }

        Inertia.get(route('investment_fund_percentage.list'),
            {
                filter_type: filter_type,
                company_id: company_id,
                policy_number: policy_number,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        )
    }

    return (
        <Row>
            <span className="pb-7 pt-7">
                <Breadcrumbs currentPath="/admin/master_data/investment_fund_percentage" />
            </span>
            <Col className="mb-2 px-1">
                <h2>Histórico de porcentaje de fondo de inversión</h2>
            </Col>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            <Row className="mb-4 px-1">
                <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex justify-content-around gap-5">
                        <div>
                            <Form.Check
                                className="mb-3"
                                name="filter_type"
                                type="radio"
                                label="Todas las pólizas"
                                value="all"
                                id="all"
                                defaultChecked={(filter_type == 'all' ? true : false)}
                                onChange={e => setFilterType('all')}
                            />
                        </div>
                        <div>
                            <Form.Check
                                className="mb-3"
                                name="filter_type"
                                type="radio"
                                label="Retenedor"
                                value="company"
                                id="company"
                                defaultChecked={(filter_type == 'company' ? true : false)}
                                onChange={e => setFilterType('company')}
                            />
                            <Form.Select className="form-control mx-2" value={company_id} onChange={handleChangeCompany}>
                                <option key='blankChoice' value={''}>Selecciona el retenedor (Empresa)</option>
                                {companies.length > 0 && (
                                    companies.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.name}</option>;
                                    }))}
                            </Form.Select>
                        </div>
                        <div>
                            <Form.Check
                                className="mb-3"
                                name="filter_type"
                                type="radio"
                                label="Individual"
                                value="policy"
                                id="policy"
                                defaultChecked={(filter_type == 'policy' ? true : false)}
                                onChange={e => setFilterType('policy')}
                            />
                            <Form.Control value={policy_number} placeholder='Número de póliza' type="text" onChange={handleChangePolicyNumber} />
                        </div>
                        <div>
                            <div className="mb-3">&nbsp;</div>
                            <Button onClick={search} variant="primary" className='btn btn-primary' type="submit" >Filtrar</Button>
                        </div>
                    </div>
                    <div className="mx-0 h-auto">
                        <Link href={ route('investment_fund_percentage.add') } className='btn btn-primary'>Agregar Nuevo Porcentaje</Link>
                    </div>
                </div>
            </Row>
            <div className="card card-flush h-md-100">
                {/* <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="text-gray-400 mt-1 fw-semibold fs-6">Ultima Actualización: {latest_update}</span>
                    </h3>
                </div> */}
                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-0 pb-3 min-w-175px text-center">MONTO</th>
                                    <th className="p-0 pb-3 min-w-175px text-center">FECHA DE CREACIÓN</th>
                                    <th className="p-0 pb-3 min-w-175px text-center">FECHA EFECTIVA</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">USUARIO</th>
                                    {/* <th className="p-0 pb-3 w-50px text-end"></th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="4"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center fw-bold fs-6">
                                            {item.type_amount=='amount' ? '$' : ''}{item.amount}{item.type_amount=='percentage' ? '%' : ''}
                                            </td>
                                            <td className="text-center fw-bold fs-6">
                                                {item.created_at}
                                            </td>
                                            <td className="text-center fw-bold fs-6">
                                                {item.application_date}
                                            </td>
                                            <td className="fw-bold fs-6">
                                                {item.user.name}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <br />
                    <PaginationList links={items.links} />

                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Histórico de porcentaje de fondo de inversión" />

export default List
