import { Inertia } from '@inertiajs/inertia'
import React, { useState } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const Add = () => {

    const { data, setData, post, processing, errors } = useForm({
        name: '',
        percentaje: '',
        active: 1,
      })

      function submit(e) {
        e.preventDefault()
        post(route('taxregimes.create'))
      }
    return (
        <Row>
            <span className="pb-7 pt-7">
                <Breadcrumbs currentPath="/admin/master_data/taxregimes/add" />
            </span>
            <h2>Agregar Régimen Fiscal</h2>
            <Form  onSubmit={submit}>
                <Form.Group className="mb-3" controlId="formAdd">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.name) ? true : false,
                    })}
                    onChange={e => setData('name', e.target.value)} aria-invalid={errors.name ? "true" : "false"} />
                    {errors.name && <div className="mt-2 text-danger">{errors.name}</div>}
                </Form.Group>
                <Form.Group  as={Col} sm={3} className="mb-3">
                    <Form.Label>Porcentaje</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" min="0" step=".1" max="100" onChange={e => setData('percentage', e.target.value)} className={classNames({ 'is-invalid': (errors.percentage) ? true : false, })} aria-invalid={errors.percentage ? "true" : "false"} />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    {errors.percentage && <div className="mt-2 text-danger">{errors.percentage}</div>}
                </Form.Group>
                <Row className="mb-6">
                    <Form.Group as={Col} controlId="formGridVeritas">
                        <Form.Label>Activo</Form.Label>
                        <InputGroup>
                            <Form.Check
                                inline
                                name="active"
                                type="radio"
                                label="Si"
                                value="1"
                                id="1"
                                defaultChecked={(data.active == 1 ? true : false)}
                                onChange={e => setData('active', 1)} className={classNames({ 'is-invalid': (errors.active) ? true : false, })} aria-invalid={errors.active ? "true" : "false"} />
                            <Form.Check
                                inline
                                name="active"
                                type="radio"
                                label="No"
                                value="0"
                                id="0"
                                defaultChecked={(data.active == 0 ? true : false)}
                                onChange={e => setData('active', 0)} className={classNames({ 'is-invalid': (errors.active) ? true : false, })} aria-invalid={errors.active ? "true" : "false"} />
                        </InputGroup>
                        {errors.active && <div className="mt-2 text-danger">{errors.active}</div>}
                    </Form.Group>
                </Row>
                <Button variant="primary" className='me-3'  disabled={processing} type="submit">Guardar</Button>
                <Button onClick={()=>window.history.back()} variant="secondary" type="normal">Regresar</Button>
            </Form>
        </Row>
    );
}

Add.layout = page => <Layout children={page} title="Agregar Régimen Fiscal" />

export default Add
