import React, { useState, useRef, useEffect } from 'react';

function FilterMenu({children}) {
    const [open, setOpen] = useState(false);

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e)=>{
            if(
                !menuRef.current.contains(e.target) ||
                e.target.classList.contains('close-filter-menu')
            ){
                setTimeout(() => {
                    setOpen(false);
                }, 100)
            }      
        };
    
        document.addEventListener("click", handler);
        
        return() => {
            document.removeEventListener("click", handler);
        }
    });

    return (
        <div className="position-relative" ref={menuRef}>
            <a className="btn btn-sm btn-flex btn-light btn-active-light-primary fw-bold" onClick={()=>{setOpen(!open)}}>
                <span className="svg-icon svg-icon-6 svg-icon-muted me-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
                    </svg>
                </span>
                Filtrar
            </a>
            <div className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px position-absolute end-0 ${open ? 'show' : ''}`} data-popper-placement="bottom-end">
                { children }
            </div>
        </div>
    )
}

export default FilterMenu;
