import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import classNames from "classnames";
import { Link, usePage } from '@inertiajs/inertia-react';
import "react-datepicker/dist/react-datepicker.css";
import { Inertia } from '@inertiajs/inertia';
import { hasPermission } from '../../utils/Permissions';
import ReactPaginate from "react-paginate";

const PaymentsHistoryByMaster = forwardRef((props, ref) => {
    const { flash, auth } = usePage().props;
    const [errors, setErrors] = useState({});

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => {
        setDialog(false);
        resetFormFields();
        fetchData();
    };
    const showDialog = () => setDialog(true);

    const resetFormFields = () => {
        setStartYear("2000");
        setStartFortnight('*');
        setEndYear(moment().year().toString());
        setEndFortnight('*');
    };

    const fetchData = () => {
        console.log("Buscando registros...");
    };

    useEffect(() => {
        if (!dialog) {
            resetFormFields();
            fetchData();
        }
    }, [dialog]);

    useImperativeHandle(ref, () => {
        return {
            openDialog(master){
                setMaster(master);
                //setStartYear('2000');
                // setStartFortnight('*');
                // setEndYear(moment().year().toString());
                // setEndFortnight('*');
                showDialog();

                getData(master.id, 1);
            },
        }
    });

    /* FILTERS */

    const [startYear, setStartYear] = useState("2000");

    function handleChangeStartYear(e) {
        setStartYear(e.target.value);
    }

    const [startFortnight, setStartFortnight] = useState('*');

    function handleChangeStartFortnight(e) {
        setStartFortnight(e.target.value);
    }

    const [endYear, setEndYear] = useState(moment().year());

    function handleChangeEndYear(e) {
        setEndYear(e.target.value);
    }

    const [endFortnight, setEndFortnight] = useState('*');

    function handleChangeEndFortnight(e) {
        setEndFortnight(e.target.value);
    }

    /* GET DATA */

    const [history, setHistory] = useState([]);
    const [coverageName, setCoverageName] = useState([]);
    const [master, setMaster] = useState([]);

    function getData(master_id, page_number){
        if(!isValid()) return;

        axios.post(route('payments_history.getMasterHistory', master_id), {
            'startYear' : startYear,
            'startFortnight' : startFortnight,
            'endYear' : endYear,
            'endFortnight' : endFortnight,
            'page' : page_number,
        })
            .then((response) => {
                if(page_number==1){
                    setRemountComponent(Math.random()); // refresh pagination when the page is 1!
                }
                setHistory(response.data['items']);
                setCoverageName(response.data['coverage_name']);
            }).catch((error) => {
                toastr.error("Algo salió mal, intentalo nuevamente");
            });

    }

    function isValid(){
        // if(parseInt(endYear) < parseInt(startYear)){
        //     Swal.fire({
        //         icon: "warning",
        //         title: "Oops...",
        //         text: "El año final no puede ser menor al inicial",
        //     });
        //     return false;
        // }else if(parseInt(endYear) == parseInt(startYear)){
        //     var init = startFortnight=='*' ? 30 : parseInt(startFortnight);
        //     var end = endFortnight=='*' ? 30 : parseInt(endFortnight);
        //     if(parseInt(end) < parseInt(init)){
        //         Swal.fire({
        //             icon: "warning",
        //             title: "Oops...",
        //             text: "La quincena final no puede ser menor al inicial",
        //         });
        //         return false;
        //     }
        // }

        if(parseInt(endYear+""+(endFortnight=='*'?'30':endFortnight)) < parseInt(startYear+""+(startFortnight=='*'?'00':startFortnight))){
            Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "El rango final no puede ser menor al inicial",
            });
            return false;
        }

        return true;
    }

    function exportHistory(type, master_id) {
        if (!isValid()) return;

        const params = new URLSearchParams({
            startYear: startYear,
            startFortnight: startFortnight,
            endYear: endYear,
            endFortnight: endFortnight,
        }).toString();

        let url;
        if (type === 'pdf') {
            url = route('payments_history.master.pdf.export', { masterId: master_id }) + `?${params}`;
        } else if (type === 'excel') {
            url = route('payments_history.master.excel.export', { masterId: master_id }) + `?${params}`;
        }
        window.location.href = url;
    }

    /* List of Years & fortnight */

    const firstYear = moment('2000-01-01', 'YYYY-MM-DD');
    const diff = moment().year() - firstYear.year() + 1;
    const years = [...Array(diff).keys()].map(i => i + firstYear.year()).reverse();

    const fortnights = [...Array(26).keys()];

    /* Paginate */

    const [remountComponent, setRemountComponent] = useState(0);

    return <>
        {/* <!--begin:Appointments Modal--> */}
        <Modal show={dialog} onHide={closeDialog} scrollable={true} size="xl" id="dialog">
            <Modal.Header closeButton className="py-4">
                <Modal.Title>
                    Histórico de pagos de {master.name} {master.last_name} {master.mother_last_name}
                    <br />
                    {master.rfc}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row className='mb-2'>
                    <div className="d-flex align-items-end justify-content-end">
                        <div className="mr-auto p-2">
                            <a className="btn btn-sm btn-info btn-flex fw-bold" onClick={()=>{exportHistory("pdf", master.id)}}>
                                Exportar a PDF
                            </a>
                        </div>
                        <div className="p-2">
                            <a className="btn btn-sm btn-info btn-flex fw-bold" onClick={()=>{exportHistory("excel", master.id)}}>
                                Exportar a Excel
                            </a>
                        </div>
                    </div>
                </Row>
                <Row className='mb-2'>
                    <Col>
                        <Form.Label className="fw-bolder">Desde</Form.Label>
                        <div className="d-flex gap-3">
                            <div className="w-50">
                                <Form.Label className="mb-0">Año</Form.Label>
                                <Form.Select size="sm" className="form-control" value={startYear} onChange={handleChangeStartYear}>
                                    {years.length > 0 && (
                                        years.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>;
                                        })
                                    )}
                                </Form.Select>
                            </div>
                            <div className="w-50">
                                <Form.Label className="mb-0">Quincena</Form.Label>
                                <Form.Select size="sm" className="form-control" value={startFortnight} onChange={handleChangeStartFortnight}>
                                    <option key='all' value={'*'}>Todas</option>
                                    {fortnights.length > 0 && (
                                        fortnights.map((item, index) => {
                                            return <option key={index} value={String(item+1).padStart(2, '0')}>{String(item+1).padStart(2, '0')}</option>;
                                        })
                                    )}
                                </Form.Select>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <Form.Label className="fw-bolder">Hasta</Form.Label>
                        <div className="d-flex gap-3">
                            <div className="w-50">
                                <Form.Label className="mb-0">Año</Form.Label>
                                <Form.Select size="sm" className="form-control" value={endYear} onChange={handleChangeEndYear}>
                                    {years.length > 0 && (
                                        years.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>;
                                        })
                                    )}
                                </Form.Select>
                            </div>
                            <div className="w-50">
                                <Form.Label className="mb-0">Quincena</Form.Label>
                                <Form.Select size="sm" className="form-control" value={endFortnight} onChange={handleChangeEndFortnight}>
                                    <option key='all' value={'*'}>Todas</option>
                                    {fortnights.length > 0 && (
                                        fortnights.map((item, index) => {
                                            return <option key={index} value={String(item+1).padStart(2, '0')}>{String(item+1).padStart(2, '0')}</option>;
                                        })
                                    )}
                                </Form.Select>
                            </div>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-end gap-3">
                        <Button onClick={() => getData(master.id, 1)} variant="primary" className='btn btn-primary btn-sm' type="submit" >
                            Buscar
                        </Button>
                        {/* <Button onClick={() => exportHistory('pdf')} className='btn btn-sm btn-secondary'>
                            Exportar PDF
                        </Button>
                        <Button onClick={() => exportHistory('excel')} className='btn btn-sm btn-secondary'>
                            Exportar Excel
                        </Button> */}
                    </Col>
                </Row>

                <br />

                {history.data==undefined || history.data.length==0 ? (
                    <div className="fw-semibold text-muted">
                        Sin registros
                    </div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-row-bordered table-row-solid">
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400">
                                    <th className="text-center">Año</th>{/* primeros 4 chars de fortnight */}
                                    <th className="text-start">Quincena</th>{/* ultimos 2 chars de fortnight */}
                                    <th className="text-start">Asegurador</th>{/* ??? nombre de cobertura - poliza si no tiene es nombre de maestro ??? */}
                                    <th className="text-start">Emisor</th>{/* transmitter first 5 digits */}
                                    <th className="text-start">Carpeta</th>{/* transmitter rest of the digits */}
                                    <th className="text-start text-end">Importe Quincenal</th>{/* biweekly amount */}
                                </tr>
                            </thead>

                            <tbody>
                                {history.data.map((item, index) => {
                                    const transmitterStr = String(item.transmitter);
                                    const emisor = transmitterStr.substring(0, 5);
                                    const carpeta = transmitterStr.substring(5);
                                    return (
                                        <tr key={index}>
                                            <td className="text-gray-800 fw-bold text-center">{item.year}</td>
                                            <td className="text-gray-800 fw-bold text-start">{item.fortnight}</td>
                                            <td className="text-gray-800 fw-bold">{coverageName}</td>
                                            <td className="text-gray-800 fw-bold">{emisor}</td>{/* transmitter first 5 digits */}
                                            <td className="text-gray-800 fw-bold">{carpeta}</td>{/* transmitter rest of the digits */}
                                            <td className="text-gray-800 fw-bold text-end">${item.biweekly_amount}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                            <div key={remountComponent}>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Siguiente »"
                                    onPageChange={(page) => getData(master.id, page.selected+1) }
                                    pageRangeDisplayed={5}
                                    pageCount={history.last_page}
                                    previousLabel="« Anterior"
                                    renderOnZeroPageCount={null}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeDialog} className="btn-sm" variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal>
        {/* <!--end:Appointments Modal--> */}
    </>;
})

export default PaymentsHistoryByMaster
