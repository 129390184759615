import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import classNames from "classnames";
import { Link, usePage } from '@inertiajs/inertia-react';
import "react-datepicker/dist/react-datepicker.css";
import { Inertia } from '@inertiajs/inertia';
import { hasPermission } from '../../utils/Permissions';
import ReactPaginate from "react-paginate";

const Withdrawals = forwardRef((props, ref) => {
    const { flash, auth } = usePage().props;
    const [errors, setErrors] = useState({});

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => setDialog(false);
    const showDialog = () => setDialog(true);

    useImperativeHandle(ref, () => {
        return {
            openWithdrawalDialog(policy_id) {
                getData(policy_id);
                getWithdrawals(policy_id, 1);
            },
        }
    });

    /* GET INIT DATA */

    const [hideAmount, setHideAmount] = useState(false);

    const formatCurrency = (value) => {
        const options = {
            style: 'currency',
            currency: 'MXN',
        };
        return value.toLocaleString('es-MX', options);
    };

    function getData(policy_id) {
        setHideAmount(false);
        setErrors({});

        axios.get(route('withdrawal.getData', policy_id))
            .then((response) => {
                var obj = {
                    'policy_id': policy_id,
                    'policy': response.data['policy'],
                    'biweekly_discount': response.data['biweekly_discount'],
                    'investment_fund': response.data['investment_fund'],
                    'max_amount': response.data['max_amount'],
                    'retirement': response.data['retirement'] != '' ? response.data['retirement'] : 0,
                    'issue': response.data['issue'],
                    'paid_up_valid': response.data['paid_up_valid'],
                    'months_last_payment': response.data['months_last_payment'],
                    'paid_up_date': response.data['paid_up_date'],
                    'amount': '',
                    'available_investment_fund': parseFloat(response.data['max_amount']) < parseFloat(response.data['investment_fund'])
                        ? parseFloat(response.data['max_amount'])
                        : parseFloat(response.data['investment_fund']),
                };

                if (obj.investment_fund == '' || obj.max_amount == '') {
                    setErrors({ amount: 'Aún no hay información del Saldo Real' })
                    setHideAmount(true);
                }

                if (parseFloat(obj.max_amount) == 0) {
                    setErrors({ amount: 'Ya se retiró el Fondo de inversión disponible' })
                    setHideAmount(true);
                }
                if (obj.available_investment_fund < parseFloat(obj.max_amount)) {
                    setErrors({ amount: 'Generando dividendos.' })
                    setHideAmount(true);
                }
                if (obj.paid_up_date != '') {
                    if (!obj.paid_up_valid) {
                        setErrors({ amount: 'Pago de primas pendientes.' })
                        setHideAmount(true);
                    }
                } else {
                    setErrors({ amount: 'No hay información de pagos para la póliza.' })
                    setHideAmount(true);
                }

                setWithdrawal(obj);
                showDialog();
            }).catch((error) => {
                toastr.error("Algo salió mal, intentalo nuevamente");
            });
    }

    /* WITHDRAWAL */
    const [withdrawal, setWithdrawal] = useState({});

    function handleWithdrawal(e) {
        const newwithdrawal = { ...withdrawal };
        newwithdrawal[e.target.name] = e.target.value;
        setWithdrawal(newwithdrawal);
    }

    function deleteWithdrawal(id) {
        setErrors({});

        Swal.fire({
            title: "¿Estás seguro de eliminar este retiro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {
                axios.post(route('withdrawal.remove', withdrawal.policy_id), {
                    'withdrawal': id
                })
                    .then((response) => {
                        toastr.success('Se elimino el retiro correctamente');
                        getData(withdrawal.policy_id);
                        getWithdrawals(withdrawal.policy_id, 1);
                        props.onChange();
                    });
            }
        });
    }

    function saveWidthdrawal(e) {
        e.preventDefault();
        setErrors({});

        if (withdrawal.amount == '' || isNaN(withdrawal.amount)) {
            setErrors({ amount: 'El monto a retirar debe ser un número válido' })
            return;
        }

        if (parseFloat(withdrawal.amount) > (parseFloat(withdrawal.max_amount))) {
            setErrors({ amount: 'No puedes retirar mas del monto máximo' })
            return;
        }

        Swal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {

                axios.post(route('withdrawal.add', withdrawal.policy_id), {
                    'amount': withdrawal.amount
                })
                    .then((response) => {
                        toastr.success('Se agregó el retiro correctamente');
                        getData(withdrawal.policy_id);
                        getWithdrawals(withdrawal.policy_id, 1);
                        props.onChange();
                    }).catch((error) => {
                        if (error.response.data.success !== undefined && error.response.data.success === false) {
                            toastr.error(error.response.data.error);
                        } else {
                            setErrors(error.response.data.errors);
                            toastr.error("Hay errores en el formulario");
                        }
                    });

            }
        });
    }

    /* GET WITHDRAWAL HISTORY */

    const [history, setHistory] = useState([]);
    const [total_withdrawals, setTotalWithdrawals] = useState({});

    function getWithdrawals(policy_id, page_number) {

        axios.post(route('withdrawal.history', policy_id), {
            'page': page_number
        })
            .then((response) => {
                setHistory(response.data['items']);
                setTotalWithdrawals(response.data['total_withdrawals']);
            }).catch((error) => {
                toastr.error("Algo salió mal, intentalo nuevamente");
            });

    }

    // Fondo de inversión disponible
    function renderInvestmentInfo() {
        var amountToShow;

        if (hasPermission('quotes.insurance.policy.admin')) {
            // Saldo Real > Fondo de inversión real = Fondo de inversión Real
            if (withdrawal.investment_fund > withdrawal.max_amount) {
                amountToShow = withdrawal.max_amount;
            } else {
                var available_fund = withdrawal.investment_fund - withdrawal.max_amount;
                // Saldo Real - Fondo de inversión anual > 0 = Fondo de inversión disponible : 0.00
                amountToShow = (available_fund > 0) ? available_fund : 0.0;
            }
        } else {
            // Fondo de inversión real > Saldo Real = 0 : Fondo de inversión disponible
            amountToShow = withdrawal.max_amount > withdrawal.investment_fund ? 0.0 : parseFloat(withdrawal.available_investment_fund);
        }
        return formatCurrency(amountToShow);
    }

    function renderInvestmentInfoV2() {
        // Fondo de inversión disponible: output
        // Saldo Real: withdrawal.investment_fund
        // Fondo de inversión real: withdrawal.max_amount
        // Descuento Quincenal: withdrawal.biweekly_discount

        let output;

        if (withdrawal.investment_fund > withdrawal.max_amount) { // Si "Saldo Real" es mayor que "Fondo de inversión real"
            output = withdrawal.max_amount; // "Fondo de inversión disponible" es igual a "Fondo de inversión Real"
        } else { // Y si es menor entonces se deberá hacer la siguiente resta
            const anual_investmend_fund = (withdrawal.biweekly_discount * 24) / 3; // (Descuento quincenal * 24) / 3

            output = withdrawal.investment_fund - anual_investmend_fund; // Saldo Real - Fondo de inversión anual
            output = output > 0 ? output : 0.0; // El mínimo es cero
        }

        return formatCurrency(output);
    }

    return <>
        {/* <!--begin:Appointments Modal--> */}
        <Modal show={dialog} onHide={closeDialog} scrollable={true} size="lg" id="dialog">
            <Modal.Header closeButton className="py-4">
                <Modal.Title>Retiros - #{withdrawal.policy}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row className='mb-2'>
                    <Col>
                        <label className="mb-0 form-label form-label-sm fw-semibold">
                            Vigencia: <strong>{withdrawal.issue}</strong>
                        </label>
                    </Col>
                    <Col>
                    </Col>
                    {
                        hasPermission('quotes.insurance.policy.show_real_balance') && (
                            <Col>
                                <label className="mb-0 form-label form-label-sm fw-semibold">
                                    Fondo de inversión disponible:
                                    <strong>
                                        {renderInvestmentInfoV2()}
                                    </strong>
                                </label>
                            </Col>
                        )
                    }
                </Row>
                <Row className='mb-2'>
                    <Col>
                        <label className="mb-0 form-label form-label-sm fw-semibold">
                            Descuento Quincenal: <strong>{formatCurrency(parseFloat(withdrawal.biweekly_discount))}</strong>
                        </label>
                    </Col>
                    <Col>
                        {hasPermission('quotes.insurance.policy.admin') && (
                            <label className="mb-0 form-label form-label-sm fw-semibold">
                                Saldo Real: <strong>{formatCurrency(parseFloat(withdrawal.investment_fund))}</strong>
                            </label>
                        )}
                    </Col>
                    <Col>
                        {hasPermission('quotes.insurance.policy.admin') && (
                            <label className="mb-0 form-label form-label-sm fw-semibold">
                                Fondo de inversión real: <strong>{formatCurrency(parseFloat(withdrawal.max_amount))}</strong>
                            </label>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="mb-0 form-label form-label-sm fw-semibold">
                            Pagado hasta: <strong>{withdrawal.paid_up_date && moment(withdrawal.paid_up_date).format('DD/MM/YYYY')}</strong>
                        </label>
                    </Col>
                </Row>
                <hr style={{ borderColor: 'var(--kt-border-color)', opacity: '1' }} />
                <Row>
                    <Col>
                        <Row className='mt-4'>
                            <label className="mb-0 form-label form-label-sm fw-semibold">Retiros en el año: <strong>{formatCurrency(parseFloat(withdrawal.retirement))}</strong></label>
                        </Row>
                    </Col>
                    <Col></Col>
                    <Col>
                        <Row className='mt-2'>
                            <label className="mb-0 form-label form-label-sm fw-semibold">Retiros totales: <strong>{formatCurrency(parseFloat(total_withdrawals))}</strong></label>
                        </Row>
                    </Col>
                    <Col>
                        {hasPermission('quotes.insurance.withdrawals.history') && (<>
                            {!hideAmount ? <>
                                <label className="mb-0 form-label form-label-sm fw-semibold text-muted">Monto a retirar</label>
                                <input
                                    onChange={(e) => handleWithdrawal(e)}
                                    value={withdrawal.amount == null ? '' : withdrawal.amount}
                                    name="amount"
                                    type="text"
                                    className={classNames({ 'is-invalid': (errors.amount) ? true : false, 'form-control form-control-sm': true })}
                                    aria-invalid={errors.amount ? "true" : "false"}
                                />
                            </> : <></>}
                        </>)}
                        {errors.amount && <div className="mt-2 text-danger small">{errors.amount}</div>}
                    </Col>
                    <Col>
                        {hasPermission('quotes.insurance.withdrawals.history') && (<>
                            {!hideAmount ? <>
                                <label className="mb-0 form-label form-label-sm fw-semibold text-muted">&nbsp;</label>
                                <Button onClick={(e) => saveWidthdrawal(e)} className="btn-sm d-block" variant="primary">Realizar retiro</Button>
                            </> : <></>}
                        </>)}
                    </Col>
                </Row>

                <br /><br />

                <Row className='align-items-center'>
                    <Col className='md-6 text-start'>
                        <h5>Historial de retiros</h5>
                    </Col>
                    <Col className='md-6 text-end'>
                        {hasPermission('quotes.withdrawals.add') &&
                            <a href={route('withdrawal.excel.import')} className="btn btn-sm btn-info mb-3">Importar Retiros</a>
                        }
                    </Col>
                </Row>
                {history.data == undefined || history.data.length == 0 ? (
                    <div className="fw-semibold text-muted">Aún no hay registros de retiro</div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-row-bordered table-row-solid">
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400">
                                    <th className="text-start">Fecha</th>
                                    <th className="text-center">Monto Retirado</th>
                                    <th className="text-start">Empleado</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {history.data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-gray-800 fw-bold text-start">
                                                {item.created_at}
                                            </td>
                                            <td className="text-gray-800 fw-bold text-center">
                                                {formatCurrency(parseFloat(item.amount))}
                                            </td>
                                            <td className="text-gray-800 fw-bold">
                                                {item.user?.name}
                                            </td>
                                            <td>
                                                {hasPermission('quotes.insurance.policy.show_real_balance') && hasPermission('quotes.withdrawals.delete') && (
                                                    <a href="#" onClick={() => deleteWithdrawal(item.id)}>
                                                        <i className="bi bi-trash text-danger"></i>
                                                    </a>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {history.last_page > 1 && (
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Siguiente »"
                                onPageChange={(page) => getWithdrawals(withdrawal.policy_id, page.selected + 1)}
                                pageRangeDisplayed={5}
                                pageCount={history.last_page}
                                previousLabel="« Anterior"
                                renderOnZeroPageCount={null}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        )}
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeDialog} className="btn-sm" variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal>
        {/* <!--end:Appointments Modal--> */}
    </>;
})

export default Withdrawals
