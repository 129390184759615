import AlertDismissible from '../../Shared/Components/AlertDismissible';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import DatePicker from "react-datepicker";
import Form from 'react-bootstrap/Form';
import InvoiceDetails from './InvoiceDetails';
import Layout from '../../Shared/Layout/LayoutAdmin';
import Nav from 'react-bootstrap/Nav';
import PaginationList from '../../Shared/Components/PaginationList';
import React, { useState, useEffect, useRef } from 'react'
import Row from 'react-bootstrap/Row';
import { Inertia } from '@inertiajs/inertia'
import { es } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';
import { groupBy } from 'lodash';
import { hasPermission } from '../../utils/Permissions';
import { usePage } from '@inertiajs/inertia-react';

const List = ({ items, advisers, statuses }) => {
    const { flash } = usePage().props
    const query = new URL(window.location).searchParams;

    const [status, setStatus] = useState(query.get("status") || '');
    const [invoice, setInvoice] = useState(query.get("invoice") || '');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    // Filters

    useEffect(() => {
        setStatus(query.get("status") || '');
        setInvoice(query.get("invoice") || '');
        setDateFrom(query.get("date_from") ? moment(query.get("date_from")).toDate() : '');
        setDateTo(query.get("date_to") ? moment(query.get("date_to")).toDate() : '');
    }, []);

    function handleChangeStatus(e) {
        setStatus(e.target.value);
    }

    function handleChangeInvoice(e) {
        setInvoice(e.target.value);
    }

    // Details

    const InvoiceDetailsRef = useRef();

    // Grouped Items

    const groupedItems = groupBy(items.data, item => item.adviser_id);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    const formatDate = (dateString) => {
        const date = parseISO(dateString);
        return format(date, "d MMMM yyyy", { locale: es });
    };

    // Filter the list

    function search() {
        const start = moment(dateFrom).startOf('day');
        const end = moment(dateTo).startOf('day');

        Inertia.get(route('commissions.response'),
            {
                status: status,
                invoice: invoice,
                date_from: start.isValid() ? start.format('YYYY-MM-DD') : '',
                date_to: end.isValid() ? end.format('YYYY-MM-DD') : '',
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        )
    }

    function exportOrders(type){
        const start = moment(dateFrom).startOf('day');
        const end = moment(dateTo).startOf('day');

        const params = new URLSearchParams({
            status: status,
            invoice: invoice,
            date_from: start.isValid() ? start.format('YYYY-MM-DD') : '',
            date_to: end.isValid() ? end.format('YYYY-MM-DD') : '',
        }).toString();

        let url;
        if (type === 'pdf') {
            url = route('commissions.response.export_pdf') + `?${params}`;
        } else if (type === 'excel') {
            url = route('commissions.response.export_excel') + `?${params}`;
        }

        window.location.href = url;
    }

    return (
        <div>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/comissions/financing" />
            </div>

            <Col className="mb-2 px-1">
                <h2>Financiamiento</h2>
            </Col>

            <InvoiceDetails
                ref={InvoiceDetailsRef}
                onChange={() => { }}
            />

            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            {flash.error && (
                <AlertDismissible message={flash.error} type="danger" />
            )}

            <br />

            <Nav variant="tabs" defaultActiveKey={route('commissions.response')} className="mb-5">
                <Nav.Item>
                    <Nav.Link href={route('commissions.list')}>Listado General</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href={route('commissions.invoice')}>Folio</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href={route('commissions.response')}>Respuesta</Nav.Link>
                </Nav.Item>
            </Nav>

            <Row className="mb-4 px-1">
                <Col sm={3}>
                    <Form.Label className="mb-0">Estatus de Factura</Form.Label>
                    <Form.Select size="sm" value={status} onChange={handleChangeStatus}>
                        <option key='blankChoice' hidden value />
                        {statuses.map((statusOption, index) => {
                            return <option key={index} value={statusOption.id}>{statusOption.name}</option>;
                        })}
                    </Form.Select>
                </Col>
                <Col sm={2}>
                    <Form.Label className="mb-0">Folio</Form.Label>
                    <Form.Control size="sm" value={invoice} placeholder='' type="text" onChange={handleChangeInvoice} />
                </Col>
                <Col sm={2}>
                    <Form.Label className="mb-0">Fecha Inicial</Form.Label>
                    <DatePicker popperPlacement="bottom-start" className="form-control form-control-sm" locale="es" selected={dateFrom} onChange={(date) => setDateFrom(date)} dateFormat="dd/MM/yyyy" />
                </Col>
                <Col sm={2}>
                    <Form.Label className="mb-0">Fecha Final</Form.Label>
                    <DatePicker popperPlacement="bottom-start" className="form-control form-control-sm" locale="es" selected={dateTo} onChange={(date) => setDateTo(date)} dateFormat="dd/MM/yyyy" />
                </Col>
                <Col sm={2}>
                    <Form.Label className="mb-0 d-block">&nbsp;</Form.Label>
                    <Button onClick={search} variant="primary" className='me-3 btn-sm' type="submit" >Filtrar</Button>
                </Col>
            </Row>

            <br />

            {Object.keys(groupedItems).length === 0 && (
                <div>No hay datos.</div>
            )}
            {Object.keys(groupedItems).length > 0 && (
                <div className="d-flex justify-content-end gap-2">
                    <a className="btn btn-sm btn-secondary btn-flex fw-bold" onClick={()=>{exportOrders("excel")}}>Exportar a Excel</a>
                    <a className="btn btn-sm btn-secondary btn-flex fw-bold" onClick={()=>{exportOrders("pdf")}}>Exportar a PDF</a>
                </div>
            )}
            {Object.keys(groupedItems).map((key, index) => {
                const list = groupedItems[key];

                return (<div key={key} className="mb-5">
                    {hasPermission('quotes.commissions.view_any') && (
                        <div className="mb-2">
                            {list[0]['adviser_name']+" "+list[0]['adviser_last_name_paternal']+" "+list[0]['adviser_last_name_maternal']}
                        </div>
                    )}
                    <div className="card card-flush">
                        <div className="card-body py-4 px-2">
                            <div className="table-responsive">
                                <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                    <thead>
                                        <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                            <th className='p-0 pb-3 text-center'>Folio</th>
                                            <th className='p-0 pb-3 text-start'>Fecha de Generación de Folio</th>
                                            <th className='p-0 pb-3 text-start'>Fecha de Generación de Comisión</th>
                                            <th className='p-0 pb-3 text-end'>Subtotal</th>
                                            <th className='p-0 pb-3 text-end'>Impuestos Traslado</th>
                                            <th className='p-0 pb-3 text-end'>Impuestos Retenidos</th>
                                            <th className='p-0 pb-3 text-end'>Comisión final</th>
                                            <th className='p-0 pb-3 text-end'>Estatus</th>
                                            <th className='p-0 pb-3 text-center'>Comprobante</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map((item) => {
                                            return (
                                                <tr key={item["id"]}>
                                                    <td className="text-gray-800 fw-bold fs-6 text-center">{item['order_number']}</td>
                                                    <td className="text-gray-800 fw-bold fs-6">{formatDate(item['created_at'])}</td>
                                                    <td className="text-gray-800 fw-bold fs-6">{formatDate(item['last_commission_created_at'])}</td>
                                                    <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['subtotal'])}</td>
                                                    <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['total_transfer_taxes'])}</td>
                                                    <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['total_retention_taxes'])}</td>
                                                    <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['total'])}</td>
                                                    <td className="text-gray-800 fw-bold fs-6 text-end">{item['status_name']}</td>
                                                    <td className="text-gray-800 text-center fw-bold fs-6">
                                                        <a href={route('order.cfdi.pdf', { id: item['id'] })} className='btn-sm'>PDF</a>
                                                        <span className="mx-1">/</span>
                                                        <a href={route('order.cfdi.xml', { id: item['id'] })} className='btn-sm'>XML</a>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>);
            })}

            <PaginationList links={items.links} />

        </div>
    );
}

List.layout = page => <Layout children={page} title="Financiamiento" />

export default List
