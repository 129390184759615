import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import classNames from "classnames";
import { Link, usePage } from '@inertiajs/inertia-react';
import "react-datepicker/dist/react-datepicker.css";
import { Inertia } from '@inertiajs/inertia';
import { hasPermission } from '../../utils/Permissions';
import ReactPaginate from "react-paginate";

const Commissions = forwardRef((props, ref) => {
    const { flash, auth } = usePage().props;
    const [errors, setErrors] = useState({});

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => setDialog(false);
    const showDialog = () => setDialog(true);

    useImperativeHandle(ref, () => {
        return {
            openDialog(adviser){
                setAdviser(adviser);
                showDialog();

                getData(adviser.id, 1);
            },
        }
    });

    const [items, setItems] = useState([]);
    const [adviser, setAdviser] = useState([]);

    function getData(id, page_number){
        axios.post(route('advisers.commissions', id), {
            'page' : page_number,
        })
            .then((response) => {
                if(page_number==1){
                    setRemountComponent(Math.random()); // refresh pagination when the page is 1!
                }

                setItems(response.data['items']);
                //setAdviser(response.data['adviser']);
            }).catch((error) => {
                toastr.error("Algo salió mal, intentalo nuevamente");
            });
    }

    /* Paginate */

    const [remountComponent, setRemountComponent] = useState(0);

    return <>
        <Modal show={dialog} onHide={closeDialog} scrollable={true} size="xl" id="dialog">
            <Modal.Header closeButton className="py-4">
                <Modal.Title>
                    Comisiones de {adviser.user?.name} {adviser.lastname_ap} {adviser.lastname_am}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {items.data==undefined || items.data.length==0 ? (
                    <div className="fw-semibold text-muted text-center">
                        Sin registros
                    </div>
                ) : (
                    <div className="table-responsive mb-4">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className='p-0 pb-3 text-center'>ID</th>
                                    <th className='p-0 pb-3 min-w-150px text-center ps-2'>NÚMERO DE PÓLIZA</th>
                                    <th className='p-0 pb-3 min-w-80px text-center ps-2'>FECHA</th>
                                    <th className='p-0 pb-3 min-w-100px text-center ps-2'>MONTO</th>
                                    <th className='p-0 pb-3 min-w-100px text-center ps-2'>NÚMERO DE PAGO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.data.map((item) => {
                                    return (
                                        <tr key={item["id"]}>
                                            <td className="text-center text-gray-800 fw-bold mb-1 fs-6">{item.id}</td>
                                            <td className="text-center text-gray-800 fw-bold mb-1 fs-6">{item.poliza!=null ? item.poliza.policy : ''}</td>
                                            <td className="text-center text-gray-800 fw-bold mb-1 fs-6">{item.created_at}</td>
                                            <td className="text-center text-gray-800 fw-bold mb-1 fs-6">{item.amount}</td>
                                            <td className="text-center text-gray-800 fw-bold mb-1 fs-6">{item.number_of_payment}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div key={remountComponent} className="mt-3">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Siguiente »"
                                onPageChange={(page) => getData(adviser.id, page.selected+1) }
                                pageRangeDisplayed={5}
                                pageCount={items.last_page}
                                previousLabel="« Anterior"
                                renderOnZeroPageCount={null}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeDialog} className="btn-sm" variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal>
    </>;
})

export default Commissions
