import React, { useState } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import { hasPermission } from '../../utils/Permissions';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import { Link, usePage } from '@inertiajs/inertia-react'
import PaginationList from '../../Shared/Components/PaginationList';

const List = ({ items }) => {
    const { flash } = usePage().props
    const data = items.data;

    return (
        <Row>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}

            <div className="card card-flush h-md-100">
                <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">Bitácora</span>
                    </h3>
                </div>
                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0" style={{ fontSize: "11px" }}>

                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="text-start">Usuario</th>
                                    <th className="text-start">Fecha</th>
                                    <th className="text-start">Ruta</th>
                                    <th className="text-start">Método</th>
                                    <th className="text-start">IP</th>
                                    <th className="text-start">User Agent</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="3"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="px-3 py-0">{item['user']['name']}</td>
                                            <td className="px-3 py-0">{moment.utc(item['created_at']).local().format('DD/MM/YYYY HH:mm')}</td>
                                            <td className="px-3 py-0">{item['url']}</td>
                                            <td className="px-3 py-0">{item['method']}</td>
                                            <td className="px-3 py-0">{item['ip_address']}</td>
                                            <td className="px-3 py-0">{item['user_agent']}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <PaginationList links={items.links} />
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Visitas de Usuario" />

export default List
