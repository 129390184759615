import { Inertia } from '@inertiajs/inertia'
import React, { useState, useEffect, useRef } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { hasPermission } from '../../utils/Permissions';
import Table from 'react-bootstrap/Table';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import PaginationList from '../../Shared/Components/PaginationList';
import { Link, usePage } from '@inertiajs/inertia-react';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const List = ({ items, positions }) => {
    const { flash } = usePage().props
    const query = new URL(window.location).searchParams;
    const [position_id, setPositionId] = useState(query.get("position") || '');
    const [search_text, setSearchText] = useState(query.get("search") || '');

    useEffect(() => {
        setPositionId(query.get("position") || '');
        setSearchText(query.get("search") || '');
    }, []);

    function handleChangePosition(e) {
        setPositionId(e.target.value);
    }

    function handleChangeSearchText(e) {
        setSearchText(e.target.value);
    }

    function search() {
        Inertia.get(route('prospects.list'),
            {
                search: search_text,
                position: position_id,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        )
    }

    return (
        <Row>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/corporate_structure/prospects" />
            </div>
            <Col className="mt-4 mb-2 px-1">
                <h2>Listado de Prospectos</h2>
            </Col>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            {flash.error && (
                <AlertDismissible message={flash.error} type="danger" />
            )}
            <Row className="mb-4 px-1">
                <div className="d-flex justify-content-between px-0">
                    <div className="d-flex justify-content-around h-auto">
                        <Form.Select className="form-control mx-2" value={position_id} onChange={handleChangePosition}>
                            <option key='blankChoice' value={''}>Todas las posiciones</option>
                            {positions.length > 0 && (
                                positions.map((position, index) => {
                                    return <option key={index} value={position.id}>{position.nombre}</option>;
                                }))}
                        </Form.Select>
                        <Form.Control value={search_text} placeholder='Nombre' type="text" onChange={handleChangeSearchText} className="mx-2" />
                        <Button onClick={search} variant="primary" className='me-3 btn-sm mx-2' type="submit" >Filtrar</Button>
                    </div>
                    <div className="mx-0 h-auto">
                        <Button href="/templates/prospects_template.xlsx" variant="primary" className="me-3 btn btn-secondary" type="submit">Descargar Template Excel</Button>
                        {hasPermission('corporate_structure.prospects.add') && (
                            <>
                                <Link href={route('prospects.excel.import')} className='me-3 btn btn-secondary'>Importar Excel</Link>
                                <Link href={route('prospects.add')} className='btn btn-primary'>Agregar Prospecto</Link>
                            </>
                        )}
                    </div>
                </div>
            </Row>
            <div className="card card-flush h-md-100">
                <div className="card-body pt-6 px-2">
                    <div className="table-responsive mb-4">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className='p-0 pb-3 min-w-150px text-start'>NOMBRE</th>
                                    <th className='p-0 pb-3 min-w-150px text-start ps-2'>FECHA DE REGISTRO</th>
                                    <th className='p-0 pb-3 min-w-80px text-start ps-2'>OFICINA</th>
                                    <th className='p-0 pb-3 min-w-100px text-start ps-2'>PUESTO A INGRESAR</th>
                                    <th className='p-0 pb-3 min-w-80px text-start ps-2'>STATUS</th>
                                    <th className='p-0 pb-3 min-w-100px text-start ps-2'>STATUS DE DOCUMENTOS</th>
                                    <th width="200"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center"
                                            colSpan="6"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {items.data.map((item) => {
                                    return (
                                        <tr key={item["id"]}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.user.name} {item.lastname_ap} {item.lastname_am}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.created_at}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p className="text-gray-800 fw-bold mb-1 fs-6">{item.office.nombre}</p>
                                            </td>
                                            <td>
                                                <p className="text-gray-800 fw-bold mb-1 fs-6">{item.position.nombre}</p>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        {item.active === 1 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-success">Activo</span>
                                                        )}
                                                        {item.active === 0 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-danger">Inactivo</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        {item.documents_complete === 1 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-success">Completo</span>
                                                        )}
                                                        {item.documents_complete === 0 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-danger">Incompleto</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-around">
                                                    {hasPermission('corporate_structure.prospects.edit') && (
                                                        <>
                                                            <div className='mx-2'>
                                                                <Link href={route('prospects.edit', item.id)}>Editar</Link>
                                                            </div>
                                                            <div className='mx-2'>
                                                                <Link href={route('prospects.password.edit', item.id)} className="ms-1">Contraseña</Link>
                                                            </div>
                                                            <div className='mx-2'>
                                                                <Link href={route('prospects.documents.edit', item.id)} className="ms-1">Docs</Link>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <PaginationList links={items.links} />
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Prospectos" />

export default List
