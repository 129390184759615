import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import classNames from "classnames";
import { Link, usePage } from '@inertiajs/inertia-react';
import "react-datepicker/dist/react-datepicker.css";
import { Inertia } from '@inertiajs/inertia';
import { hasPermission } from '../../utils/Permissions';
import ReactPaginate from "react-paginate";

const Beneficiaries = forwardRef((props, ref) => {
    const { flash, auth } = usePage().props;
    const [errors, setErrors] = useState({});

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => setDialog(false);
    const showDialog = () => setDialog(true);

    useImperativeHandle(ref, () => {
        return {
            openDialog(policy){
                setPolicy(policy);
                showDialog();

                getData(policy.id);
            },
        }
    });

    const [items, setItems] = useState([]);
    const [policy, setPolicy] = useState([]);

    function getData(id){
        axios.post(route('insurance.beneficiaries', id))
            .then((response) => {
                setItems(response.data);
            }).catch((error) => {
                toastr.error("Algo salió mal, intentalo nuevamente");
            });
    }

    return <>
        <Modal show={dialog} onHide={closeDialog} scrollable={true} size="xl" id="dialog">
            <Modal.Header closeButton className="py-4">
                <Modal.Title>
                    Beneficiarios
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {items==undefined || items.length==0 ? (
                    <div className="fw-semibold text-muted text-center">
                        No se encontró referencia a una solicitud
                    </div>
                ) : (
                    <div className="table-responsive mb-4">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="min-w-175px text-start">Nombre</th>
                                    <th className="min-w-175px text-start">Apellido Paterno</th>
                                    <th className="min-w-175px text-start">Apellido Materno</th>
                                    <th className="min-w-175px text-start">Parentesco</th>
                                    <th className="min-w-175px text-start">Porcentaje</th>
                                    <th className="min-w-175px text-start">Fecha de Nacimiento</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item) => {
                                    return (
                                        <tr key={item["id"]}>
                                            <td className="text-start text-gray-800 fw-bold mb-1 fs-6">{item.name}</td>
                                            <td className="text-start text-gray-800 fw-bold mb-1 fs-6">{item.last_name}</td>
                                            <td className="text-start text-gray-800 fw-bold mb-1 fs-6">{item.mother_last_name}</td>
                                            <td className="text-start text-gray-800 fw-bold mb-1 fs-6">{item.relationship}</td>
                                            <td className="text-start text-gray-800 fw-bold mb-1 fs-6">{item.percentage}</td>
                                            <td className="text-start text-gray-800 fw-bold mb-1 fs-6">{item.birthdate}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeDialog} className="btn-sm" variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal>
    </>;
})

export default Beneficiaries
