import { Inertia } from '@inertiajs/inertia'
import React, { useState, useEffect, useRef } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import classNames from "classnames";
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import { Link, usePage } from '@inertiajs/inertia-react'
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const List = ({ items }) => {

    const { flash, auth } = usePage().props;
    const query = new URL(window.location).searchParams;
    const [status, setStatus] = useState(query.get("status") || '');
    const [master_name, setMasterName] = useState(query.get("master_name") || '');

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setStatus(query.get("status") || '');
        setMasterName(query.get("master_name") || '');
    }, []);

    const data = items.data;

    function handleChangeMaster(e) {
        setMasterName(e.target.value);
    }

    function handleChangeStatus(e) {
        setStatus(e.target.value);
    }

    function search() {
        Inertia.get(route('appointments.list'),
            {
                status: status,
                master_name: master_name,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        )
    }

    function updateAppointmentsAsSeen(){
        Swal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function(result) {
            if (result.value) {

                axios.post(route('appointments.updateAppointmentsAsSeen'))
                    .then((response) => {
                        toastr.success('Las citas se han marcado como leídas.');
                        Inertia.reload({ only: ['auth.user', 'items'] });
                    })
                    .catch((error) => {
                        toastr.error("Hubo un error marcando las citas como leídas.");
                    })
            }
        });
    }

    function updateAppointmentAsSeen(appointment_id) {
        setTimeout(() => {
            axios.post(route('appointments.updateAppointmentAsSeen', appointment_id))
                .then((response) => {
                    Inertia.reload({ only: ['auth.user', 'items'] });
                })
                .catch((error) => {
                    toastr.error("Hubo un error marcando la cita como leída.");
                });
        }, 300);
    }

    /* ### RESCHEDULE APPOINTMENT ### */

    const [rescheduleAppointmentDialog, setRescheduleAppointmentDialog] = useState(false);
    const closeRescheduleAppointmentDialog = () => setRescheduleAppointmentDialog(false);
    const showRescheduleAppointmentDialog = () => setRescheduleAppointmentDialog(true);
    
    const [appointmentId, setAppointmentId] = useState(new Date());
    const [appointmentDate, setAppointmentDate] = useState(new Date());
    const [appointmentTime, setAppointmentTime] = useState(new Date());

    const startOfDay = moment().startOf('day');
    const hours = Array(48).fill(null).map((_, i) => startOfDay.add(i ? 30 : 0, 'minutes').format('hh:mm A'));

    function rescheduleAppointment(appointment){
        setAppointmentId(appointment.id);
        setAppointmentDate(moment(appointment.date, 'YYYY-MM-DD').toDate());
        setAppointmentTime(moment(appointment.time, 'HH:mm').format('hh:mm A'));
        showRescheduleAppointmentDialog();

        updateAppointmentAsSeen(appointment.id);
    }

    function updateAppointment(){
        setErrors({});

        Swal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function(result) {
            if (result.value) {
                window.blockUI.block();
                axios.post(route('appointments.update', appointmentId), {
                    'date' : moment(appointmentDate).format('YYYY-MM-DD'),
                    'time' : moment(appointmentTime, 'hh:mm A').format('HH:mm'),
                })
                    .then((response) => {
                        toastr.success('Se reagendó la cita de seguimiento');
                        closeRescheduleAppointmentDialog();

                        Inertia.reload({ only: ['items'] });
                    }).catch((error) => {
                        setErrors(error.response.data.errors);
                        toastr.error("Hay errores en el formulario");
                    }).then(() => {
                        window.blockUI.release();
                    });

            }
        });
    }

    /* ### ADD FEEDBACK & VIEW FEEDBACK ### */

    const [addFeedbackDialog, setAddFeedbackDialog] = useState(false);
    const closeAddFeedbackDialog = () => setAddFeedbackDialog(false);
    const showAddFeedbackDialog = () => setAddFeedbackDialog(true);

    const [appointment, setAppointment] = useState({});

    function handleAppointment(e){
        const newappointment = {...appointment};
        newappointment[e.target.name]=e.target.value;
        setAppointment(newappointment);
    }
    
    function addFeedback(appointment) { 
        showAddFeedbackDialog();
        setAppointment(appointment);

        updateAppointmentAsSeen(appointment.id);
    }

    function saveFeedback() {
        setErrors({});

        Swal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function(result) {
            if (result.value) {
                window.blockUI.block();
                axios.post(route('appointments.update', appointment.id), {
                    'feedback' : appointment.feedback,
                    'status' : 'complete',
                })
                    .then((response) => {
                        toastr.success('Se reagendó la cita de seguimiento');
                        closeAddFeedbackDialog();

                        Inertia.reload({ only: ['items'] });
                    }).catch((error) => {
                        setErrors(error.response.data.errors);
                        toastr.error("Hay errores en el formulario");
                    }).then(() => {
                        window.blockUI.release();
                    });

            }
        });
    }

    const [viewFeedbackDialog, setViewFeedbackDialog] = useState(false);
    const closeViewFeedbackDialog = () => setViewFeedbackDialog(false);
    const showViewFeedbackDialog = () => setViewFeedbackDialog(true);

    function viewFeedback(appointment) {
        showViewFeedbackDialog();
        setAppointment(appointment);

        updateAppointmentAsSeen(appointment.id);
    }

    return (
        <Row>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/quotes/appointments" />
            </div>
            <Row className="mb-4">
                <div className='d-flex justify-content-between px-0'>
                    <div className="d-flex justify-content-between px-0">
                        <div className="d-flex justify-content-around h-auto">
                            <Form.Select value={status} onChange={handleChangeStatus}>
                                <option key='blankChoice' value={''}>Estatus de citas</option>
                                <option key='0' value={`pending`}>Pendiente</option>;
                                <option key='1' value={`complete`}>Completada</option>;
                                <option key='2' value={`not_affected`}>No efectuada</option>;
                            </Form.Select>
                            <Form.Control value={master_name} onChange={handleChangeMaster} placeholder='Nombre de maestro' type="text" className="mx-2"></Form.Control>
                            <Button onClick={search} variant="primary" className='me-3 btn-sm btn-primary mx-2' type="submit">Buscar</Button>
                        </div>
                    </div>
                    <div>
                        <div className="py-2">
                            <Button onClick={updateAppointmentsAsSeen} variant="primary" className='btn-sm' type="submit">Marcar todas como leídas</Button>
                        </div>
                    </div>
                </div>
            </Row>

            <div className="card card-flush h-md-100">
                <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">Citas programadas</span>
                    </h3>
                </div>

                {/* <!--begin:Reschedule Appointment Modal--> */}
                <Modal show={rescheduleAppointmentDialog} onHide={closeRescheduleAppointmentDialog} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Cita</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Fecha</Form.Label>
                                    <DatePicker className="form-control" locale="es" selected={appointmentDate} onChange={(date) => setAppointmentDate(date)} dateFormat="dd/MM/yyyy" />
                                    {errors.date && <div className="mt-2 text-danger">{errors.date}</div>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Hora</Form.Label>
                                    <Form.Select onChange={(e) => setAppointmentTime(e.target.value)} value={appointmentTime}>
                                        {hours.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            );
                                        })}
                                    </Form.Select>
                                    {errors.time && <div className="mt-2 text-danger">{errors.time}</div>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeRescheduleAppointmentDialog}>Cerrar</Button>
                        <Button variant="primary" onClick={updateAppointment}>Guardar</Button>
                    </Modal.Footer>
                </Modal>
                {/* <!--end:Reschedule Appointment Modal--> */}

                {/* <!--begin:Add Feedback Modal--> */}
                <Modal show={addFeedbackDialog} onHide={closeAddFeedbackDialog} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Agregar Retroalimentación</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                    <Tabs defaultActiveKey="appointment" id="uncontrolled-tab-example">
                        <Tab eventKey="appointment" title="Cita" className="pt-4">
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Usuario</Form.Label>
                                        <Form.Control value={auth.user.name} disabled={true} type="text" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Fecha</Form.Label>
                                        <Form.Control value={appointment.date+" "+moment(appointment.time,'HH:mm').format('hh:mm A')} disabled={true} type="text" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control value={appointment.status=='pending' ? 'Pendiente' : (appointment.status=='complete' ? 'Completada' : 'No efectuada')} disabled={true} type="text" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <Form.Control name="feedback" onChange={(e)=>handleAppointment(e)} value={appointment.feedback==null?'':appointment.feedback} as="textarea" placeholder="Retroalimentación importante / Siguientes pasos" rows="3"  className={classNames({'is-invalid': (errors.feedback) ? true : false, 'form-control': true})} aria-invalid={errors.feedback ? "true" : "false"}></Form.Control>
                                {errors.feedback && <div className="mt-2 text-danger">{errors.feedback}</div>}
                            </div>
                        </Tab>
                        <Tab eventKey="master" title="Ver Maestro" className="pt-4">
                            <div className="d-flex flex-column">
                                <div className="mt-2">
                                    <span className="text-gray-900 fs-1 fw-bold">{appointment.insurance_policy?.master.name}</span>
                                </div>
                                <div className="separator separator-dashed my-4"></div>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>RFC</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.master.rfc==null?'':appointment.insurance_policy?.master.rfc} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Edad</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.master.age==null?'':appointment.insurance_policy?.master.age} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Telefono</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.master.phone_number==null?'':appointment.insurance_policy?.master.phone_number} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Dirección</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.master.address==null?'':appointment.insurance_policy?.master.address} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Escuela</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.master.school==null?'':appointment.insurance_policy?.master.school} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Dirección</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.master.address_school==null?'':appointment.insurance_policy?.master.address_school} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Correo Electrónico</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.master.email==null?'':appointment.insurance_policy?.master.email} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Ingresos mensuales aproximados</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.master.income==null?'':appointment.insurance_policy?.master.income} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Tab>
                        <Tab eventKey="policy" title="Ver Poliza" className="pt-4">
                            <div className="d-flex flex-column">
                                <div className="mt-2 mb-2">
                                    <span className="text-gray-900 fs-1 fw-bold">Datos de Póliza</span>
                                </div>
                                <label className="col-lg-4 fw-semibold text-muted">
                                    Estatus de póliza
                                    {appointment.insurance_policy?.status=="1" || appointment.insurance_policy?.status=="0"  &&
                                        <span className="badge badge-light-success d-inline-block ms-4">Activa</span>
                                    }
                                    {appointment.insurance_policy?.status=="2" && <span className="badge badge-light-warning d-inline-block ms-4">Inactiva</span>}
                                    {appointment.insurance_policy?.status=="3" && <span className="badge badge-light-danger d-inline-block ms-4">Saldada</span>}
                                </label>
                                <div className="separator separator-dashed my-4"></div>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Poliza</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.policy==null?'':appointment.insurance_policy?.policy} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Agrupación</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.group==null?'':appointment.insurance_policy?.group} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Emisión</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.issue==null?'':appointment.insurance_policy?.issue} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Agente</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.agent_one==null?'':appointment.insurance_policy?.agent_one} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Suma asegurada</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.sum_assured==null?'':appointment.insurance_policy?.sum_assured} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nombre Cobertura</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.coverage_name==null?'':appointment.insurance_policy?.coverage_name} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Apellido Paterno</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.lastname_paternal==null?'':appointment.insurance_policy?.lastname_paternal} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Apellido Materno</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.lastname_maternal==null?'':appointment.insurance_policy?.lastname_maternal} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>RFC Cobertura</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.rfc_coverage==null?'':appointment.insurance_policy?.rfc_coverage} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Importe DIPMC</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.dipmc_amount==null?'':appointment.insurance_policy?.dipmc_amount} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Importe BITP</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.bitp_amount==null?'':appointment.insurance_policy?.bitp_amount} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Importe GF</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.gf_amount==null?'':appointment.insurance_policy?.gf_amount} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Importe BAF</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.baf_amount==null?'':appointment.insurance_policy?.baf_amount} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Importe SEVI</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.sevi_amount==null?'':appointment.insurance_policy?.sevi_amount} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>BIT</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.bit==null?'':appointment.insurance_policy?.bit} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Pagado hasta</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.paid_up==null?'':appointment.insurance_policy?.paid_up} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Comentario</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.comment==null?'':appointment.insurance_policy?.comment} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Descuento Quincenal</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.biweekly_discount==null?'':appointment.insurance_policy?.biweekly_discount} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Retiros</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.retirement==null?'':appointment.insurance_policy?.retirement} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fondo de inversiones y Retiros</Form.Label>
                                            <Form.Control value={appointment.insurance_policy?.investment_fund==null?'':appointment.insurance_policy?.investment_fund} disabled={true} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Tab>
                    </Tabs>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeAddFeedbackDialog}>Cerrar</Button>
                        <Button variant="primary" onClick={saveFeedback}>Guardar</Button>
                    </Modal.Footer>
                </Modal>
                {/* <!--end:Add Feedback Modal--> */}

                {/* <!--begin:View Feedback Modal--> */}
                <Modal show={viewFeedbackDialog} onHide={closeViewFeedbackDialog} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Ver Información</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Usuario</Form.Label>
                                    <Form.Control value={auth.user.name} disabled={true} type="text" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control value={appointment.date+" "+moment(appointment.time,'HH:mm').format('hh:mm A')} disabled={true} type="text" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control value={appointment.status=='pending' ? 'Pendiente' : (appointment.status=='complete' ? 'Completada' : 'No efectuada')} disabled={true} type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mb-3">
                            <Form.Control value={appointment.feedback} as="textarea" disabled={true} className="form-control" name="" id="" rows="3"></Form.Control>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeViewFeedbackDialog}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
                {/* <!--end:View Feedback Modal--> */}

                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-2 pb-3 w-10px text-start"></th>
                                    <th className="p-0 pb-3 w-100px text-start">MAESTRO</th>
                                    <th className="p-2 pb-3 w-70px text-start">PÓLIZA</th>
                                    <th className="p-2 pb-3 w-100px text-start">FECHA</th>
                                    <th className="p-2 pb-3 w-50px text-start">HORA</th>
                                    <th className="p-2 pb-3 w-50px text-start">ESTATUS</th>
                                    <th className="p-0 pb-3 w-50px text-center">ACCIONES</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="6"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {
                                data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {item.viewed=="0" && <span>🔴</span>}
                                                {item.viewed=="1" && <span></span>}
                                            </td>
                                            <td>
                                                <p className="text-gray-800 fw-bold mb-1 fs-6">{item.insurance_policy?.master?.name}</p>
                                            </td>
                                            <td>
                                                <p className="text-gray-800 fw-bold mb-1 fs-6">{item.insurance_policy?.policy}</p>
                                            </td>
                                            <td>
                                                <p className="text-gray-800 fw-bold mb-1 fs-6">{item.formatted_date}</p>
                                            </td>
                                            <td>
                                                <p className="text-gray-800 fw-bold mb-1 fs-6">{moment(item.time,'HH:mm').format('hh:mm A')}</p>
                                            </td>
                                            <td>
                                                {item.status=="pending" && <span className="badge badge-light-warning">Pendiente</span>}
                                                {item.status=="complete" && <span className="badge badge-light-success">Completa</span>}
                                                {item.status=="not affected" && <span className="badge badge-light-danger">No efectuada</span>}
                                            </td>
                                            <td>
                                                <div className="text-center">
                                                    {item.status=="complete" && <Link onClick={(e) => {e.preventDefault(); viewFeedback(item)}}>Ver Información</Link>}
                                                    {item.status=="pending" &&
                                                        <div>
                                                            <Link onClick={(e) => {e.preventDefault(); addFeedback(item)}}>Agregar Retroalimentación</Link>
                                                            <br />
                                                            <Link onClick={(e) => {e.preventDefault(); rescheduleAppointment(item)}}>Reagendar Cita</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                    </tr>
                                    );
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Citas programadas" />

export default List
