import React, { useState, useRef } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import { Link, usePage } from '@inertiajs/inertia-react';
import { Inertia } from '@inertiajs/inertia';
import PaginationList from '../../Shared/Components/PaginationList';

const List = ({ masters, location }) => {
    const { flash } = usePage().props
    
    const data = masters.data;

    return (
        <Row>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}

            <h1>Estatus de solicitudes</h1>
            <p>Historial de solicitudes</p>

            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-bordered table-row-gray-100 align-middle mb-0">
                            <thead>
                                <tr className="fw-bold text-muted text-center">
                                    <th>Folio</th>
                                    <th>Fecha</th>
                                    <th>Aprobador</th>
									<th>Asesor / Empleado</th>
                                    <th>Maestro</th>
                                    <th>Edad</th>
                                    <th>Teléfono</th>
                                    <th>RFC</th>
                                    <th>Dirección</th>
                                    <th>Escuela</th>
                                    <th>Dirección Escuela</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 pb-0 text-center text-gray-800 fw-bold fs-6"
                                            colSpan="11"
                                        >
                                            No hay datos
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index} className="fs-9 text-center">
                                            <td className="text-dark fw-bold fs-8">{item.id}</td>
                                            <td className="text-dark fw-bold fs-8">{item.created_at}</td>
                                            <td className="text-dark fw-bold fs-8">
                                                {item.user_approved!=null ? item.user_approved.name : ''}
                                            </td>
                                            <td className="text-dark fw-bold fs-8">{item.user.name}</td>
                                            <td>
                                                <span className="text-dark fw-bold d-block">{item.name}</span>
                                            </td>
                                            <td>
                                                <span className="text-dark fw-bold d-block">{item.age}</span>
                                            </td>
                                            <td>
                                                <span className="text-dark fw-bold d-block">{item.phone_number}</span>
                                            </td>
                                            <td>
                                                <span className="text-dark fw-bold d-block">{item.rfc}</span>
                                            </td>
                                            <td>
                                                <span className="text-dark fw-bold d-block">{item.address}</span>
                                            </td>
                                            <td>
                                                <span className="text-dark fw-bold d-block">{item.school}</span>
                                            </td>
                                            <td>
                                                <span className="text-dark fw-bold d-block">{item.address_school}</span>
                                            </td>
                                            <td>
                                                {item.approved==0 && <span className="py-2 px-4 badge badge-light-warning">Pendiente</span>}
                                                {item.approved==1 && <span className="py-2 px-4 badge badge-light-success">Aprobado</span>}
                                                {item.approved==2 && <span className="py-2 px-4 badge badge-light-danger">Rechazado</span>}

                                                {item.approved==2 && <>
                                                    <span className="text-muted fw-semibold text-muted d-block mt-2">
                                                        {item.message}
                                                    </span>
                                                </>}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <PaginationList links={masters.links} />
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Aprobaciones" />

export default List
