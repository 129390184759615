import React, { useState, useRef, useEffect } from 'react';

function OptionsMenu({children}) {
    const [open, setOpen] = useState(false);

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e)=>{
            if(
                !menuRef.current.contains(e.target) ||
                e.target.classList.contains('close-actions-menu')
            ){
                setTimeout(() => {
                    setOpen(false);
                }, 100)
            }
        };

        document.addEventListener("click", handler);

        return() => {
            document.removeEventListener("click", handler);
        }
    });

    return (
        <div className="position-relative" ref={menuRef}>
            <a className="fw-bold" onClick={()=>{setOpen(!open)}}>
                <span className="svg-icon svg-icon-6 me-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                    </svg>
                </span>
            </a>
            <div className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px position-absolute end-0 ${open ? 'show' : ''}`} data-popper-placement="bottom-end">
                { children }
            </div>
        </div>
    )
}

export default OptionsMenu;
