import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import parse from 'html-react-parser';

function AlertDismissible(props) {
    const [show, setShow] = useState(true);

    if (show) {
        return (
            <Alert variant={props.type} onClose={() => setShow(false)} dismissible className="mt-5">
                {props.type != 'danger' && (
                    parse(props.message)
                )}
                {props.error && (
                    <Alert.Heading>Ocurrió un error en el proceso:</Alert.Heading>
                )}
                {props.type == 'danger' && props.message.constructor != Array && (
                    parse(props.message)
                )}
                {props.error && (
                    props.message.map((item, index) => {
                        return (
                            <p key={index}>{item}</p>
                        )
                    })
                )}
            </Alert>
        )
    }
}

export default AlertDismissible;
