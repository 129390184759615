import "react-datepicker/dist/react-datepicker.css";
import 'glightbox/dist/css/glightbox.min.css';
import ActionsMenu from '../../Shared/Components/ActionsMenu';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import Beneficiaries from './Beneficiaries';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import DatePicker from "react-datepicker";
import FilterMenu from '../../Shared/Components/FilterMenu';
import Form from 'react-bootstrap/Form';
import GLightbox from 'glightbox';
import Layout from '../../Shared/Layout/LayoutAdmin';
import Modal from 'react-bootstrap/Modal';
import OptionsMenu from '../../Shared/Components/OptionsMenu';
import PaginationList from '../../Shared/Components/PaginationList';
import PaymentsHistoryByMaster from './PaymentsHistoryByMaster';
import PaymentsHistoryByPolicy from './PaymentsHistoryByPolicy';
import React, { useState, useEffect, useRef } from 'react'
import Row from 'react-bootstrap/Row';
import Withdrawals from './Withdrawals';
import axios from 'axios';
import classNames from "classnames";
import es from 'date-fns/locale/es';
import { Inertia } from '@inertiajs/inertia'
import { Link, useForm, usePage } from '@inertiajs/inertia-react'
import { hasPermission } from '../../utils/Permissions';
import { registerLocale, setDefaultLocale } from "react-datepicker";
registerLocale('es', es)

const List = ({ masters, advisers, product_types_list, offices, companies }) => {
    const { flash } = usePage().props;
    const query = new URL(window.location).searchParams;

    const [policies, setPolicies] = useState([]);
    const [policy, setPolicy] = useState({});
    const [master, setMaster] = useState({});
    const [errors, setErrors] = useState({});
    const [search_master_nombre, setSearchMasterNombre] = useState(query.get("nombre") || '');
    const [search_master_rfc, setSearchMasterRfc] = useState(query.get("rfc") || '');
    const [search_master_poliza, setSearchMasterPoliza] = useState(query.get("poliza") || '');
    const [search_master_status, setSearchMasterStatus] = useState(query.get("status") || '');
    const [search_emitter_name, setSearchEmitterName] = useState(query.get("emitter_name") || '');
    const [search_office, setSearchOffice] = useState(query.get("office") || '');
    const [search_company, setSearchCompany] = useState(query.get("company") || '');
    const [search_agent, setSearchAgent] = useState(query.get("agent") || '');
    const [search_state, setSearchState] = useState(query.get("state") || '');
    const [search_coverage_name, setSearchCoverageName] = useState(query.get("coverage_name") || '');
    const [search_product_type_name, setSearchProductTypeName] = useState(query.get("product_type_name") || '');
    const [issueDate, setIssueDate] = useState(new Date());
    const [paidupDate, setPaidupDate] = useState(new Date());
    const [product_types, setProductTypes] = useState(product_types_list);

    const { data, setData, post, processing, cover_errors, progress, reset } = useForm({
        file: null,
    });

    const [filteredAdvisers, setFilteredAdvisers] = useState({
        agent1Options: advisers,
        agent2Options: advisers,
        agent3Options: advisers
    });

    const search_poliza = useRef();

    const WithdrawalsRef = useRef();
    const PaymentsHistoryByMasterRef = useRef();
    const PaymentsHistoryByPolicyRef = useRef();
    const BeneficiariesRef = useRef();

    useEffect(() => {
        setSearchMasterNombre(query.get("nombre") || '');
        setSearchMasterRfc(query.get("rfc") || '');
        setSearchMasterPoliza(query.get("poliza") || '');
        setSearchMasterStatus(query.get("status") || '');
        setSearchOffice(query.get("office") || '');
        setSearchCompany(query.get("company") || '');
        setSearchAgent(query.get("agent") || '');
        setSearchEmitterName(query.get("emitter_name") || '');
    }, []);

    useEffect(() => {
        const agent1Selected = policy.agent1;
        const agent2Selected = policy.agent2;
        const agent3Selected = policy.agent3;

        const agent1Options = advisers.filter(adviser => adviser.id !== agent2Selected && adviser.id !== agent3Selected);
        const agent2Options = advisers.filter(adviser => adviser.id !== agent1Selected && adviser.id !== agent3Selected);
        const agent3Options = advisers.filter(adviser => adviser.id !== agent1Selected && adviser.id !== agent2Selected);

        setFilteredAdvisers({
            agent1Options,
            agent2Options,
            agent3Options
        });
    }, [policy.agent1, policy.agent2, policy.agent3, advisers]);

    useEffect(() => {
        setPolicy([]);
    }, [policies]);

    function getPolicies(master, e) {
        if (e != null) e.preventDefault();
        setMaster(master);
        axios
            .get(route('insurance.get.policies', master.id), {
                params: {
                    poliza: search_master_poliza,
                    office: search_office,
                    company: search_company,
                    agent: search_agent,
                    coverage_name: search_coverage_name,
                    product_type_name: search_product_type_name,
                    emitter_name: search_emitter_name,
                }
            })
            .then((response) => {
                setPolicies(response.data);
            });
    }

    function changeMaster(master, e) {
        search_poliza.current = '';
        if (document.getElementById('search_poliza') != undefined) document.getElementById('search_poliza').value = "";
        getPolicies(master, e);

        scrollToById('polizas');
    }

    function getPolicy(selectPolicy, e) {
        e.preventDefault();
        setPolicy(selectPolicy);
        setIssueDate(Date.parse(selectPolicy.issue + ' 00:00:00'));
        if (selectPolicy.paid_up != null) {
            setPaidupDate(Date.parse(selectPolicy.paid_up + ' 00:00:00'));
        } else {
            setPaidupDate("");
        }
        scrollToById('poliza');
    }

    function scrollToById(id) {
        if (window.innerWidth <= 900) {
            setTimeout(() => {
                window.scrollTo({
                    top: document.getElementById(id).offsetTop,
                    behavior: 'smooth'
                });
            }, 100)
        }
    }

    function searchMasters() {
        setMaster('');
        setPolicies([]);
        Inertia.get(route('insurance.list'),
            {
                nombre: search_master_nombre,
                rfc: search_master_rfc,
                poliza: search_master_poliza,
                status: search_master_status,
                office: search_office,
                company: search_company,
                agent: search_agent,
                state: search_state,
                coverage_name: search_coverage_name,
                product_type_name: search_product_type_name,
                emitter_name: search_emitter_name,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        )
    }

    /* ### UPDATE MASTER ### */

    function handleMaster(e) {
        const newpolicy = { ...policy };
        newpolicy.master[e.target.name] = e.target.value;
        setPolicy(newpolicy);
    }

    function updateMaster(e) {
        e.preventDefault();
        setErrors({});

        Swal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {
                axios.post(route('masters.update', policy.master.id), policy.master)
                    .then((response) => {
                        toastr.success(response.data);
                    }).catch((error) => {
                        setErrors(error.response.data.errors);
                        toastr.error("Hay errores en el formulario");
                    });
            }
        });
    }

    /* ### UPDATE POLICY ### */

    function handlePolicy(e) {
        const newpolicy = { ...policy };
        newpolicy[e.target.name] = e.target.value;
        setPolicy(newpolicy);
    }

    function updatePolicy(e) {
        e.preventDefault();
        policy.issue = (issueDate != null) ? moment(issueDate).format('YYYY-MM-DD') : null;
        policy.paid_up = (paidupDate != null) ? moment(paidupDate).format('YYYY-MM-DD') : null;
        axios.post(route('insurance.update', policy.id), policy)
            .then((response) => {
                toastr.success('Póliza actualizada correctamente');
            }).catch((error) => {
                setErrors(error.response.data.errors);
                toastr.error("Hay errores en el formulario");
            });
    }

    /* ### NEGATIVE AMOUNTS ### */
    const [negativeAmountsDialog, setNegativeAmountsDialog] = useState(false);
    const closeNegativeAmountsDialog = () => setNegativeAmountsDialog(false);
    const showNegativeAmountsDialog = () => setNegativeAmountsDialog(true);
    const [negativeAmountsMonth, setNegativeAmountsMonth] = useState("01");
    const months = [
        { id: "01", name: "Enero" },
        { id: "02", name: "Febrero" },
        { id: "03", name: "Marzo" },
        { id: "04", name: "Abril" },
        { id: "05", name: "Mayo" },
        { id: "06", name: "Junio" },
        { id: "07", name: "Julio" },
        { id: "08", name: "Agosto" },
        { id: "09", name: "Septiembre" },
        { id: "10", name: "Octubre" },
        { id: "11", name: "Noviembre" },
        { id: "12", name: "Diciembre" },
    ];

    function downloadNegativeAmountsByMonth() {
        window.location.href = route('withdrawal.excel.export.negativeAmmounts') + "?month=" + negativeAmountsMonth;
    }

    /* ### UPLOAD COVER ### */

    const [coverDialog, setCoverDialog] = useState(false);
    const closeCoverDialog = () => setCoverDialog(false);
    const showCoverDialog = () => setCoverDialog(true);

    function uploadCover(e) {
        e.preventDefault();

        Swal.fire({
            title: "¿Estás seguro de subir el archivo?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {
                window.blockUI.block();

                post(route('insurance.cover.upload', policy.id), {
                    onFinish: (r) => {
                        const newpolicy = { ...policy };
                        newpolicy['cover'] = 1;
                        setPolicy(newpolicy);

                        toastr.success('Se agregó la carátula de la póliza');
                        closeCoverDialog();
                        Inertia.reload({ only: ['auth.user'] });
                        window.blockUI.release();
                    },
                    onError: error => {
                        console.log(error);
                        toastr.error(error.response.data.message);
                    },
                })
            }
        });
    }

    /* ### CHECK HISTORY & CREATE APPOINTMENT ### */

    const [appointmentsDialog, setAppointmentsDialog] = useState(false);
    const closeAppointmentsDialog = () => setAppointmentsDialog(false);
    const showAppointmentsDialog = () => setAppointmentsDialog(true);

    const [appointments, setAppointments] = useState([]);

    const [appointmentDate, setAppointmentDate] = useState(new Date());
    const [appointmentTime, setAppointmentTime] = useState(new Date());

    const startOfDay = moment().startOf('day');
    const hours = Array(48).fill(null).map((_, i) => startOfDay.add(i ? 30 : 0, 'minutes').format('hh:mm A'));

    function checkHistory() {
        window.blockUI.block();
        axios.get(route('appointments.get.appointmensByPolicy', policy.id))
            .then((response) => {

                setAppointmentDate(new Date());
                setAppointmentTime('09:00 AM');
                showAppointmentsDialog();

                setAppointments(response.data);

            }).catch((error) => {
                setErrors(error.response.data.errors);
                toastr.error("Hay errores en el formulario");
            }).then(() => {
                window.blockUI.release();
            });
    }

    function createAppointment(e) {
        e.preventDefault();
        setErrors({});

        var appointment = {
            'insurance_policy_id': policy.id,
            'date': moment(appointmentDate).format('YYYY-MM-DD'),
            'time': moment(appointmentTime, 'hh:mm A').format('HH:mm'),
            'status': 'pending',
            'feedback': '',
        };

        Swal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {
                window.blockUI.block();
                axios.post(route('appointments.create'), appointment)
                    .then((response) => {
                        toastr.success('Se agendó una cita de seguimiento');
                        closeAppointmentsDialog();

                        Inertia.reload({ only: ['auth.user'] });
                    }).catch((error) => {
                        toastr.error(error.response.data.message);
                    }).then(() => {
                        window.blockUI.release();
                    });
            }
        });
    }

    function viewCover(id) {
        const myGallery = GLightbox({
            'openEffect': 'fade',
            'closeEffect': 'fade',
            elements: [
                {
                    'href': route('insurance.cover.see', id),
                    'type': 'external',
                    'height': '90vh',
                    'width': '90vw',
                },
            ],
            autoplayVideos: true,
        });

        myGallery.open();
    }

    return <>
        {/*  begin::Toolbar container */}
        <div className="mb-7 mt-7">
            <Breadcrumbs currentPath="/admin/quotes/insurance" />
        </div>
        <Row className="pb-3 pb-lg-6">
            <Col lg={6} className="d-flex align-items-center">
                <h1 className="page-heading text-dark fw-bold fs-1 mb-2">Listado de Pólizas</h1>
            </Col>
            <Col lg={6} className="text-lg-end">
                {hasPermission('quotes.insurance.policy.add') && (
                    <ActionsMenu>
                        <div className="text-md-center px-7 py-5 ">
                            <div className="mb-4">
                                <Link href={route('insurance.excel.import')} className='me-3 "text-lg'>Agregar Pólizas</Link>
                            </div>
                            <div className="mb-4">
                                <Link href={route('insurance.txt.import')} className='me-3 "text-lg'>Agregar Pólizas desde Txt</Link>
                            </div>
                            <div className="mb-4">
                                <Link href={route('balances.excel.import')} className='me-3' variant="secondary">Actualizar Saldos</Link>
                            </div>
                            {hasPermission('quotes.withdrawals.add') && (
                                <div className="mb-4">
                                    <Link href={route('withdrawal.excel.import')} className='me-3' variant="secondary">Importar Retiros</Link>
                                </div>
                            )}
                            {/* <div className="mb-4">
                                <a href="/templates/insurance_policies_template.xls" className='me-3' variant="primary" type="submit">Descargar Template Excel</a>
                            </div> */}
                            <div className="mb-4">
                                <a href='#' onClick={e => showNegativeAmountsDialog(e)} className='me-3' variant="secondary">Descargar Saldos Negativos</a>
                            </div>
                            <div className="mb-4">
                                <Link href={route('withdrawal.calculate.pending')} className='me-3' variant="secondary">Actualizar Retiros Pendientes</Link>
                            </div>
                            {hasPermission('actions.upload.payments') && (
                                <div className="mb-4">
                                    <Link href={route('payments.excel.import')} className='me-3' variant="secondary">Importar Archivos de Pagos</Link>
                                </div>
                            )}
                            {hasPermission('actions.upload.paid_up') && (
                                <div className="mb-2">
                                    <Link href={route('insurance.paidUp.import')} className='me-3' variant="secondary">Actualizar valor Pagado hasta</Link>
                                </div>
                            )}
                            {hasPermission('actions.upload.payments') && (
                                <div className="mb-2">
                                    <Link href={route('insurance.policies_charged.import')} className='me-3' variant="secondary">Importar Pólizas Cobradas</Link>
                                </div>
                            )}
                        </div>
                    </ActionsMenu>
                )}
            </Col>
        </Row>
        {/*  end::Toolbar container */}

        {flash.success && (
            <AlertDismissible message={flash.success} type="success" />
        )}
        {flash.error && (
            <AlertDismissible message={flash.error} type="danger" />
        )}

        {/*  begin::Inbox App - View & Reply  */}
        <div className="d-flex flex-column flex-lg-row" style={{ 'gap': '20px' }}>

            {/*  begin::Sidebar */}
            <div className="d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px mb-3">
                {/*  begin::Sticky aside */}
                <div className="card card-flush mb-0" data-kt-sticky="true" data-kt-sticky-name="inbox-aside-sticky2" data-kt-sticky-offset="{default: false, xl: '100px'}" data-kt-sticky-width="{lg: '275px'}" data-kt-sticky-left="auto" data-kt-sticky-top="100px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">
                    <div className="card-header small mt-3">
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <h5 className="text-gray-900 text-hover-primary fs-3 fw-bold m-0">Maestros</h5>

                            {hasPermission('quotes.insurance.filters.view') && (
                                <FilterMenu>
                                    <div className="px-7 py-5">
                                        <div className="fs-5 text-dark fw-bold">Opciones</div>
                                    </div>
                                    <div className="separator border-gray-200"></div>
                                    <div className="px-7 py-5 overflow-auto" style={{ 'maxHeight': '400px' }}>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">Nombre Maestro:</label>
                                            <input type="text" value={search_master_nombre} onChange={e => setSearchMasterNombre(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">RFC:</label>
                                            <input type="text" value={search_master_rfc} onChange={e => setSearchMasterRfc(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold"># de Póliza:</label>
                                            <input type="text" value={search_master_poliza} onChange={e => setSearchMasterPoliza(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">Oficina:</label>
                                            <input type="text" value={search_office} onChange={e => setSearchOffice(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">Retenedor:</label>
                                            <input type="text" value={search_company} onChange={e => setSearchCompany(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">Agente:</label>
                                            <input type="text" value={search_agent} onChange={e => setSearchAgent(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">Estado:</label>
                                            <input type="text" value={search_state} onChange={e => setSearchState(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">Nombre Cobertura:</label>
                                            <input type="text" value={search_coverage_name} onChange={e => setSearchCoverageName(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">Nombre del Tipo de Producto:</label>
                                            <input type="text" value={search_product_type_name} onChange={e => setSearchProductTypeName(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">Emisor:</label>
                                            <input type="text" value={search_emitter_name} onChange={e => setSearchEmitterName(e.target.value)} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold">Estatus:</label>
                                            <select value={search_master_status} onChange={e => setSearchMasterStatus(e.target.value)} className="form-select" aria-label="Select example">
                                                <option value="">Todas</option>
                                                <option value="1">Activa</option>
                                                <option value="2">Inactiva</option>
                                                <option value="3">Saldada</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="px-7 py-5">
                                        {/* <!--begin::Actions--> */}
                                        <div className="d-flex justify-content-end">
                                            <Button onClick={searchMasters} variant="primary" className='me-3 btn-sm close-filter-menu'>Aplicar</Button>
                                        </div>
                                        {/* <!--end::Actions--> */}
                                    </div>
                                </FilterMenu>
                            )}
                        </div>
                    </div>
                    <div className="separator separator-dashed my-4"></div>
                    {/*  begin::Aside content */}
                    <div className="card-body pt-0 scroll-items">
                        {/* Masters List */}
                        <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary">
                            {masters.data.length == 0 && (
                                <p className="fs-3 p-3 f">No hay lista de maestros</p>
                            )}
                            {masters.data.map((item) => {
                                return (
                                    <div className="menu-item mb-3" key={item['id']}>
                                        {/*  begin::Partnership */}
                                        <span className={classNames({ 'active': (master?.id == item['id']) ? true : false, 'menu-link': true })} onClick={(e) => changeMaster(item, e)}>
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-6 svg-icon-success me-3">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 6C8.7 6 6 8.7 6 12C6 15.3 8.7 18 12 18C15.3 18 18 15.3 18 12C18 8.7 15.3 6 12 6Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                            </span>
                                            <span className="menu-title fw-semibold">{item['name']} {item['last_name']} {item['mother_last_name']}</span>
                                            {/*   <span className="badge badge-light-primary">6</span> */}
                                        </span>
                                        {/*  end::Partnership */}
                                    </div>
                                );
                            })}
                            {masters.data.length > 0 && (
                                <PaginationList links={masters.links} filters={{
                                    nombre: search_master_nombre,
                                    rfc: search_master_rfc,
                                    poliza: search_master_poliza,
                                    status: search_master_status,
                                    office: search_office,
                                    company: search_company,
                                    agent: search_agent,
                                    state: search_state,
                                    coverage_name: search_coverage_name,
                                    product_type_name: search_product_type_name
                                }} />
                            )}
                        </div>
                        {/* end::Menu */}
                    </div>
                    {/*  end::Aside content */}
                </div>
                {/*  end::Sticky aside */}
            </div>
            {/*  end::Sidebar */}

            {/*  begin::Polizas */}
            {Object.keys(master).length > 0 && (
                <div className="d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px policies-items mb-3" id="polizas">
                    {/*begin::Sticky aside  */}
                    <div className="card card-flush mb-0" data-kt-sticky="true" data-kt-sticky-name="inbox-aside-sticky1" data-kt-sticky-offset="{default: false, xl: '100px'}" data-kt-sticky-width="{lg: '275px'}" data-kt-sticky-left="auto" data-kt-sticky-top="100px" data-kt-sticky-animation="false" data-kt-sticky-zindex="94">
                        <div className="card-header small mt-3">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <h5 className="text-gray-900 text-hover-primary fs-3 fw-bold m-0">Pólizas</h5>

                                <PaymentsHistoryByMaster ref={PaymentsHistoryByMasterRef} onChange={() => { }}></PaymentsHistoryByMaster>
                                {hasPermission('quotes.insurance.payments.history') && (
                                    <OptionsMenu>
                                        <div className="text-md-center py-4">
                                            <div className="mb-2">
                                                <a href="#" onClick={() => { PaymentsHistoryByMasterRef.current.openDialog(master) }}>Histórico de Pagos</a>
                                            </div>
                                        </div>
                                    </OptionsMenu>
                                )}
                                {/*
                                <FilterMenu>
                                    <div className="px-7 py-5">
                                        <div className="fs-5 text-dark fw-bold">Opciones</div>
                                    </div>
                                    <div className="separator border-gray-200"></div>
                                    <div className="px-7 py-5">
                                        <div className="mb-4">
                                            <label className="form-label fw-semibold"># de Póliza:</label>
                                            <input ref={search_poliza} id="search_poliza" type="text" className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px" />
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <Button onClick={e => getPolicies(master, e)} variant="primary" className='me-3 btn-sm close-filter-menu'>Aplicar</Button>
                                        </div>
                                    </div>
                                </FilterMenu>
                            */}
                            </div>
                        </div>
                        <div className="separator separator-dashed my-4"></div>
                        {/*  begin::Aside content */}
                        <div className="card-body pt-0 scroll-items">
                            {/*begin::Menu  */}
                            <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary">
                                {policies.length == 0 && (
                                    <p className="fs-3 p-3 f">No hay lista de pólizas</p>
                                )}
                                {/*begin::Menu item  */}
                                {policies.map((item) => {
                                    return (

                                        <div className="menu-item mb-3 item" key={item['id']} onClick={e => getPolicy(item, e)}>
                                            <span className={classNames({ 'active': (policy?.id == item['id']) ? true : false, 'menu-link': true })}>
                                                <span className="menu-title fw-semibold">{item['policy']}</span>
                                            </span>
                                            <div className="separator separator-dashed my-3"></div>
                                        </div>
                                    );
                                })}
                                {/*end::Menu item  */}

                            </div>
                            {/*end::Menu  */}
                        </div>
                        {/*end::Aside content  */}
                    </div>
                    {/*end::Sticky aside  */}
                </div>
            )}
            {/*  end::Polizas */}

            {/*  begin::)} */}
            {policy.id != null && (
                <div className="flex-column flex-sm-row-fluid flex-sm-row-auto w-100 w-lg-800px mb-3" id="poliza">
                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                        <div className="card-body pb-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <div>{/*Retiros en el año: <strong>pendiente</strong>*/}</div>
                                <div>{/*Monto máximo: <strong>pendiente</strong>*/}</div>
                                <div>
                                    <Withdrawals ref={WithdrawalsRef} onChange={() => { }}></Withdrawals>
                                    <PaymentsHistoryByPolicy ref={PaymentsHistoryByPolicyRef} onChange={() => { }}></PaymentsHistoryByPolicy>
                                    <Beneficiaries ref={BeneficiariesRef} onChange={() => { }}></Beneficiaries>
                                </div>
                            </div>
                            <hr style={{ borderColor: 'var(--kt-border-color)', opacity: '1' }} />
                            <div className="row align-items-center">

                                <label className="col-4 fw-semibold text-muted text-start">
                                    <span className="me-4">Estatus de póliza</span>
                                    {policy.status == 0 && <span className="badge badge-warning d-inline-block">Terminada</span>}
                                    {policy.status == 1 && <span className="badge badge-success d-inline-block">Vigente</span>}
                                    {policy.status == 2 && <span className="badge badge-danger d-inline-block">Cancelada</span>}
                                </label>
                                <div className="col-6">
                                    <div className="text-end">
                                        Último seguimiento: {policy.last_appointment == null ? 'Sin registros' : policy.last_appointment.formatted_date}
                                    </div>
                                </div>
                                <div className="col-2 text-end">
                                    <OptionsMenu>
                                        <div className="text-md-center py-4">
                                            {(hasPermission('quotes.insurance.withdrawals.history') || hasPermission('quotes.insurance.withdrawals.history.view')) && (
                                                <div className="mb-4">
                                                    <a href="#" onClick={() => { WithdrawalsRef.current.openWithdrawalDialog(policy.id) }}>Retiros</a>
                                                </div>
                                            )}
                                            <div className="mb-2">
                                                {hasPermission('quotes.insurance.appointment.history') && (
                                                    <a href="#" onClick={checkHistory}>Citas de Seguimiento al Maestro/Asegurado</a>
                                                )}
                                            </div>
                                            <div className="mb-2">
                                                {hasPermission('quotes.insurance.policy.edit') && (
                                                    <a href="#" onClick={showCoverDialog}>Agregar Carátula de Póliza</a>
                                                )}
                                            </div>
                                            {policy.cover==1 && (
                                                <div className="mb-2">
                                                    <a href="#" onClick={() => viewCover(policy.id)}>Ver Carátula de Póliza</a>
                                                </div>
                                            )}
                                            <div className="mb-2">
                                                <a href="#" onClick={() => { BeneficiariesRef.current.openDialog(policy) }}>Beneficiarios</a>
                                            </div>
                                        </div>
                                    </OptionsMenu>
                                </div>

                                {/* <!--begin:Appointments Modal--> */}
                                <Modal show={appointmentsDialog} onHide={closeAppointmentsDialog} scrollable={true} size="lg" id="appointment-dialog">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Ver anteriores</Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        {appointments.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <Row >
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Usuario</Form.Label>
                                                                <Form.Control value={item.user?.name} disabled={true} type="text" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Fecha</Form.Label>
                                                                <Form.Control value={item.formatted_date + " " + moment(item.time, 'HH:mm').format('hh:mm A')} disabled={true} type="text" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Status</Form.Label>
                                                                <Form.Control value={item.status == 'pending' ? 'Pendiente' : (item.status == 'complete' ? 'Completada' : 'No efectuada')} disabled={true} type="text" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <div className="mb-6">
                                                        <Form.Control value={item.feedback} as="textarea" disabled={true} placeholder="Retroalimentación importante / Siguientes pasos" className="form-control" name="" id="" rows="3"></Form.Control>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {appointments.length == 0 && <>
                                            <div style={{ minHeight: '200px' }}>Aún no se han registrado citas de seguimiento</div>
                                        </>}
                                    </Modal.Body>

                                    {hasPermission('quotes.insurance.appointment.add') && (
                                        <Modal.Footer>
                                            <div>
                                                <DatePicker popperPlacement="top-start" className="form-control form-control-sm" locale="es" selected={appointmentDate} onChange={(date) => setAppointmentDate(date)} dateFormat="dd/MM/yyyy" />
                                                {errors.date && <div className="mt-2 text-danger">{errors.date}</div>}
                                            </div>
                                            <div>
                                                <Form.Select size="sm" onChange={(e) => setAppointmentTime(e.target.value)} value={appointmentTime}>
                                                    {hours.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item}>{item}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                                {errors.time && <div className="mt-2 text-danger">{errors.time}</div>}
                                            </div>
                                            <Button onClick={(e) => createAppointment(e)} className="btn-sm" variant="primary">Agendar nueva cita</Button>
                                        </Modal.Footer>
                                    )}
                                </Modal>
                                {/* <!--end:Appointments Modal--> */}


                                {/* <!--begin:Cover Modal--> */}
                                <Modal show={coverDialog} onHide={closeCoverDialog} scrollable={true} size="lg" id="cover-dialog">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Carátula de Póliza</Modal.Title>
                                    </Modal.Header>
                                    <Form onSubmit={uploadCover}>
                                        <Modal.Body>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>PDF de Carátula de Póliza</Form.Label>
                                                        <Form.Control type="file" onChange={e => setData('file', e.target.files[0])} accept={'.pdf'} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Modal.Body>

                                        {hasPermission('quotes.insurance.policy.view') && (
                                            <Modal.Footer>
                                                <Col className="mt-3">
                                                    {progress && (
                                                        <progress value={progress.percentage} max="100">
                                                            {progress.percentage}%
                                                        </progress>
                                                    )}
                                                    <Button variant="primary" className='me-3' disabled={processing} type="submit">Guardar Archivo</Button>
                                                </Col>
                                            </Modal.Footer>
                                        )}
                                    </Form>
                                </Modal>
                                {/* <!--end:Cover Modal--> */}

                            </div>
                        </div>
                        <div className="card-header mt-4">
                            <div className="d-flex flex-column">
                                {/* begin::FormInfo  */}
                                <div className="d-flex flex-wrap fw-semibold fs-6 mb-2 pe-2">
                                    <form>
                                        <Row>
                                            <Col lg={4}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Nombre(s)</label>
                                                    <input onChange={(e) => handleMaster(e)} value={policy.master.name == null ? '' : policy.master.name} name="name" type="text" className={classNames({ 'is-invalid': (errors.name) ? true : false, 'form-control': true })} aria-invalid={errors.name ? "true" : "false"} />
                                                    {errors.name && <div className="mt-2 text-danger">{errors.name}</div>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Apellido Paterno</label>
                                                    <input onChange={(e) => handleMaster(e)} value={policy.master.last_name == null ? '' : policy.master.last_name} name="last_name" type="text" className={classNames({ 'is-invalid': (errors.last_name) ? true : false, 'form-control': true })} aria-invalid={errors.last_name ? "true" : "false"} />
                                                    {errors.last_name && <div className="mt-2 text-danger">{errors.last_name}</div>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Apellido Materno</label>
                                                    <input onChange={(e) => handleMaster(e)} value={policy.master.mother_last_name == null ? '' : policy.master.mother_last_name} name="mother_last_name" type="text" className={classNames({ 'is-invalid': (errors.mother_last_name) ? true : false, 'form-control': true })} aria-invalid={errors.mother_last_name ? "true" : "false"} />
                                                    {errors.mother_last_name && <div className="mt-2 text-danger">{errors.mother_last_name}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="separator separator-dashed my-4"></div>
                                        <Row>
                                            <Col lg={4}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">RFC</label>
                                                    <input onChange={(e) => handleMaster(e)} value={policy.master.rfc == null ? '' : policy.master.rfc} name="rfc" type="text" className={classNames({ 'is-invalid': (errors.rfc) ? true : false, 'form-control': true })} aria-invalid={errors.rfc ? "true" : "false"} />
                                                    {errors.rfc && <div className="mt-2 text-danger">{errors.rfc}</div>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Edad</label>
                                                    <input onChange={(e) => handleMaster(e)} disabled={false} value={policy.master.age == null ? '' : policy.master.age} name="age" type="text" className={classNames({ 'is-invalid': (errors.age) ? true : false, 'form-control': true })} aria-invalid={errors.age ? "true" : "false"} />
                                                    {errors.age && <div className="mt-2 text-danger">{errors.age}</div>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Teléfono</label>
                                                    <input onChange={(e) => handleMaster(e)} disabled={false} value={policy.master.phone_number == null ? '' : policy.master.phone_number} name="phone_number" type="text" className={classNames({ 'is-invalid': (errors.phone_number) ? true : false, 'form-control': true })} aria-invalid={errors.phone_number ? "true" : "false"} />
                                                    {errors.phone_number && <div className="mt-2 text-danger">{errors.phone_number}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Calle y número</label>
                                                    <input onChange={(e) => handleMaster(e)} value={policy.master.street_number == null ? '' : policy.master.street_number} name="street_number" type="text" className={classNames({ 'is-invalid': errors.street_number ? true : false, 'form-control': true })} aria-invalid={errors.street_number ? "true" : "false"} />
                                                    {errors.street && <div className="mt-2 text-danger">{errors.street_number}</div>}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Colonia</label>
                                                    <input onChange={(e) => handleMaster(e)} value={policy.master.neighborhood == null ? '' : policy.master.neighborhood} name="neighborhood" type="text" className={classNames({ 'is-invalid': errors.neighborhood ? true : false, 'form-control': true })} aria-invalid={errors.neighborhood ? "true" : "false"} />
                                                    {errors.city && <div className="mt-2 text-danger">{errors.city}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Ciudad</label>
                                                    <input onChange={(e) => handleMaster(e)} value={policy.master.city == null ? '' : policy.master.city} name="city" type="text" className={classNames({ 'is-invalid': errors.city ? true : false, 'form-control': true })} aria-invalid={errors.city ? "true" : "false"} />
                                                    {errors.city && <div className="mt-2 text-danger">{errors.city}</div>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Estado</label>
                                                    <input onChange={(e) => handleMaster(e)} value={policy.master.state == null ? '' : policy.master.state} name="state" type="text" className={classNames({ 'is-invalid': errors.state ? true : false, 'form-control': true })} aria-invalid={errors.state ? "true" : "false"} />
                                                    {errors.state && <div className="mt-2 text-danger">{errors.state}</div>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Código Postal</label>
                                                    <input onChange={(e) => handleMaster(e)} value={policy.master.zip_code == null ? '' : policy.master.zip_code} name="zip_code" type="text" className={classNames({ 'is-invalid': errors.zip_code ? true : false, 'form-control': true })} aria-invalid={errors.zip_code ? "true" : "false"} />
                                                    {errors.zip_code && <div className="mt-2 text-danger">{errors.zip_code}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="separator separator-dashed my-4"></div>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Escuela</label>
                                                    <input onChange={(e) => handleMaster(e)} disabled={false} value={policy.master.school == null ? '' : policy.master.school} name="school" type="text" className={classNames({ 'is-invalid': (errors.school) ? true : false, 'form-control': true })} aria-invalid={errors.school ? "true" : "false"} />
                                                    {errors.school && <div className="mt-2 text-danger">{errors.school}</div>}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Dirección</label>
                                                    <input onChange={(e) => handleMaster(e)} disabled={false} value={policy.master.address_school == null ? '' : policy.master.address_school} name="address_school" type="text" className={classNames({ 'is-invalid': (errors.address_school) ? true : false, 'form-control': true })} aria-invalid={errors.address_school ? "true" : "false"} />
                                                    {errors.address_school && <div className="mt-2 text-danger">{errors.address_school}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-5">
                                                    <label className="form-label fw-semibold text-muted">Correo Electrónico</label>
                                                    <input onChange={(e) => handleMaster(e)} disabled={false} value={policy.master.email == null ? '' : policy.master.email} name="email" type="text" className={classNames({ 'is-invalid': (errors.email) ? true : false, 'form-control': true })} aria-invalid={errors.email ? "true" : "false"} />
                                                    {errors.email && <div className="mt-2 text-danger">{errors.email}</div>}
                                                </div>
                                            </Col>
                                            {/* <Col lg={6}>
                                                <label className="form-label fw-semibold text-muted">Ingresos mensuales aproximados</label>
                                                <div className="input-group mb-5">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <input onChange={(e) => handleMaster(e)} disabled={false} value={policy.master.income == null ? '' : policy.master.income} name="income" type="number" className={classNames({ 'is-invalid': (errors.income) ? true : false, 'form-control': true })} aria-invalid={errors.income ? "true" : "false"} />
                                                    {errors.income && <div className="mt-2 text-danger">{errors.income}</div>}
                                                </div>
                                            </Col> */}
                                        </Row>

                                        {hasPermission('quotes.insurance.masters.edit') && (
                                            <Row>
                                                <Col className="">
                                                    <button onClick={(e) => updateMaster(e)} className="btn btn-primary btn-sm mb-3">Actualizar Maestro</button>
                                                </Col>
                                            </Row>
                                        )}

                                    </form>
                                </div>
                                {/* end::FormInfo  */}
                            </div>
                        </div>
                        {/* begin::Card body  */}
                        <div className="card-header">
                            <div className="d-flex align-items-center">
                                <h5 className="text-gray-900 text-hover-primary fs-3 fw-bold m-0">Datos de Póliza</h5>
                            </div>
                        </div>
                        <div className="card-body p-9">
                            {/* begin::Row  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Estatus de Póliza</label>
                                <div className="col-lg-8">
                                    <select onChange={(e) => handlePolicy(e)} value={policy.status == null ? '' : policy.status} name="status" className="form-select" aria-label="Select example">
                                        <option value="1">Vigente</option>
                                        <option value="0">Terminada</option>
                                        <option value="2">Cancelada</option>
                                    </select>
                                </div>
                            </div>
                            {/* end::Row  */}
                            {/* begin::Row  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Póliza</label>
                                <div className="col-lg-8 fv-row">
                                    <input value={policy.policy == null ? '' : policy.policy} readOnly disabled name="policy" type="text" className={classNames({ 'form-control': true })} />
                                </div>
                            </div>
                            {/* end::Row  */}
                            {/* begin::Input group  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Agrupación</label>
                                <div className="col-lg-8">
                                    <input readOnly disabled value={policy.group == null ? '' : policy.group} name="group" type="text" className={classNames({ 'form-control': true })} />
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Vigencia</label>
                                <div className="col-lg-8">
                                    <DatePicker popperPlacement="top-start" className="form-control form-control-sm" locale="es" selected={issueDate} onChange={(date) => setIssueDate(date)} dateFormat="dd/MM/yyyy" />
                                    {errors.issue && <div className="mt-2 text-danger">{errors.issue}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Row  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Emisor</label>
                                <div className="col-lg-8 fv-row">
                                    <input value={policy.emitter_name == null ? '' : policy.emitter_name} readOnly disabled name="emitter_name" type="text" className={classNames({ 'form-control': true })} />
                                </div>
                            </div>
                            {/* end::Row  */}
                            {/* begin::Row  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Tipo de Producto</label>
                                <div className="col-lg-8 fv-row">
                                    <Form.Select value={policy.product_type_name} onChange={(e) => handlePolicy(e)} name="product_type_name" className={classNames({ 'is-invalid': (errors.product_type_name) ? true : false, })} aria-invalid={errors.product_type_name ? "true" : "false"} >
                                        {product_types.length > 0 && (
                                            product_types.map((product, index) => {
                                                return <option key={index} value={product.name}>{product.name}</option>;
                                            }))}
                                    </Form.Select>
                                    {errors.product_type_name && <div className="mt-2 text-danger">{errors.product_type_name}</div>}
                                </div>
                            </div>
                            {/* end::Row  */}
                            {/* begin::Input group  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Agente 1</label>
                                <div className="col-lg-8">
                                    <Form.Select
                                        value={policy.agent1}
                                        onChange={(e) => handlePolicy(e)}
                                        name="agent1"
                                        className={classNames({ 'is-invalid': errors.agent1 ? true : false })}
                                        aria-invalid={errors.agent1 ? "true" : "false"}
                                    >
                                        <option value=""></option>
                                        {filteredAdvisers.agent1Options.map((item, index) => (
                                            <option key={index} value={item.id}>{item.user_name} {item.lastname_ap} {item.lastname_am} ({item.type === 'adviser' ? 'Asesor' : 'Empleado'})</option>
                                        ))}
                                    </Form.Select>
                                </div>
                                {errors.agent1 && <div className="mt-2 text-danger">{errors.agent1}</div>}
                            </div>
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">% Agente 1</label>
                                <div className="col-lg-8">
                                    <input onChange={(e) => handlePolicy(e)} value={policy.porcent1 == null ? '' : policy.porcent1} name="porcent1" type="text" className={classNames({ 'is-invalid': (errors.porcent1) ? true : false, 'form-control': true })} />
                                    {errors.porcent1 && <div className="mt-2 text-danger">{errors.porcent1}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Agente 2</label>
                                <div className="col-lg-8">
                                    <Form.Select
                                        value={policy.agent2}
                                        onChange={(e) => handlePolicy(e)}
                                        name="agent2"
                                        className={classNames({ 'is-invalid': errors.agent2 ? true : false })}
                                        aria-invalid={errors.agent2 ? "true" : "false"}
                                    >
                                        <option value=""></option>
                                        {filteredAdvisers.agent2Options.map((item, index) => (
                                            <option key={index} value={item.id}>{item.user_name} {item.lastname_ap} {item.lastname_am} ({item.type === 'adviser' ? 'Asesor' : 'Empleado'})</option>
                                        ))}
                                    </Form.Select>
                                </div>
                                {errors.agent2 && <div className="mt-2 text-danger">{errors.agent2}</div>}
                            </div>
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">% Agente 2</label>
                                <div className="col-lg-8">
                                    <input onChange={(e) => handlePolicy(e)} value={policy.porcent2 == null ? '' : policy.porcent2} name="porcent2" type="text" className={classNames({ 'is-invalid': (errors.porcent2) ? true : false, 'form-control': true })} />
                                    {errors.porcent2 && <div className="mt-2 text-danger">{errors.porcent2}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Agente 3</label>
                                <div className="col-lg-8">
                                    <Form.Select
                                        value={policy.agent3}
                                        onChange={(e) => handlePolicy(e)}
                                        name="agent3"
                                        className={classNames({ 'is-invalid': errors.agent3 ? true : false })}
                                        aria-invalid={errors.agent3 ? "true" : "false"}
                                    >
                                        <option value=""></option>
                                        {filteredAdvisers.agent3Options.map((item, index) => (
                                            <option key={index} value={item.id}>{item.user_name} {item.lastname_ap} {item.lastname_am} ({item.type === 'adviser' ? 'Asesor' : 'Empleado'})</option>
                                        ))}
                                    </Form.Select>
                                </div>
                                {errors.agent3 && <div className="mt-2 text-danger">{errors.agent3}</div>}
                            </div>
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">% Agente 3</label>
                                <div className="col-lg-8">
                                    <input
                                        onChange={(e) => handlePolicy(e)}
                                        value={policy.porcent3 === null ? '' : policy.porcent3}
                                        name="porcent3"
                                        type="text"
                                        className={classNames({ 'is-invalid': errors.porcent3 ? true : false, 'form-control': true })}
                                    />
                                    {errors.porcent3 && <div className="mt-2 text-danger">{errors.porcent3}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">Suma asegurada</label>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input onChange={(e) => handlePolicy(e)} value={policy.sum_assured == null ? '' : policy.sum_assured} name="sum_assured" type="number" className={classNames({ 'is-invalid': (errors.sum_assured) ? true : false, 'form-control': true })} aria-invalid={errors.sum_assured ? "true" : "false"} />
                                        {errors.sum_assured && <div className="mt-2 text-danger">{errors.sum_assured}</div>}
                                    </div>
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">P Total</label>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input onChange={(e) => handlePolicy(e)} value={policy.p_total == null ? '' : policy.p_total} name="p_total" type="number" className={classNames({ 'is-invalid': (errors.p_total) ? true : false, 'form-control': true })} aria-invalid={errors.p_total ? "true" : "false"} />
                                        {errors.p_total && <div className="mt-2 text-danger">{errors.p_total}</div>}
                                    </div>
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Nombre Cobertura</label>
                                <div className="col-lg-8">
                                    <input onChange={(e) => handlePolicy(e)} value={policy.coverage_name == null ? '' : policy.coverage_name} name="coverage_name" type="text" className={classNames({ 'is-invalid': (errors.coverage_name) ? true : false, 'form-control': true })} />
                                    {errors.coverage_name && <div className="mt-2 text-danger">{errors.coverage_name}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Apellido Paterno</label>
                                <div className="col-lg-8">
                                    <input onChange={(e) => handlePolicy(e)} value={policy.lastname_paternal == null ? '' : policy.lastname_paternal} name="lastname_paternal" type="text" className={classNames({ 'is-invalid': (errors.lastname_paternal) ? true : false, 'form-control': true })} />
                                    {errors.lastname_paternal && <div className="mt-2 text-danger">{errors.lastname_paternal}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Apellido Materno</label>
                                <div className="col-lg-8">
                                    <input onChange={(e) => handlePolicy(e)} value={policy.lastname_maternal == null ? '' : policy.lastname_maternal} name="lastname_maternal" type="text" className={classNames({ 'is-invalid': (errors.lastname_maternal) ? true : false, 'form-control': true })} />
                                    {errors.lastname_maternal && <div className="mt-2 text-danger">{errors.lastname_maternal}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">RFC Cobertura</label>
                                <div className="col-lg-8">
                                    <input onChange={(e) => handlePolicy(e)} value={policy.rfc_coverage == null ? '' : policy.rfc_coverage} name="rfc_coverage" type="text" className={classNames({ 'is-invalid': (errors.rfc_coverage) ? true : false, 'form-control': true })} />
                                    {errors.rfc_coverage && <div className="mt-2 text-danger">{errors.rfc_coverage}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Importe DIPMC</label>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input onChange={(e) => handlePolicy(e)} value={policy.dipmc_amount == null ? '' : policy.dipmc_amount} name="dipmc_amount" type="number" className={classNames({ 'is-invalid': (errors.dipmc_amount) ? true : false, 'form-control': true })} aria-invalid={errors.dipmc_amount ? "true" : "false"} />
                                        {errors.dipmc_amount && <div className="mt-2 text-danger">{errors.dipmc_amount}</div>}
                                    </div>
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Importe BITP</label>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input onChange={(e) => handlePolicy(e)} value={policy.bitp_amount == null ? '' : policy.bitp_amount} name="bitp_amount" type="number" className={classNames({ 'is-invalid': (errors.bitp_amount) ? true : false, 'form-control': true })} aria-invalid={errors.bitp_amount ? "true" : "false"} />
                                        {errors.bitp_amount && <div className="mt-2 text-danger">{errors.bitp_amount}</div>}
                                    </div>
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Importe GF</label>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input onChange={(e) => handlePolicy(e)} value={policy.gf_amount == null ? '' : policy.gf_amount} name="gf_amount" type="number" className={classNames({ 'is-invalid': (errors.gf_amount) ? true : false, 'form-control': true })} aria-invalid={errors.gf_amount ? "true" : "false"} />
                                        {errors.gf_amount && <div className="mt-2 text-danger">{errors.gf_amount}</div>}
                                    </div>
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Importe BAF</label>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input onChange={(e) => handlePolicy(e)} value={policy.baf_amount == null ? '' : policy.baf_amount} name="baf_amount" type="number" className={classNames({ 'is-invalid': (errors.baf_amount) ? true : false, 'form-control': true })} aria-invalid={errors.baf_amount ? "true" : "false"} />
                                        {errors.baf_amount && <div className="mt-2 text-danger">{errors.baf_amount}</div>}
                                    </div>
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Importe SEVI</label>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input onChange={(e) => handlePolicy(e)} value={policy.sevi_amount == null ? '' : policy.sevi_amount} name="sevi_amount" type="number" className={classNames({ 'is-invalid': (errors.sevi_amount) ? true : false, 'form-control': true })} aria-invalid={errors.sevi_amount ? "true" : "false"} />
                                        {errors.sevi_amount && <div className="mt-2 text-danger">{errors.sevi_amount}</div>}
                                    </div>
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">BIT</label>
                                <div className="col-lg-8">
                                    <select onChange={(e) => handlePolicy(e)} value={policy.bit == null ? '' : policy.bit} name="bit" className="form-select" aria-label="Select example">
                                        <option value="1">Incluido</option>
                                        <option value="0">No Incluido</option>
                                    </select>
                                    {errors.bit && <div className="mt-2 text-danger">{errors.bit}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Pagado hasta</label>
                                <div className="col-lg-8 d-flex align-items-center justify-content-between">
                                    <div>
                                        <DatePicker popperPlacement="top-start" className="form-control form-control-sm" locale="es" selected={paidupDate} onChange={(date) => setPaidupDate(date)} dateFormat="dd/MM/yyyy" />
                                        {errors.paid_up && <div className="mt-2 text-danger">{errors.paid_up}</div>}
                                    </div>
                                    <div>
                                        {hasPermission('quotes.insurance.payments.history') && (
                                            <a href="#" onClick={() => { PaymentsHistoryByPolicyRef.current.openDialog(policy) }}>Consultar Histórico</a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* end::Card body  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Descuento Quincenal</label>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input onChange={(e) => handlePolicy(e)} value={policy.biweekly_discount == null ? '' : policy.biweekly_discount} name="biweekly_discount" type="number" className={classNames({ 'is-invalid': (errors.biweekly_discount) ? true : false, 'form-control': true })} aria-invalid={errors.biweekly_discount ? "true" : "false"} />
                                        {errors.biweekly_discount && <div className="mt-2 text-danger">{errors.biweekly_discount}</div>}
                                    </div>
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Retenedor</label>
                                <div className="col-lg-8">
                                    <Form.Select onChange={(e) => handlePolicy(e)} value={policy.company_name} name="company_name">
                                        <option value=""></option>
                                        {companies.map((item, index) => {
                                            return <option key={index} value={item.name}>{item.office_location_name} - {item.name}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.company_name && <div className="mt-2 text-danger">{errors.company_name}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {/* begin::Input group  */}
                            <div className="row mb-5">
                                <label className="col-lg-4 fw-semibold text-muted">Oficina</label>
                                <div className="col-lg-8">
                                    <Form.Select onChange={(e) => handlePolicy(e)} value={policy.office_name} name="office_name">
                                        <option value=""></option>
                                        {offices.map((item, index) => {
                                            return <option key={index} value={item.nombre}>{item.office_location_name} - {item.nombre}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.office_name && <div className="mt-2 text-danger">{errors.office_name}</div>}
                                </div>
                            </div>
                            {/* end::Input group  */}
                            {hasPermission('quotes.insurance.policy.edit') && (
                                <Row>
                                    <Col className="">
                                        <button onClick={(e) => updatePolicy(e)} className="btn btn-primary mb-3">Actualizar Póliza</button>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* <!--begin:Negative Amounts Modal--> */}
            <Modal show={negativeAmountsDialog} onHide={closeNegativeAmountsDialog} scrollable={true} size="lg" id="negativeAmounts-dialog">
                <Modal.Header closeButton>
                    <Modal.Title>Pólizas con Saldos Negativos</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col style={{ textAlign: 'center' }} md={{ span: 3, offset: 3 }}>
                                <p>Reporte General</p>
                                <a href={route('withdrawal.excel.export.negativeAmmounts')} className="btn btn-primary mb-3">Descargar</a>
                            </Col>
                            <Col style={{ textAlign: 'center' }} md={3}>
                                <p>Reporte por Mes</p>
                                <Form.Select className='mb-4' size="sm" onChange={(e) => setNegativeAmountsMonth(e.target.value)} value={negativeAmountsMonth}>
                                    {months.map((item) => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        );
                                    })}
                                </Form.Select>
                                <button onClick={e => downloadNegativeAmountsByMonth(e)} className="btn btn-primary mb-3">Descargar</button>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={closeNegativeAmountsDialog} className="btn-sm" variant="secondary">Cerrar</Button>
                </Modal.Footer>
            </Modal>
            {/* <!--end:Negative Amounts Moda--> */}

            {/*  end::)} */}
        </div>
        {/*  end::Inbox App - View & Reply  */}
    </>;
}

List.layout = page => <Layout children={page} title="Pólizas" />

export default List
