import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import classNames from "classnames";
import { Link, usePage } from '@inertiajs/inertia-react';
import "react-datepicker/dist/react-datepicker.css";
import { Inertia } from '@inertiajs/inertia';
import { hasPermission } from '../../utils/Permissions';
import ReactPaginate from "react-paginate";

const PaidupDateHistory = forwardRef((props, ref) => {
    const { flash, auth } = usePage().props;
    const [errors, setErrors] = useState({});

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => setDialog(false);
    const showDialog = () => setDialog(true);

    useImperativeHandle(ref, () => {
        return {
            openDialog(policy_id){
                setPolicyId(policy_id);
                setStartYear(moment().year());
                setStartFortnight('*');
                setEndYear(moment().year());
                setEndFortnight('*');
                showDialog();

                getData(policy_id, 1);
            },
        }
    });

    /* FILTERS */

    const [startYear, setStartYear] = useState(moment().year());

    function handleChangeStartYear(e) {
        setStartYear(e.target.value);
    }

    const [startFortnight, setStartFortnight] = useState('*');

    function handleChangeStartFortnight(e) {
        setStartFortnight(e.target.value);
    }

    const [endYear, setEndYear] = useState(moment().year());

    function handleChangeEndYear(e) {
        setEndYear(e.target.value);
    }

    const [endFortnight, setEndFortnight] = useState('*');

    function handleChangeEndFortnight(e) {
        setEndFortnight(e.target.value);
    }

    /* GET DATA */

    const [history, setHistory] = useState([]);
    const [policyId, setPolicyId] = useState([]);

    function getData(policy_id, page_number){
        if(!isValid()) return;

        axios.post(route('paidupDateHistory.getData', policy_id), {
            'startYear' : startYear,
            'startFortnight' : startFortnight,
            'endYear' : endYear,
            'endFortnight' : endFortnight,
            'page' : page_number,
        })
            .then((response) => {
                if(page_number==1){
                    setRemountComponent(Math.random()); // refresh pagination when the page is 1!
                }
                setHistory(response.data['items']);
            }).catch((error) => {
                toastr.error("Algo salió mal, intentalo nuevamente");
            });

    }

    function isValid(){
        if(parseInt(endYear) < parseInt(startYear)){
            Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "El año final no puede ser menor al inicial",
            });
            return false;
        }else if(parseInt(endYear) == parseInt(startYear)){
            var init = startFortnight=='*' ? 30 : parseInt(startFortnight);
            var end = endFortnight=='*' ? 30 : parseInt(endFortnight);
            if(parseInt(end) < parseInt(init)){
                Swal.fire({
                    icon: "warning",
                    title: "Oops...",
                    text: "La quincena final no puede ser menor al inicial",
                });
                return false;
            }
        }

        return true;
    }

    function exportHistory(type){
        if(!isValid()) return;

        const url = type=='pdf' ? 'paidup_date_history.pdf.export' : 'paidup_date_history.excel.export';

        Inertia.get(route(url, policyId),
            {
                'startYear' : startYear,
                'startFortnight' : startFortnight,
                'endYear' : endYear,
                'endFortnight' : endFortnight,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        )
    }

    /* List of Years & fortnight */

    const firstYear = moment('2000-01-01', 'YYYY-MM-DD');
    const diff = moment().year() - firstYear.year() + 1;
    const years = [...Array(diff).keys()].map(i => i + firstYear.year()).reverse();

    const fortnights = [...Array(26).keys()];

    /* Paginate */

    const [remountComponent, setRemountComponent] = useState(0);

    return <>
        {/* <!--begin:Appointments Modal--> */}
        <Modal show={dialog} onHide={closeDialog} scrollable={true} size="xl" id="dialog">
            <Modal.Header closeButton className="py-4">
                <Modal.Title>Histórico de pagos</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row className='mb-2'>
                    <Col>
                        <Form.Label className="fw-bolder">Desde</Form.Label>
                        <div className="d-flex gap-3">
                            <div className="w-50">
                                <Form.Label className="mb-0">Año</Form.Label>
                                <Form.Select size="sm" className="form-control" value={startYear} onChange={handleChangeStartYear}>
                                    {years.length > 0 && (
                                        years.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>;
                                        })
                                    )}
                                </Form.Select>
                            </div>
                            <div className="w-50">
                                <Form.Label className="mb-0">Quincena</Form.Label>
                                <Form.Select size="sm" className="form-control" value={startFortnight} onChange={handleChangeStartFortnight}>
                                    <option key='all' value={'*'}>Todas</option>
                                    {fortnights.length > 0 && (
                                        fortnights.map((item, index) => {
                                            return <option key={index} value={item+1}>{item+1}</option>;
                                        })
                                    )}
                                </Form.Select>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <Form.Label className="fw-bolder">Hasta</Form.Label>
                        <div className="d-flex gap-3">
                            <div className="w-50">
                                <Form.Label className="mb-0">Año</Form.Label>
                                <Form.Select size="sm" className="form-control" value={endYear} onChange={handleChangeEndYear}>
                                    {years.length > 0 && (
                                        years.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>;
                                        })
                                    )}
                                </Form.Select>
                            </div>
                            <div className="w-50">
                                <Form.Label className="mb-0">Quincena</Form.Label>
                                <Form.Select size="sm" className="form-control" value={endFortnight} onChange={handleChangeEndFortnight}>
                                    <option key='all' value={'*'}>Todas</option>
                                    {fortnights.length > 0 && (
                                        fortnights.map((item, index) => {
                                            return <option key={index} value={item+1}>{item+1}</option>;
                                        })
                                    )}
                                </Form.Select>
                            </div>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-end gap-3">
                        <Button onClick={() => getData(policyId, 1)} variant="primary" className='btn btn-primary btn-sm' type="submit" >
                            Buscar
                        </Button>
                        <Button onClick={() => exportHistory('pdf')} className='btn btn-sm btn-secondary'>
                            Exportar PDF
                        </Button>
                        <Button onClick={() => exportHistory('excel')} className='btn btn-sm btn-secondary'>
                            Exportar Excel
                        </Button>
                    </Col>
                </Row>

                <br />

                {history.data==undefined || history.data.length==0 ? (
                    <div className="fw-semibold text-muted">
                        {/* Aún no hay registros */}
                    </div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-row-bordered table-row-solid">
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400">
                                    <th className="text-center">Año</th>
                                    <th className="text-start">Quincena</th>
                                    <th className="text-start">RFC</th>
                                    <th className="text-start">Nombre</th>
                                    <th className="text-start">Importe Quincenal</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {history.data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-gray-800 fw-bold text-center">2018</td>
                                            <td className="text-gray-800 fw-bold text-start">18</td>
                                            {/* <td className="text-gray-800 fw-bold">{item.user?.name}</td> */}
                                            <td className="text-gray-800 fw-bold">{item.nombre}</td>
                                            <td className="text-gray-800 fw-bold">Prueba</td>
                                            <td className="text-gray-800 fw-bold">150</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {history.last_page > 1 && (
                            <div key={remountComponent}>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Siguiente »"
                                    onPageChange={(page) => getData(policyId, page.selected+1) }
                                    pageRangeDisplayed={5}
                                    pageCount={history.last_page}
                                    previousLabel="« Anterior"
                                    renderOnZeroPageCount={null}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        )}
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeDialog} className="btn-sm" variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal>
        {/* <!--end:Appointments Modal--> */}
    </>;
})

export default PaidupDateHistory
