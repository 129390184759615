import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import classNames from "classnames";
import DatePicker from "react-datepicker";
registerLocale('es', es)
import { Link, usePage } from '@inertiajs/inertia-react';
import "react-datepicker/dist/react-datepicker.css";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import { Inertia } from '@inertiajs/inertia'

const ScheduleInterview = forwardRef((props, ref) => {
    const { flash, auth } = usePage().props;
    const [errors, setErrors] = useState({});

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => setDialog(false);
    const showDialog = () => setDialog(true);

    useImperativeHandle(ref, () => {
        return {
            newInterviewDialog(prospectId){
                setInterview({
                    'id' : null,
                    'user_id' : auth.user.id,
                    'prospect_id' : prospectId,
                    'modality' : 'online',
                    'status' : 'open',
                    'time_start' : '08:00 AM',
                    'time_end' : '08:00 AM',
                });

                showDialog();
            },

            editInterviewDialog(interview){
                var newinterview = {...interview};
                newinterview.date = moment(newinterview.date, 'YYYY-MM-DD HH:mm:ss').toDate();
                newinterview.time_start = moment(newinterview.time_start, 'HH:mm:ss').format('hh:mm A');
                newinterview.time_end = moment(newinterview.time_end, 'HH:mm:ss').format('hh:mm A');

                setInterview(newinterview);

                showDialog();
            }
        }
    });

    /* INTERVIEW */

    const startOfDay = moment().startOf('day');
    const hours = Array(48).fill(null).map((_, i) => startOfDay.add(i ? 30 : 0, 'minutes').format('hh:mm A'));

    const [interview, setInterview] = useState({});

    function handleInterview(e){
        const newinterview = {...interview};
        newinterview[e.target.name]=e.target.value;
        setInterview(newinterview);
    }

    function setInterviewDate(date){
        const newinterview = {...interview};
        newinterview['date']=date;
        setInterview(newinterview);
    }

    function updateInterview(e){
        e.preventDefault();
        setErrors({});

        Swal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function(result) {
            if (result.value) {

                if(interview.id == null){ // new interview
                    axios.post(route('calendar.add'), interview)
                        .then((response) => {
                            toastr.success('La entrevista fue guardada correctamente');
                            closeDialog();
                            props.onChange();
                        }).catch((error) => {
                            if(error.response.data.success !== undefined && error.response.data.success===false){
                                toastr.error(error.response.data.message);
                            }else{
                                setErrors(error.response.data.errors);
                                toastr.error("Hay errores en el formulario");
                            }
                        });
                }else{ // update interview
                    axios.post(route('calendar.update', interview.id), interview)
                        .then((response) => {
                            toastr.success('La entrevista fue actualizada correctamente');
                            closeDialog();
                            props.onChange();
                        }).catch((error) => {
                            if(error.response.data.success !== undefined && error.response.data.success===false){
                                toastr.error(error.response.data.message);
                            }else{
                                setErrors(error.response.data.errors);
                                toastr.error("Hay errores en el formulario");
                            }
                        });
                }

            }
        });
    }

    return <>
        {/* <!--begin:Appointments Modal--> */}
        <Modal show={dialog} onHide={closeDialog} scrollable={true} size="lg" id="dialog">
            <Modal.Header closeButton className="py-4">
                <Modal.Title>Agendar Entrevista</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col className="mb-2">
                        <label className="mb-0 form-label form-label-sm fw-semibold text-muted">Modalidad</label>
                        <Form.Select
                            size="sm"
                            value={interview.modality==null?'':interview.modality}
                            className={classNames({'is-invalid': (errors.modality) ? true : false})}
                            name="modality"
                            onChange={(e)=>handleInterview(e)}
                        >
                            <option value="face-to-face">Presencial</option>
                            <option value="online">En Linea</option>
                        </Form.Select>
                        {errors.modality && <div className="mt-2 text-danger">{errors.modality}</div>}
                    </Col>
                    <Col className="mb-2">
                        <label className="mb-0 form-label form-label-sm fw-semibold text-muted">Estatus</label>
                        <Form.Select
                            size="sm"
                            value={interview.status==null?'':interview.status}
                            className={classNames({'is-invalid': (errors.status) ? true : false})}
                            name="status"
                            onChange={(e)=>handleInterview(e)}
                        >
                            <option value="open">Abierta</option>
                            <option value="close">Cerrada</option>
                        </Form.Select>
                        {errors.status && <div className="mt-2 text-danger">{errors.status}</div>}
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-2">
                        <label className="mb-0 form-label form-label-sm fw-semibold text-muted">Lugar de la cita / Código de sesión</label>
                        <input
                            onChange={(e)=>handleInterview(e)}
                            value={interview.appointment_place==null?'':interview.appointment_place}
                            name="appointment_place"
                            type="text"
                            className={classNames({'is-invalid': (errors.appointment_place) ? true : false, 'form-control form-control-sm': true})}
                            aria-invalid={errors.appointment_place ? "true" : "false"}
                        />
                        {errors.appointment_place && <div className="mt-2 text-danger">{errors.appointment_place}</div>}
                    </Col>
                    <Col className="mb-2">
                        <label className="mb-0 form-label form-label-sm fw-semibold text-muted">Correo de entrevistado</label>
                        <input
                            onChange={(e)=>handleInterview(e)}
                            value={interview.email==null?'':interview.email}
                            name="email"
                            type="text"
                            className={classNames({'is-invalid': (errors.email) ? true : false, 'form-control form-control-sm': true})}
                            aria-invalid={errors.email ? "true" : "false"}
                        />
                        {errors.email && <div className="mt-2 text-danger">{errors.email}</div>}
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-2">
                        <label className="mb-0 form-label form-label-sm fw-semibold text-muted">Fecha</label>
                        <DatePicker
                            popperPlacement="top-start"
                            className={classNames({'is-invalid': (errors.date) ? true : false, 'form-control form-control-sm': true})}
                            locale="es"
                            selected={interview.date==null?'':interview.date}
                            name="date"
                            onChange={(date) => setInterviewDate(date)}
                            dateFormat="dd/MM/yyyy"
                        />
                        {errors.date && <div className="mt-2 text-danger">{errors.date}</div>}
                    </Col>
                    <Col className="mb-2">
                        <label className="mb-0 form-label form-label-sm fw-semibold text-muted">Desde</label>
                        <Form.Select
                            size="sm"
                            value={interview.time_start==null?'':interview.time_start}
                            className={classNames({'is-invalid': (errors.time_start) ? true : false})}
                            name="time_start"
                            onChange={(e)=>handleInterview(e)}
                        >
                            {hours.map((item, index) => {
                                return (
                                    <option key={index} value={item}>{item}</option>
                                );
                            })}
                        </Form.Select>
                        {errors.time_start && <div className="mt-2 text-danger">{errors.time_start}</div>}
                    </Col>
                    <Col className="mb-2">
                        <label className="mb-0 form-label form-label-sm fw-semibold text-muted">Hasta</label>
                        <Form.Select
                            size="sm"
                            value={interview.time_end==null?'':interview.time_end}
                            className={classNames({'is-invalid': (errors.time_end) ? true : false})}
                            name="time_end"
                            onChange={(e)=>handleInterview(e)}
                        >
                            {hours.map((item, index) => {
                                return (
                                    <option key={index} value={item}>{item}</option>
                                );
                            })}
                        </Form.Select>
                        {errors.time_end && <div className="mt-2 text-danger">{errors.time_end}</div>}
                    </Col>
                </Row>

                <div className="mt-4">
                    {React.useMemo(() => <FullCalendar
                        locale={esLocale}
                        height={500}
                        plugins={[dayGridPlugin, listPlugin]}
                        initialView='listMonth'
                        weekends={false}
                        headerToolbar={{
                            left: 'listMonth,dayGridMonth,dayGridWeek',
                            center: 'title',
                            right: 'today prev,next'
                        }}
                        eventContent={(eventInfo) => {
                            return (
                              <>
                                <b className="me-1">{eventInfo.timeText}</b>
                                <i>{eventInfo.event.title}</i>
                              </>
                            )
                        }}
                        eventSources={[
                            {
                                events: function(info, successCallback, failureCallback) {

                                    axios.get(route('calendar.get'), {
                                        params: {
                                            'user_id' : auth.user.id,
                                            'start' : info.startStr,
                                            'end' : info.endStr,
                                        }
                                    })
                                        .then((response) => {
                                            successCallback(response.data);
                                        }).catch((error) => {
                                            toastr.error("Oops, algo salió mal");
                                        })
                                },
                            }
                        ]}
                    />, [])}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeDialog} className="btn-sm" variant="secondary">Cerrar</Button>
                <Button onClick={(e)=>updateInterview(e)} className="btn-sm" variant="primary">Agendar Entrevista</Button>
            </Modal.Footer>
        </Modal>
        {/* <!--end:Appointments Modal--> */}
    </>;
})

export default ScheduleInterview
