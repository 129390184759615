import React, { useState } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import { Link, usePage } from '@inertiajs/inertia-react'

const Chat = () => {
    return <>
        {/* <!--begin::Toolbar container--> */}
        <div className="container-xxl d-flex flex-stack pb-3 pb-lg-6">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Private Chat</h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
                <a href="#" className="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Create</a>
            </div>
        </div>
        {/* <!--end::Toolbar container--> */}

        {/* <!--begin::Layout--> */}
        <div className="d-flex flex-column flex-lg-row">
            {/* <!--begin::Sidebar--> */}
            <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
                {/* <!--begin::Contacts--> */}
                <div className="card card-flush">
                    {/* <!--begin::Card header--> */}
                    <div className="card-header pt-7" id="kt_chat_contacts_header">
                        {/* <!--begin::Form--> */}
                        <form className="w-100 position-relative" autoComplete="off">
                            {/* <!--begin::Icon--> */}
                            {/* <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg--> */}
                            <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                                </svg>
                            </span>
                            {/* <!--end::Svg Icon--> */}
                            {/* <!--end::Icon--> */}
                            {/* <!--begin::Input--> */}
                            <input type="text" className="form-control form-control-solid px-15" name="search" defaultValue="" placeholder="Search by username or email..." />
                            {/* <!--end::Input--> */}
                        </form>
                        {/* <!--end::Form--> */}
                    </div>
                    {/* <!--end::Card header--> */}
                    {/* <!--begin::Card body--> */}
                    <div className="card-body pt-5" id="kt_chat_contacts_body">
                        {/* <!--begin::List--> */}
                        <div className="scroll-y me-n5 pe-5 h-200px h-lg-auto" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_toolbar, #kt_app_toolbar, #kt_footer, #kt_app_footer, #kt_chat_contacts_header" data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_contacts_body" data-kt-scroll-offset="5px">
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">M</span>
                                        <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div>
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Melody Macy</a>
                                        <div className="fw-semibold text-muted">melody@altbox.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">1 day</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                            {/* <!--begin::Separator--> */}
                            <div className="separator separator-dashed d-none"></div>
                            {/* <!--end::Separator--> */}
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <img alt="Pic" src="/metronic/assets/media/avatars/300-1.jpg" />
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Max Smith</a>
                                        <div className="fw-semibold text-muted">max@kt.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">5 hrs</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                            {/* <!--begin::Separator--> */}
                            <div className="separator separator-dashed d-none"></div>
                            {/* <!--end::Separator--> */}
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <img alt="Pic" src="/metronic/assets/media/avatars/300-5.jpg" />
                                        <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div>
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Sean Bean</a>
                                        <div className="fw-semibold text-muted">sean@dellito.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">2 weeks</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                            {/* <!--begin::Separator--> */}
                            <div className="separator separator-dashed d-none"></div>
                            {/* <!--end::Separator--> */}
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <img alt="Pic" src="/metronic/assets/media/avatars/300-25.jpg" />
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Brian Cox</a>
                                        <div className="fw-semibold text-muted">brian@exchange.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">1 day</span>
                                    <span className="badge badge-sm badge-circle badge-light-warning">9</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                            {/* <!--begin::Separator--> */}
                            <div className="separator separator-dashed d-none"></div>
                            {/* <!--end::Separator--> */}
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <span className="symbol-label bg-light-warning text-warning fs-6 fw-bolder">C</span>
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Mikaela Collins</a>
                                        <div className="fw-semibold text-muted">mik@pex.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">2 weeks</span>
                                    <span className="badge badge-sm badge-circle badge-light-danger">5</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                            {/* <!--begin::Separator--> */}
                            <div className="separator separator-dashed d-none"></div>
                            {/* <!--end::Separator--> */}
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <img alt="Pic" src="/metronic/assets/media/avatars/300-9.jpg" />
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Francis Mitcham</a>
                                        <div className="fw-semibold text-muted">f.mit@kpmg.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">3 hrs</span>
                                    <span className="badge badge-sm badge-circle badge-light-success">2</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                            {/* <!--begin::Separator--> */}
                            <div className="separator separator-dashed d-none"></div>
                            {/* <!--end::Separator--> */}
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">O</span>
                                        <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div>
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Olivia Wild</a>
                                        <div className="fw-semibold text-muted">olivia@corpmail.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">1 day</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                            {/* <!--begin::Separator--> */}
                            <div className="separator separator-dashed d-none"></div>
                            {/* <!--end::Separator--> */}
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <span className="symbol-label bg-light-primary text-primary fs-6 fw-bolder">N</span>
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Neil Owen</a>
                                        <div className="fw-semibold text-muted">owen.neil@gmail.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">20 hrs</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                            {/* <!--begin::Separator--> */}
                            <div className="separator separator-dashed d-none"></div>
                            {/* <!--end::Separator--> */}
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <img alt="Pic" src="/metronic/assets/media/avatars/300-23.jpg" />
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Dan Wilson</a>
                                        <div className="fw-semibold text-muted">dam@consilting.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">3 hrs</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                            {/* <!--begin::Separator--> */}
                            <div className="separator separator-dashed d-none"></div>
                            {/* <!--end::Separator--> */}
                            {/* <!--begin::User--> */}
                            <div className="d-flex flex-stack py-4">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Avatar--> */}
                                    <div className="symbol symbol-45px symbol-circle">
                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">E</span>
                                        <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div>
                                    </div>
                                    {/* <!--end::Avatar--> */}
                                    {/* <!--begin::Details--> */}
                                    <div className="ms-5">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">Emma Bold</a>
                                        <div className="fw-semibold text-muted">emma@intenso.com</div>
                                    </div>
                                    {/* <!--end::Details--> */}
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Lat seen--> */}
                                <div className="d-flex flex-column align-items-end ms-2">
                                    <span className="text-muted fs-7 mb-1">3 hrs</span>
                                </div>
                                {/* <!--end::Lat seen--> */}
                            </div>
                            {/* <!--end::User--> */}
                        </div>
                        {/* <!--end::List--> */}
                    </div>
                    {/* <!--end::Card body--> */}
                </div>
                {/* <!--end::Contacts--> */}
            </div>
            {/* <!--end::Sidebar--> */}
            {/* <!--begin::Content--> */}
            <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                {/* <!--begin::Messenger--> */}
                <div className="card" id="kt_chat_messenger">
                    {/* <!--begin::Card header--> */}
                    <div className="card-header" id="kt_chat_messenger_header">
                        {/* <!--begin::Title--> */}
                        <div className="card-title">
                            {/* <!--begin::User--> */}
                            <div className="d-flex justify-content-center flex-column me-3">
                                <a href="#" className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">Brian Cox</a>
                                {/* <!--begin::Info--> */}
                                <div className="mb-0 lh-1">
                                    <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                                    <span className="fs-7 fw-semibold text-muted">Active</span>
                                </div>
                                {/* <!--end::Info--> */}
                            </div>
                            {/* <!--end::User--> */}
                        </div>
                        {/* <!--end::Title--> */}
                        {/* <!--begin::Card toolbar--> */}
                        <div className="card-toolbar">
                            {/* <!--begin::Menu--> */}
                            <div className="me-n3">
                                <button className="btn btn-sm btn-icon btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i className="bi bi-three-dots fs-2"></i>
                                </button>
                                {/* <!--begin::Menu 3--> */}
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">
                                    {/* <!--begin::Heading--> */}
                                    <div className="menu-item px-3">
                                        <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Contacts</div>
                                    </div>
                                    {/* <!--end::Heading--> */}
                                    {/* <!--begin::Menu item--> */}
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Add Contact</a>
                                    </div>
                                    {/* <!--end::Menu item--> */}
                                    {/* <!--begin::Menu item--> */}
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link flex-stack px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">Invite Contacts 
                                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a contact email to send an invitation"></i></a>
                                    </div>
                                    {/* <!--end::Menu item--> */}
                                    {/* <!--begin::Menu item--> */}
                                    <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                        <a href="#" className="menu-link px-3">
                                            <span className="menu-title">Groups</span>
                                            <span className="menu-arrow"></span>
                                        </a>
                                        {/* <!--begin::Menu sub--> */}
                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            {/* <!--begin::Menu item--> */}
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-bs-toggle="tooltip" title="Coming soon">Create Group</a>
                                            </div>
                                            {/* <!--end::Menu item--> */}
                                            {/* <!--begin::Menu item--> */}
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-bs-toggle="tooltip" title="Coming soon">Invite Members</a>
                                            </div>
                                            {/* <!--end::Menu item--> */}
                                            {/* <!--begin::Menu item--> */}
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-bs-toggle="tooltip" title="Coming soon">Settings</a>
                                            </div>
                                            {/* <!--end::Menu item--> */}
                                        </div>
                                        {/* <!--end::Menu sub--> */}
                                    </div>
                                    {/* <!--end::Menu item--> */}
                                    {/* <!--begin::Menu item--> */}
                                    <div className="menu-item px-3 my-1">
                                        <a href="#" className="menu-link px-3" data-bs-toggle="tooltip" title="Coming soon">Settings</a>
                                    </div>
                                    {/* <!--end::Menu item--> */}
                                </div>
                                {/* <!--end::Menu 3--> */}
                            </div>
                            {/* <!--end::Menu--> */}
                        </div>
                        {/* <!--end::Card toolbar--> */}
                    </div>
                    {/* <!--end::Card header--> */}
                    {/* <!--begin::Card body--> */}
                    <div className="card-body" id="kt_chat_messenger_body">
                        {/* <!--begin::Messages--> */}
                        <div className="scroll-y me-n5 pe-5 h-300px h-lg-auto" data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer" data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_messenger_body" data-kt-scroll-offset="5px">
                            {/* <!--begin::Message(in)--> */}
                            <div className="d-flex justify-content-start mb-10">
                                {/* <!--begin::Wrapper--> */}
                                <div className="d-flex flex-column align-items-start">
                                    {/* <!--begin::User--> */}
                                    <div className="d-flex align-items-center mb-2">
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/metronic/assets/media/avatars/300-25.jpg" />
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                        {/* <!--begin::Details--> */}
                                        <div className="ms-3">
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Brian Cox</a>
                                            <span className="text-muted fs-7 mb-1">2 mins</span>
                                        </div>
                                        {/* <!--end::Details--> */}
                                    </div>
                                    {/* <!--end::User--> */}
                                    {/* <!--begin::Text--> */}
                                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">How likely are you to recommend our company to your friends and family ?</div>
                                    {/* <!--end::Text--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                            </div>
                            {/* <!--end::Message(in)--> */}
                            {/* <!--begin::Message(out)--> */}
                            <div className="d-flex justify-content-end mb-10">
                                {/* <!--begin::Wrapper--> */}
                                <div className="d-flex flex-column align-items-end">
                                    {/* <!--begin::User--> */}
                                    <div className="d-flex align-items-center mb-2">
                                        {/* <!--begin::Details--> */}
                                        <div className="me-3">
                                            <span className="text-muted fs-7 mb-1">5 mins</span>
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">You</a>
                                        </div>
                                        {/* <!--end::Details--> */}
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/metronic/assets/media/avatars/300-1.jpg" />
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                    </div>
                                    {/* <!--end::User--> */}
                                    {/* <!--begin::Text--> */}
                                    <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text">Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.</div>
                                    {/* <!--end::Text--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                            </div>
                            {/* <!--end::Message(out)--> */}
                            {/* <!--begin::Message(in)--> */}
                            <div className="d-flex justify-content-start mb-10">
                                {/* <!--begin::Wrapper--> */}
                                <div className="d-flex flex-column align-items-start">
                                    {/* <!--begin::User--> */}
                                    <div className="d-flex align-items-center mb-2">
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/metronic/assets/media/avatars/300-25.jpg" />
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                        {/* <!--begin::Details--> */}
                                        <div className="ms-3">
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Brian Cox</a>
                                            <span className="text-muted fs-7 mb-1">1 Hour</span>
                                        </div>
                                        {/* <!--end::Details--> */}
                                    </div>
                                    {/* <!--end::User--> */}
                                    {/* <!--begin::Text--> */}
                                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">Ok, Understood!</div>
                                    {/* <!--end::Text--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                            </div>
                            {/* <!--end::Message(in)--> */}
                            {/* <!--begin::Message(out)--> */}
                            <div className="d-flex justify-content-end mb-10">
                                {/* <!--begin::Wrapper--> */}
                                <div className="d-flex flex-column align-items-end">
                                    {/* <!--begin::User--> */}
                                    <div className="d-flex align-items-center mb-2">
                                        {/* <!--begin::Details--> */}
                                        <div className="me-3">
                                            <span className="text-muted fs-7 mb-1">2 Hours</span>
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">You</a>
                                        </div>
                                        {/* <!--end::Details--> */}
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/metronic/assets/media/avatars/300-1.jpg" />
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                    </div>
                                    {/* <!--end::User--> */}
                                    {/* <!--begin::Text--> */}
                                    <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text">You’ll receive notifications for all issues, pull requests!</div>
                                    {/* <!--end::Text--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                            </div>
                            {/* <!--end::Message(out)--> */}
                            {/* <!--begin::Message(in)--> */}
                            <div className="d-flex justify-content-start mb-10">
                                {/* <!--begin::Wrapper--> */}
                                <div className="d-flex flex-column align-items-start">
                                    {/* <!--begin::User--> */}
                                    <div className="d-flex align-items-center mb-2">
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/metronic/assets/media/avatars/300-25.jpg" />
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                        {/* <!--begin::Details--> */}
                                        <div className="ms-3">
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Brian Cox</a>
                                            <span className="text-muted fs-7 mb-1">3 Hours</span>
                                        </div>
                                        {/* <!--end::Details--> */}
                                    </div>
                                    {/* <!--end::User--> */}
                                    {/* <!--begin::Text--> */}
                                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">You can unwatch this repository immediately by clicking here: 
                                    <a href="https://keenthemes.com">Keenthemes.com</a></div>
                                    {/* <!--end::Text--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                            </div>
                            {/* <!--end::Message(in)--> */}
                            {/* <!--begin::Message(out)--> */}
                            <div className="d-flex justify-content-end mb-10">
                                {/* <!--begin::Wrapper--> */}
                                <div className="d-flex flex-column align-items-end">
                                    {/* <!--begin::User--> */}
                                    <div className="d-flex align-items-center mb-2">
                                        {/* <!--begin::Details--> */}
                                        <div className="me-3">
                                            <span className="text-muted fs-7 mb-1">4 Hours</span>
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">You</a>
                                        </div>
                                        {/* <!--end::Details--> */}
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/metronic/assets/media/avatars/300-1.jpg" />
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                    </div>
                                    {/* <!--end::User--> */}
                                    {/* <!--begin::Text--> */}
                                    <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text">Most purchased Business courses during this sale!</div>
                                    {/* <!--end::Text--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                            </div>
                            {/* <!--end::Message(out)--> */}
                            {/* <!--begin::Message(in)--> */}
                            <div className="d-flex justify-content-start mb-10">
                                {/* <!--begin::Wrapper--> */}
                                <div className="d-flex flex-column align-items-start">
                                    {/* <!--begin::User--> */}
                                    <div className="d-flex align-items-center mb-2">
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/metronic/assets/media/avatars/300-25.jpg" />
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                        {/* <!--begin::Details--> */}
                                        <div className="ms-3">
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Brian Cox</a>
                                            <span className="text-muted fs-7 mb-1">5 Hours</span>
                                        </div>
                                        {/* <!--end::Details--> */}
                                    </div>
                                    {/* <!--end::User--> */}
                                    {/* <!--begin::Text--> */}
                                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided</div>
                                    {/* <!--end::Text--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                            </div>
                            {/* <!--end::Message(in)--> */}
                            {/* <!--begin::Message(template for out)--> */}
                            <div className="d-flex justify-content-end mb-10 d-none" data-kt-element="template-out">
                                {/* <!--begin::Wrapper--> */}
                                <div className="d-flex flex-column align-items-end">
                                    {/* <!--begin::User--> */}
                                    <div className="d-flex align-items-center mb-2">
                                        {/* <!--begin::Details--> */}
                                        <div className="me-3">
                                            <span className="text-muted fs-7 mb-1">Just now</span>
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">You</a>
                                        </div>
                                        {/* <!--end::Details--> */}
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/metronic/assets/media/avatars/300-1.jpg" />
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                    </div>
                                    {/* <!--end::User--> */}
                                    {/* <!--begin::Text--> */}
                                    <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text"></div>
                                    {/* <!--end::Text--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                            </div>
                            {/* <!--end::Message(template for out)--> */}
                            {/* <!--begin::Message(template for in)--> */}
                            <div className="d-flex justify-content-start mb-10 d-none" data-kt-element="template-in">
                                {/* <!--begin::Wrapper--> */}
                                <div className="d-flex flex-column align-items-start">
                                    {/* <!--begin::User--> */}
                                    <div className="d-flex align-items-center mb-2">
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/metronic/assets/media/avatars/300-25.jpg" />
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                        {/* <!--begin::Details--> */}
                                        <div className="ms-3">
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Brian Cox</a>
                                            <span className="text-muted fs-7 mb-1">Just now</span>
                                        </div>
                                        {/* <!--end::Details--> */}
                                    </div>
                                    {/* <!--end::User--> */}
                                    {/* <!--begin::Text--> */}
                                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">Right before vacation season we have the next Big Deal for you.</div>
                                    {/* <!--end::Text--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                            </div>
                            {/* <!--end::Message(template for in)--> */}
                        </div>
                        {/* <!--end::Messages--> */}
                    </div>
                    {/* <!--end::Card body--> */}
                    {/* <!--begin::Card footer--> */}
                    <div className="card-footer pt-4" id="kt_chat_messenger_footer">
                        {/* <!--begin::Input--> */}
                        <textarea className="form-control form-control-flush mb-3" rows="1" data-kt-element="input" placeholder="Type a message"></textarea>
                        {/* <!--end::Input--> */}
                        {/* <!--begin:Toolbar--> */}
                        <div className="d-flex flex-stack">
                            {/* <!--begin::Actions--> */}
                            <div className="d-flex align-items-center me-2">
                                <button className="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" title="Coming soon">
                                    <i className="bi bi-paperclip fs-3"></i>
                                </button>
                                <button className="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" title="Coming soon">
                                    <i className="bi bi-upload fs-3"></i>
                                </button>
                            </div>
                            {/* <!--end::Actions--> */}
                            {/* <!--begin::Send--> */}
                            <button className="btn btn-primary" type="button" data-kt-element="send">Send</button>
                            {/* <!--end::Send--> */}
                        </div>
                        {/* <!--end::Toolbar--> */}
                    </div>
                    {/* <!--end::Card footer--> */}
                </div>
                {/* <!--end::Messenger--> */}
            </div>
            {/* <!--end::Content--> */}
        </div>
        {/* <!--end::Layout--> */}
    </>;
}

Chat.layout = page => <Layout children={page} title="Chat" />

export default Chat
