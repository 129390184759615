import { Inertia } from '@inertiajs/inertia'
import React, { useState } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const Add = () => {

    const { data, setData, post, processing, errors } = useForm({
        nombre: '',
      })

      function submit(e) {
        e.preventDefault()
        post(route('locations.create'))
      }
    return (
        <Row>
            <span className="pb-7 pt-7">
                <Breadcrumbs currentPath="/admin/master_data/locations/add" />
            </span>
            <h2>Agregar Estado</h2>
            <Form  onSubmit={submit}>
                <Form.Group className="mb-3" controlId="formAdd">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.nombre) ? true : false,
                    })}
                    onChange={e => setData('nombre', e.target.value)} aria-invalid={errors.nombre ? "true" : "false"} />
                    {errors.nombre && <div className="mt-2 text-danger">{errors.nombre}</div>}
                </Form.Group>
                <Button variant="primary" className='me-3'  disabled={processing} type="submit">Guardar</Button>
                <Button onClick={()=>window.history.back()} variant="secondary" type="normal">Regresar</Button>
            </Form>
        </Row>
    );
}

Add.layout = page => <Layout children={page} title="Agregar Estado" />

export default Add
