import React, { useState, useRef } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import { Link, usePage } from '@inertiajs/inertia-react';
import { Inertia } from '@inertiajs/inertia';
import PaginationList from '../../Shared/Components/PaginationList';

const List = ({ masters, location }) => {
    const { flash } = usePage().props
    
    const data = masters.data;

    /* #### REJECT #### */

    const [showDialog, setShowDialog] = useState(false);
    const handleCloseDialog = () => setShowDialog(false);
    const handleShowDialog = () => setShowDialog(true);

    const message = useRef();
    const [currentId, setCurrentId] = useState(0);

    function openReject(id){
        setCurrentId(id);
        handleShowDialog()

        setTimeout(() => {
            message.current.value = "";
            message.current.focus();
        }, 50)
    }

    function reject(){
        axios.post(route('master_changes.reject', currentId), { 'message' : message.current.value, })
            .then((response) => {
                toastr.success('Solicitud rechazada correctamente');
                handleCloseDialog();

                Inertia.get(route('master_changes.pending'), {},
                    {
                        preserveState: true,
                        preserveScroll: true,
                        replace: true
                    }
                )
            }).catch((error) => {
                toastr.error("Algo salió mal, intentalo nuevamente");
            });
    }

    /* #### APPROVE #### */

    function approve(id){
        Swal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function(result) {
            if (result.value) {

                axios.post(route('master_changes.approve', id))
                    .then((response) => {
                        toastr.success('Solicitud aprobada correctamente');

                        Inertia.get(route('master_changes.pending'), {},
                            {
                                preserveState: true,
                                preserveScroll: true,
                                replace: true
                            }
                        )
                    }).catch((error) => {
                        toastr.error("Algo salió mal, intentalo nuevamente");
                    });     
                    
            } 
        });
    }

    return (
        <Row>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}

            <h1>Aprobaciones</h1>
            <p>Aprobar solicitudes</p>

            {/* <!--begin:Decline Modal--> */}
            <Modal show={showDialog} onHide={handleCloseDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Rechazar solicitud</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <textarea ref={message} placeholder="Motivo..." className="form-control" name="" id="" cols="30" rows="10"></textarea>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDialog}>Cerrar</Button>
                    <Button variant="danger" onClick={reject}>Rechazar</Button>
                </Modal.Footer>
            </Modal>
            {/* <!--end:Decline Modal--> */}

            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-bordered table-row-gray-100 align-middle mb-0">
                            <thead>
                                <tr className="fw-bold text-muted text-center">
                                    <th>Folio</th>
                                    <th>Fecha</th>
									<th>Asesor / Empleado</th>
                                    <th>Maestro</th>
                                    <th>Edad</th>
                                    <th>Teléfono</th>
                                    <th>RFC</th>
                                    <th>Dirección</th>
                                    <th>Escuela</th>
                                    <th>Dirección Escuela</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 pb-0 text-center text-gray-800 fw-bold fs-6"
                                            colSpan="11"
                                        >
                                            No hay datos
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index} className="fs-9 text-center">
                                            <td className="text-dark fw-bold fs-8">{item.id}</td>
                                            <td className="text-dark fw-bold fs-8">{item.created_at}</td>
                                            <td className="text-dark fw-bold fs-8">{item.user.name}</td>
                                            <td>
                                                <span className="text-muted fw-semibold text-muted d-block">{item.master.name}</span>
                                                <span className="d-block fas fa-arrow-down my-1"></span>
                                                <span className="text-dark fw-bold d-block">{item.name}</span>
                                            </td>
                                            <td>
                                                <span className="text-muted fw-semibold text-muted d-block">{item.master.age}</span>
                                                <span className="d-block fas fa-arrow-down my-1"></span>
                                                <span className="text-dark fw-bold d-block">{item.age}</span>
                                            </td>
                                            <td>
                                                <span className="text-muted fw-semibold text-muted d-block">{item.master.phone_number}</span>
                                                <span className="d-block fas fa-arrow-down my-1"></span>
                                                <span className="text-dark fw-bold d-block">{item.phone_number}</span>
                                            </td>
                                            <td>
                                                <span className="text-muted fw-semibold text-muted d-block">{item.master.rfc}</span>
                                                <span className="d-block fas fa-arrow-down my-1"></span>
                                                <span className="text-dark fw-bold d-block">{item.rfc}</span>
                                            </td>
                                            <td>
                                                <span className="text-muted fw-semibold text-muted d-block">{item.master.address}</span>
                                                <span className="d-block fas fa-arrow-down my-1"></span>
                                                <span className="text-dark fw-bold d-block">{item.address}</span>
                                            </td>
                                            <td>
                                                <span className="text-muted fw-semibold text-muted d-block">{item.master.school}</span>
                                                <span className="d-block fas fa-arrow-down my-1"></span>
                                                <span className="text-dark fw-bold d-block">{item.school}</span>
                                            </td>
                                            <td>
                                                <span className="text-muted fw-semibold text-muted d-block">{item.master.address_school}</span>
                                                <span className="d-block fas fa-arrow-down my-1"></span>
                                                <span className="text-dark fw-bold d-block">{item.address_school}</span>
                                            </td>
                                            <td className="text-end text-nowrap">
                                                <button onClick={()=>openReject(item.id)} className="btn btn-danger btn-sm px-4 me-2">Rechazar</button>
                                                <button onClick={()=>approve(item.id)} className="btn btn-success btn-sm px-4">Aprobar</button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <PaginationList links={masters.links} />
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Aprobaciones" />

export default List
