import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Breadcrumbs = ({ currentPath }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        axios.get(`/api/breadcrumbs?path=${encodeURIComponent(currentPath)}`)
            .then(response => {
                setBreadcrumbs(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch breadcrumbs:', error);
            });
    }, [currentPath]);

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {breadcrumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                    <li className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`}>
                    {index === breadcrumbs.length - 1 ? crumb.title : <a href={crumb.url}>{crumb.title}</a>}
                    </li>
                    {index < breadcrumbs.length - 1 && <span className="breadcrumb-separator">|</span>}
                </React.Fragment>
                ))}
            </ol>
        <style jsx='true'>{`
            .breadcrumb-separator {
                padding: 0 5px;
            }
            .breadcrumb-item + .breadcrumb-item::before {
                content: "|"; /* Add your custom separator */
            }
            .breadcrumb-item::before {
                display: none; /* Remove the default Bootstrap separator */
            }
            .breadcrumb .breadcrumb-item:after {
                content: none;
            }
            .breadcrumb .breadcrumb-item {
                display: flex;
                align-items: center;
                padding-left: 0;
                padding-right: 0;
            }
        `}</style>
        </nav>
    );
};

export default Breadcrumbs;
