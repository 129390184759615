import { Inertia } from '@inertiajs/inertia'
import React, { useState, useEffect } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const Add = ({ office_locations, departments }) => {

    const [positions, setPositions] = useState([[]]);
    const [leaders, setLeaders] = useState([[]]);
    const [offices, setOffices] = useState([[]]);

    const { data, setData, post, processing, errors, reset } = useForm({
        office_id: '',
        office_location_id: '',
        department_id: '',
        position_id: '',
        leader_id: '',
        email: '',
        password: '',
        password_confirmation: '',
        nombre: '',
        lastname_ap: '',
        lastname_am: '',
        years_old: '',
        scholarship: '',
        contact_medium: '',
        phone_number: ''
    })

    const scholarships = [
        "Primaria",
        "Secundaria",
        "Bachillerato general",
        "Estudios superiores (Licenciatura)",
        "Posgrado / Maestría",
        "Doctorado"
    ];

    const mediums = [
        "WhatsApp",
        "Facebook",
        "Telegram"
    ]

    function handleChangeOfficeLocations(e) {
        let office_location_id = e.target.value;
        setOffices([]);
        setData('office_location_id', office_location_id);
        getOffices(office_location_id);
    }

    function getOffices(office_location_id) {
        axios
            .get(route('employees.get.offices', office_location_id), {})
            .then((response) => {
                setOffices(response.data);
            });
    }

    useEffect(() => {
        setData('office_id', '');
    }, [data.office_location_id]);

    useEffect(() => {
        setData('position_id', '');
    }, [data.department_id]);

    useEffect(() => {
        if (data.position_id == '') {
            setData('leader_id', '');
        }
    }, [data.position_id]);

    function handleChangeDepartment(e) {
        let department_id = e.target.value;
        setPositions([]);
        setLeaders([]);
        setData('department_id', department_id);
        getPositions(department_id);
        getLeaders();
    }

    function getPositions(department_id) {
        axios
            .get(route('employees.get.positions', department_id), {})
            .then((response) => {
                setPositions(response.data);
            });
    }

    function getLeaders() {
        axios
            .get(route('employees.get.leaders'), {})
            .then((response) => {
                setLeaders(response.data);
            });
    }

    function submit(e) {
        e.preventDefault();
        const submitter = e.nativeEvent.submitter.name
        if (submitter == "submitAsAdviser") {
            post(route('advisers.add'))
        } else if (submitter == "submitAsEmployee") {
            post(route('employees.add'))
        } else {
            post(route('prospects.create'))
        }
    }

    const [registerProspectModal, setRegisterProspectModal] = useState(false);
    const closeRegisterProspectModal = () => setRegisterProspectModal(false);
    const showRegisterProspectModal = () => setRegisterProspectModal(true);

    function registerProspect(){
        showRegisterProspectModal();
    }

    return (
        <>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/corporate_structure/prospects/add" />
            </div>
            <h2 className="mb-4">Agregar Prospecto</h2>
            <Container>
                <Form className="pb-5" onSubmit={submit} id="prospect-form">
                    <Card className="mb-3">
                        <Container className="pt-2 pb-2">
                            <Card.Title className="mb-4 mt-4">Datos de Usuario</Card.Title>

                            <Row className="mb-3">
                                <Form.Group as={Col} sm={4} controlId="formGridName">
                                    <Form.Label>Nombre(s)</Form.Label>
                                    <Form.Control type="text" onChange={e => setData('nombre', e.target.value)} className={classNames({ 'is-invalid': (errors.nombre) ? true : false, })} aria-invalid={errors.nombre ? "true" : "false"} />
                                    {errors.nombre && <div className="mt-2 text-danger">{errors.nombre}</div>}
                                </Form.Group>
                                <Form.Group as={Col} sm={4} controlId="formGridAp">
                                    <Form.Label>Apellido Paterno</Form.Label>
                                    <Form.Control type="text" onChange={e => setData('lastname_ap', e.target.value)} className={classNames({ 'is-invalid': (errors.lastname_ap) ? true : false, })} aria-invalid={errors.lastname_ap ? "true" : "false"} />
                                    {errors.lastname_ap && <div className="mt-2 text-danger">{errors.lastname_ap}</div>}
                                </Form.Group>

                                <Form.Group as={Col} sm={4} controlId="formGridAm">
                                    <Form.Label>Apellido Materno</Form.Label>
                                    <Form.Control type="text" onChange={e => setData('lastname_am', e.target.value)} className={classNames({ 'is-invalid': (errors.lastname_am) ? true : false, })} aria-invalid={errors.lastname_am ? "true" : "false"} />
                                    {errors.lastname_am && <div className="mt-2 text-danger">{errors.lastname_am}</div>}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Correo electrónico</Form.Label>
                                    <Form.Control type="email" onChange={e => setData('email', e.target.value)} className={classNames({ 'is-invalid': (errors.email) ? true : false, })} aria-invalid={errors.email ? "true" : "false"} />
                                    {errors.email && <div className="mt-2 text-danger">{errors.email}</div>}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control type="password" autoComplete="off" onChange={e => setData('password', e.target.value)} className={classNames({ 'is-invalid': (errors.password) ? true : false, })} aria-invalid={errors.password ? "true" : "false"} />
                                    {errors.password && <div className="mt-2 text-danger">{errors.password}</div>}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Confirmar Contraseña</Form.Label>
                                    <Form.Control type="password" autoComplete="off" onChange={e => setData('password_confirmation', e.target.value)} className={classNames({ 'is-invalid': (errors.password_confirmation) ? true : false, })} aria-invalid={errors.password_confirmation ? "true" : "false"} />
                                    {errors.password_confirmation && <div className="mt-2 text-danger">{errors.password_confirmation}</div>}
                                </Form.Group>
                            </Row>
                        </Container>
                    </Card>

                    <Card className="mb-3">
                        <Container className="pt-2 pb-2">
                            <Card.Title className="mb-4">Puesto del Empleado</Card.Title>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridOfficeLocation">
                                    <Form.Label>Estado</Form.Label>
                                    <Form.Select value={data.office_location_id} onChange={handleChangeOfficeLocations} className={classNames({ 'is-invalid': (errors.department_id) ? true : false, })} aria-invalid={errors.department_id ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {office_locations.map((office_location, index) => {
                                            return <option key={index} value={office_location.id}>{office_location.nombre}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.office_location_id && <div className="mt-2 text-danger">{errors.office_location_id}</div>}
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridOffice">
                                    <Form.Label>Oficina</Form.Label>
                                    <Form.Select value={data.office_id} onChange={e => setData('office_id', e.target.value)} className={classNames({ 'is-invalid': (errors.office_id) ? true : false, })} aria-invalid={errors.office_id ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {offices.map((office, index) => {
                                            return <option key={index} value={office.id}>{office.nombre}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.office_id && <div className="mt-2 text-danger">{errors.office_id}</div>}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridDepartment">
                                    <Form.Label>Departamento</Form.Label>
                                    <Form.Select value={data.department_id} onChange={handleChangeDepartment} className={classNames({ 'is-invalid': (errors.department_id) ? true : false, })} aria-invalid={errors.department_id ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {departments.map((department, index) => {
                                            return <option key={index} value={department.id}>{department.nombre}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.department_id && <div className="mt-2 text-danger">{errors.department_id}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPosition">
                                    <Form.Label>Puesto</Form.Label>
                                    <Form.Select value={data.position_id} onChange={e => setData('position_id', e.target.value)} className={classNames({ 'is-invalid': (errors.position_id) ? true : false, })} aria-invalid={errors.position_id ? "true" : "false"} >
                                        <option key='blankChoice' value={''}>Selecciona una opción</option>
                                        {positions.length > 0 && (
                                            positions.map((position, index) => {
                                                return <option key={index} value={position.id}>{position.nombre}</option>;
                                            }))}
                                    </Form.Select>
                                    {errors.position_id && <div className="mt-2 text-danger">{errors.position_id}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridLeader">
                                    <Form.Label>Lider</Form.Label>
                                    <Form.Select value={data.leader_id} onChange={e => setData('leader_id', e.target.value)} className={classNames({ 'is-invalid': (errors.leader_id) ? true : false, })} aria-invalid={errors.leader_id ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {leaders.length > 0 && (
                                            leaders.map((leader, index) => {
                                                return <option key={index} value={leader.id}>{leader.name}</option>;
                                            }))}
                                    </Form.Select>
                                    {errors.leader_id && <div className="mt-2 text-danger">{errors.leader_id}</div>}
                                </Form.Group>
                            </Row>
                        </Container>
                    </Card>

                    <Card className="mb-5">
                        <Container className="pt-2 pb-2">
                            <Card.Title className="mb-4">Información Personal</Card.Title>
                            <Row className="mb-3">
                                <Form.Group as={Col} sm={3} controlId="formGridYears">
                                    <Form.Label>Edad</Form.Label>
                                    <Form.Control type="number" min="1" max="150" onChange={e => setData('years_old', e.target.value)} className={classNames({ 'is-invalid': (errors.years_old) ? true : false, })} aria-invalid={errors.years_old ? "true" : "false"} />
                                    {errors.years_old && <div className="mt-2 text-danger">{errors.years_old}</div>}
                                </Form.Group>
                                <Form.Group as={Col} sm={3} controlId="formGridScholarship">
                                    <Form.Label>Escolaridad</Form.Label>
                                    <Form.Select name="scholarship" onChange={e => setData('scholarship', e.target.value)} className={classNames({ 'is-invalid': (errors.scholarship) ? true : false, })} aria-invalid={errors.scholarship ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {scholarships.map((scholarship, index) => {
                                            return <option key={index} value={scholarship}>{scholarship}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.scholarship && <div className="mt-2 text-danger">{errors.scholarship}</div>}
                                </Form.Group>

                                <Form.Group as={Col} sm={3} controlId="formGridContact">
                                    <Form.Label>Medio de contacto</Form.Label>
                                    <Form.Select name="contact_medium" onChange={e => setData('contact_medium', e.target.value)} className={classNames({ 'is-invalid': (errors.contact_medium) ? true : false, })} aria-invalid={errors.contact_medium ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {mediums.map((medium, index) => {
                                            return <option key={index} value={medium}>{medium}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.contact_medium && <div className="mt-2 text-danger">{errors.contact_medium}</div>}
                                </Form.Group>

                                <Form.Group as={Col} sm={3} controlId="formGridPhone">
                                    <Form.Label>Teléfono de contacto</Form.Label>
                                    <Form.Control type="tel" onChange={e => setData('phone_number', e.target.value)} className={classNames({ 'is-invalid': (errors.phone_number) ? true : false, })} aria-invalid={errors.phone_number ? "true" : "false"} />
                                    {errors.phone_number && <div className="mt-2 text-danger">{errors.phone_number}</div>}
                                </Form.Group>
                            </Row>
                        </Container>
                    </Card>

                    <div className="pt-3 pb-3 text-center">
                        <Button onClick={() => window.history.back()} variant="secondary" type="normal" className='me-3'>Regresar</Button>
                        <Button onClick={registerProspect} variant="primary">Continuar</Button>
                    </div>

                    {/* <!--begin:Register Modal--> */}
                    <Modal show={registerProspectModal} onHide={closeRegisterProspectModal} scrollable={true} size="lg" id="register-prospect">
                        <Modal.Header closeButton>
                            <Modal.Title>Guardar Contacto</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="pl-3 mb-3">
                                <h3 className='font-weight-normal pb-5 text-center'>¿Como deseas registrar este Contacto?</h3>
                                <div className="d-flex justify-content-between py-2 row">
                                    <div className='col-8'>
                                        <h5 className='font-weight-bold'>Prospecto</h5>
                                        <p>Continuar como Prospecto y solo actualizar su información.</p>
                                    </div>
                                    <div className="col-3 text-center pt-2">
                                        <Button variant="primary" size="sm" disabled={processing} type="submit" name="submit" form="prospect-form">Guardar Prospecto</Button>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between py-2 row">
                                    <div className='col-8'>
                                        <h5 className='font-weight-bold'>Asesor</h5>
                                        <p>Convertir a este contacto en Asesor y completar la información en el siguiente formulario.</p>
                                    </div>
                                    <div className="col-3 text-center pt-2">
                                        <Button variant="info" size="sm" type="submit" name="submitAsAdviser" form="prospect-form">Convertir a Asesor</Button>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between py-2 row">
                                    <div className='col-8'>
                                        <h5 className='font-weight-bold'>Empleado</h5>
                                        <p className='col-md'>Convertir a este contacto en Empleado y completar la información en el siguiente formulario.</p>
                                    </div>
                                    <div className='col-3 text-center pt-2'>
                                        <Button variant="info" size="sm" type="submit" name="submitAsEmployee" form="prospect-form">Convertir a Empleado</Button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* <!--end:Register Modal--> */}
                </Form>
            </Container>
        </>
    );
}

Add.layout = page => <Layout children={page} title="Agregar Prospecto" />

export default Add
