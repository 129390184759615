import { Inertia } from '@inertiajs/inertia'
import React, { useState } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';

const Add = ({ location }) => {

    const { data, setData, post, processing, errors } = useForm({
        name: '',
        description: '',
      })

      function submit(e) {
        e.preventDefault()
        post(route('companies.create', location.id))
      }
    return (
        <Row>
            <h2>Agregar Empresa en {location.nombre}</h2>
            <Form  onSubmit={submit}>
                <Form.Group className="mb-3" controlId="formAdd">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.name) ? true : false,
                    })}
                    onChange={e => setData('name', e.target.value)} aria-invalid={errors.name ? "true" : "false"} />
                    {errors.name && <div className="mt-2 text-danger">{errors.name}</div>}
                </Form.Group>
                <Form.Group className="mb-5 mt-2" controlId="">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.description) ? true : false,
                    })}
                    onChange={e => setData('description', e.target.value)} aria-invalid={errors.description ? "true" : "false"} />
                    {errors.description && <div className="mt-2 text-danger">{errors.description}</div>}
                </Form.Group>
                <Button variant="primary" className='me-3'  disabled={processing} type="submit">Guardar</Button>
                <Button onClick={()=>window.history.back()} variant="secondary" type="normal">Regresar</Button>
            </Form>
        </Row>
    );
}

Add.layout = page => <Layout children={page} title="Agregar Empresa" />

export default Add
