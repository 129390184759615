import React, { useState, forwardRef, useImperativeHandle } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { usePage } from '@inertiajs/inertia-react';
import "react-datepicker/dist/react-datepicker.css";

const InvoiceErrorDetails = forwardRef((props, ref) => {
    const { flash, auth } = usePage().props;
    const [errors, setErrors] = useState({});

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => setDialog(false);
    const showDialog = () => setDialog(true);

    useImperativeHandle(ref, () => {
        return {
            openDialog(order_id) {
                setOrderId(order_id);
                getInvoiceDetails(order_id, 1);
            },
        }
    });

    const [orderId, setOrderId] = useState(null);
    const [items, setItems] = useState([]);

    function getInvoiceDetails(orderId) {
        showDialog();

        axios.get(route('commissions.invoice.errors', orderId))
            .then((response) => {
                setItems(response.data['items']);
            }).catch((error) => {
                toastr.error("Algo salió mal, intentalo nuevamente");
            });
    }

    return <>
        {/* <!--begin:Appointments Modal--> */}
        <Modal show={dialog} onHide={closeDialog} scrollable={true} size="xl" id="dialog">
            <Modal.Header closeButton className="py-4">
                <Modal.Title>Errores</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {items.data == undefined || items.data.length == 0 ? (
                    <div className="fw-semibold text-muted">No hay errores</div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-row-bordered table-row-solid">
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400">
                                    <th className='text-start'>Código</th>
                                    <th className='text-start'>Mensaje</th>
                                </tr>
                            </thead>

                            <tbody>
                                {items.data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-gray-800 fw-bold text-start">{item['code']}</td>
                                            <td className="text-gray-800 fw-bold text-start">{item['message']}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeDialog} className="btn-sm" variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal>
        {/* <!--end:Appointments Modal--> */}
    </>;
})

export default InvoiceErrorDetails
