import React, { useState } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import { Link, usePage } from '@inertiajs/inertia-react'

const List = ({ items, location }) => {
    const { flash } = usePage().props

    const [showDialog, setShowDialog] = useState(false);

    const handleCloseDialog = () => setShowDialog(false);
    const handleShowDialog = () => setShowDialog(true);

    return (
        <Row>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}

            <h1>Aprobaciones</h1>
            <p>Aprobar solicitudes</p>

            {/* <!--begin:Decline Modal--> */}
            <Modal show={showDialog} onHide={handleCloseDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Rechazar solicitud</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <textarea placeholder="Motivo..." className="form-control" name="" id="" cols="30" rows="10"></textarea>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDialog}>Cerrar</Button>
                    <Button variant="danger" onClick={handleShowDialog}>Rechazar</Button>
                </Modal.Footer>
            </Modal>
            {/* <!--end:Decline Modal--> */}

            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                            <thead>
                                <tr className="fw-bold text-muted text-center">
                                    <th className="p-0 pb-3">Folio</th>
                                    <th className="p-0 pb-3">Fecha</th>
									<th className="p-0 pb-3">Asesor / Empleado</th>
                                    <th className="p-0 pb-3">Maestro</th>
                                    <th className="p-0 pb-3">Edad</th>
                                    <th className="p-0 pb-3">Teléfono</th>
                                    <th className="p-0 pb-3">RFC</th>
                                    <th className="p-0 pb-3">Dirección</th>
                                    <th className="p-0 pb-3">Escuela</th>
                                    <th className="p-0 pb-3">Dirección Escuela</th>
                                    <th className="p-0 pb-3 text-end"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="fs-9 text-center">
                                    <td className="text-dark fw-bold fs-8">81829381</td>
                                    <td className="text-dark fw-bold fs-8">09/12/2022</td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">Adan Luna</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">Alvin Rmz</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">Hugo Treviño</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">Marco Salas</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">29</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">31</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">8111023912</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">8111023913</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">SUL010720JN8</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">URU070122S28</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">RAYON 7 CENTRO 43720 ACAXOCHITLAN HIDALGO</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">CIRCUITO PUERTO MARQUEZ 154 LOS ALAMOS 43640 TULANCINGO DE BRAVO HIDALGO</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">Instituto Americano</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">Tecnologico de Monterrey</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">VALENTIN GOMEZ FARIAS 109 TEPEYAC 43680 TULANCINGO DE BRAVO HIDALGO</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">VICENTE SUAREZ S/N FRANCISCO VILLA 43760 SANTIAGO TULANTEPEC DE LUGO GUERRERO HIDALGO</span>
                                    </td>
                                    <td className="text-end text-nowrap">
                                        <button onClick={handleShowDialog} className="btn btn-danger btn-sm px-4 me-2">Rechazar</button>
                                        <button className="btn btn-success btn-sm px-4">Aprobar</button>
                                    </td>
                                </tr>
                                <tr className="fs-9 text-center">
                                    <td className="text-dark fw-bold fs-8">81829381</td>
                                    <td className="text-dark fw-bold fs-8">09/12/2022</td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">Adan Luna</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">Alvin Rmz</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">Hugo Treviño</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">Marco Salas</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">29</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">31</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">8111023912</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">8111023913</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">SUL010720JN8</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">URU070122S28</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">RAYON 7 CENTRO 43720 ACAXOCHITLAN HIDALGO</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">CIRCUITO PUERTO MARQUEZ 154 LOS ALAMOS 43640 TULANCINGO DE BRAVO HIDALGO</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">Instituto Americano</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">Tecnologico de Monterrey</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">VALENTIN GOMEZ FARIAS 109 TEPEYAC 43680 TULANCINGO DE BRAVO HIDALGO</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">VICENTE SUAREZ S/N FRANCISCO VILLA 43760 SANTIAGO TULANTEPEC DE LUGO GUERRERO HIDALGO</span>
                                    </td>
                                    <td className="text-end text-nowrap">
                                        <button onClick={handleShowDialog} className="btn btn-danger btn-sm px-4 me-2">Rechazar</button>
                                        <button className="btn btn-success btn-sm px-4">Aprobar</button>
                                    </td>
                                </tr>
                                <tr className="fs-9 text-center">
                                    <td className="text-dark fw-bold fs-8">81829381</td>
                                    <td className="text-dark fw-bold fs-8">09/12/2022</td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">Adan Luna</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">Alvin Rmz</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">Hugo Treviño</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">Marco Salas</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">29</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">31</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">8111023912</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">8111023913</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">SUL010720JN8</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">URU070122S28</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">RAYON 7 CENTRO 43720 ACAXOCHITLAN HIDALGO</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">CIRCUITO PUERTO MARQUEZ 154 LOS ALAMOS 43640 TULANCINGO DE BRAVO HIDALGO</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">Instituto Americano</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">Tecnologico de Monterrey</span>
                                    </td>
                                    <td>
                                        <span className="text-muted fw-semibold text-muted d-block">VALENTIN GOMEZ FARIAS 109 TEPEYAC 43680 TULANCINGO DE BRAVO HIDALGO</span>
                                        <span className="d-block fas fa-arrow-down my-1"></span>
                                        <span className="text-dark fw-bold d-block">VICENTE SUAREZ S/N FRANCISCO VILLA 43760 SANTIAGO TULANTEPEC DE LUGO GUERRERO HIDALGO</span>
                                    </td>
                                    <td className="text-end text-nowrap">
                                        <button onClick={handleShowDialog} className="btn btn-danger btn-sm px-4 me-2">Rechazar</button>
                                        <button className="btn btn-success btn-sm px-4">Aprobar</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Aprobaciones" />

export default List
