import React from 'react'
import Layout from '../../../Shared/Layout/LayoutAdmin';
import classNames from "classnames";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from '@inertiajs/inertia-react';
import Breadcrumbs from '../../../Shared/Components/Breadcrumbs';

const Edit = ({ item }) => {
    const accept_types = '.doc,.docx,.pdf';

    const { data, setData, post, processing, errors, progress } = useForm({
        rfc: item.rfc || null,
        curp: item.curp || null,
        rfc_file: null,
        curp_file: null,
        ine_file1: null,
        ine_file2: null,
        certificate_file: null,
        address_file: null,
        commercial_contract_file: null,
        imss_file: null,
        tax_id_file: null,
        ciec_file1: null,
        ciec_file2: null,
        ciec_file3: null,
        fiel_file1: null,
        fiel_file2: null,
        fiel_file3: null,
    })

    function submit(e) {
        e.preventDefault()
        post(route('advisers.documents.update', item.id))
    }

    function LinkDocument(props) {
        if (!props.file) {
            return <span className="svg-icon ms-1 me-1">
                <svg viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke=""><g strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24"></rect> <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z" fill="#e23c3c"></path> </g></svg>
            </span>
        }

        return (
            <>
                <span className="svg-icon ms-1  me-1">
                <svg viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke=""><g strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24"></rect> <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L12.0243 14.3899C11.4586 14.9556 10.5414 14.9556 9.97568 14.3899L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z" fill="#3eac5a"></path> </g></svg>
                </span>
                <a className='ms-1' href={'/storage/' + props.file} target="_blank">Ver documento</a>
            </>
        );
    }
    return (
        <>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/corporate_structure/advisers/documents" />
            </div>
            <Row>
                <Col>
                    <h2>Documentos Asesores</h2>
                </Col>
                <Form onSubmit={submit}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formAdd">
                                <Form.Label>RFC</Form.Label>
                                <Form.Control type="text" defaultValue={item.rfc} className={classNames({
                                    'is-invalid': (errors.rfc) ? true : false,
                                })}
                                    onChange={e => setData('rfc', e.target.value)} aria-invalid={errors.rfc ? "true" : "false"} />
                                {errors.rfc && <div className="mt-2 text-danger">{errors.rfc}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>RFC Documento</Form.Label>
                                <LinkDocument file={item.rfc_file} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.rfc_file) ? true : false, })} defaultValue="" onChange={e => setData('rfc_file', e.target.files[0])} aria-invalid={errors.rfc_file ? "true" : "false"} accept={accept_types} />
                                {errors.rfc_file && <div className="mt-2 text-danger">{errors.rfc_file}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formAdd">
                                <Form.Label>CURP</Form.Label>
                                <Form.Control type="text" defaultValue={item.curp} className={classNames({
                                    'is-invalid': (errors.curp) ? true : false,
                                })}
                                    onChange={e => setData('curp', e.target.value)} aria-invalid={errors.curp ? "true" : "false"} accept={accept_types} />
                                {errors.curp && <div className="mt-2 text-danger">{errors.curp}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>CURP Documento</Form.Label>
                                <LinkDocument file={item.curp_file} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.curp_file) ? true : false, })} defaultValue="" onChange={e => setData('curp_file', e.target.files[0])} aria-invalid={errors.curp_file ? "true" : "false"} accept={accept_types} />
                                {errors.curp_file && <div className="mt-2 text-danger">{errors.curp_file}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>INE (Frente)</Form.Label>
                                <LinkDocument file={item.ine_file1} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.ine_file1) ? true : false, })} defaultValue="" onChange={e => setData('ine_file1', e.target.files[0])} aria-invalid={errors.ine_file1 ? "true" : "false"} accept={accept_types} />
                                {errors.ine_file1 && <div className="mt-2 text-danger">{errors.ine_file1}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>INE (Revés)</Form.Label>
                                <LinkDocument file={item.ine_file2} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.ine_file2) ? true : false, })} defaultValue="" onChange={e => setData('ine_file2', e.target.files[0])} aria-invalid={errors.ine_file2 ? "true" : "false"} accept={accept_types} />
                                {errors.ine_file2 && <div className="mt-2 text-danger">{errors.ine_file2}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Acta de Nacimiento</Form.Label>
                                <LinkDocument file={item.certificate_file} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.certificate_file) ? true : false, })} defaultValue="" onChange={e => setData('certificate_file', e.target.files[0])} aria-invalid={errors.certificate_file ? "true" : "false"} accept={accept_types} />
                                {errors.certificate_file && <div className="mt-2 text-danger">{errors.certificate_file}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Comprobande de Domicilio</Form.Label>
                                <LinkDocument file={item.address_file} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.address_file) ? true : false, })} defaultValue="" onChange={e => setData('address_file', e.target.files[0])} aria-invalid={errors.address_file ? "true" : "false"} accept={accept_types} />
                                {errors.address_file && <div className="mt-2 text-danger">{errors.address_file}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Contratro Mercantil</Form.Label>
                                <LinkDocument file={item.commercial_contract_file} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.commercial_contract_file) ? true : false, })} defaultValue="" onChange={e => setData('commercial_contract_file', e.target.files[0])} aria-invalid={errors.commercial_contract_file ? "true" : "false"} accept={accept_types} />
                                {errors.commercial_contract_file && <div className="mt-2 text-danger">{errors.commercial_contract_file}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>IMSS</Form.Label>
                                <LinkDocument file={item.imss_file} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.imss_file) ? true : false, })} defaultValue="" onChange={e => setData('imss_file', e.target.files[0])} aria-invalid={errors.imss_file ? "true" : "false"} accept={accept_types} />
                                {errors.imss_file && <div className="mt-2 text-danger">{errors.imss_file}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Cedula Fiscal</Form.Label>
                                <LinkDocument file={item.tax_id_file} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.tax_id_file) ? true : false, })} defaultValue="" onChange={e => setData('tax_id_file', e.target.files[0])} aria-invalid={errors.tax_id_file ? "true" : "false"} accept={accept_types} />
                                {errors.tax_id_file && <div className="mt-2 text-danger">{errors.tax_id_file}</div>}
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>CIEC 1</Form.Label>
                                <LinkDocument file={item.ciec_file1} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.ciec_file1) ? true : false, })} defaultValue="" onChange={e => setData('ciec_file1', e.target.files[0])} aria-invalid={errors.ciec_file1 ? "true" : "false"} accept={accept_types} />
                                {errors.ciec_file1 && <div className="mt-2 text-danger">{errors.ciec_file1}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>CIEC 2</Form.Label>
                                <LinkDocument file={item.ciec_file2} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.ciec_file2) ? true : false, })} defaultValue="" onChange={e => setData('ciec_file2', e.target.files[0])} aria-invalid={errors.ciec_file2 ? "true" : "false"} accept={accept_types} />
                                {errors.ciec_file2 && <div className="mt-2 text-danger">{errors.ciec_file2}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>CIEC 3</Form.Label>
                                <LinkDocument file={item.ciec_file3} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.ciec_file3) ? true : false, })} defaultValue="" onChange={e => setData('ciec_file3', e.target.files[0])} aria-invalid={errors.ciec_file3 ? "true" : "false"} accept={accept_types} />
                                {errors.ciec_file3 && <div className="mt-2 text-danger">{errors.ciec_file3}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>FIEL 1</Form.Label>
                                <LinkDocument file={item.fiel_file1} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.fiel_file1) ? true : false, })} defaultValue="" onChange={e => setData('fiel_file1', e.target.files[0])} aria-invalid={errors.fiel_file1 ? "true" : "false"} accept={accept_types} />
                                {errors.fiel_file1 && <div className="mt-2 text-danger">{errors.fiel_file1}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>FIEL 2</Form.Label>
                                <LinkDocument file={item.fiel_file2} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.fiel_file2) ? true : false, })} defaultValue="" onChange={e => setData('fiel_file2', e.target.files[0])} aria-invalid={errors.fiel_file2 ? "true" : "false"} accept={accept_types} />

                                {errors.fiel_file2 && <div className="mt-2 text-danger">{errors.fiel_file2}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>FIEL 3</Form.Label>
                                <LinkDocument file={item.fiel_file3} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.fiel_file3) ? true : false, })} defaultValue="" onChange={e => setData('fiel_file3', e.target.files[0])} aria-invalid={errors.fiel_file3 ? "true" : "false"} accept={accept_types} />
                                {errors.fiel_file3 && <div className="mt-2 text-danger">{errors.fiel_file3}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Cédula</Form.Label>
                                <LinkDocument file={item.identification_file} />
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.identification_file) ? true : false, })} defaultValue="" onChange={e => setData('identification_file', e.target.files[0])} aria-invalid={errors.identification_file ? "true" : "false"} accept={accept_types} />
                                {errors.identification_file && <div className="mt-2 text-danger">{errors.identification_file}</div>}
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col className="mt-3">
                            {progress && (
                                <progress value={progress.percentage} max="100">
                                    {progress.percentage}%
                                </progress>
                            )}
                            <Button variant="primary" className='me-3' disabled={processing} type="submit">Guardar</Button>
                            <Button onClick={() => window.history.back()} variant="secondary" type="normal">Regresar</Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>
    );
}

Edit.layout = page => <Layout children={page} title="Documentos Asesores" />

export default Edit
