import React, { useState, useRef, useEffect } from 'react';

function ActionsMenu({children}) {
    const [open, setOpen] = useState(false);

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e)=>{
            if(
                !menuRef.current.contains(e.target) ||
                e.target.classList.contains('close-actions-menu')
            ){
                setTimeout(() => {
                    setOpen(false);
                }, 100)
            }
        };

        document.addEventListener("click", handler);

        return() => {
            document.removeEventListener("click", handler);
        }
    });

    return (
        <div className="position-relative" ref={menuRef}>
            <a className="btn btn-sm btn-info btn-flex fw-bold" onClick={()=>{setOpen(!open)}}>
                <p className='m-1 p-1'>
                    Acciones
                </p>
                <span className="svg-icon svg-icon-6 svg-icon-muted me-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg>
                </span>
            </a>
            <div className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px position-absolute end-0 ${open ? 'show' : ''}`} data-popper-placement="bottom-end">
                { children }
            </div>
        </div>
    )
}

export default ActionsMenu;
