import React, { useState } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import { Link, usePage } from '@inertiajs/inertia-react';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const List = ({ items, role_users }) => {
    const { flash } = usePage().props
    const data = items.data;

    return (
        <Row>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            {flash.error && (
                <AlertDismissible message={flash.error} type="danger" />
            )}
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/corporate_structure/roles" />
            </div>
            <div className="card card-flush h-md-100">
                <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">Listado de Roles</span>
                        <span className="text-gray-400 mt-1 fw-semibold fs-6">{ data.length } Roles</span>
                    </h3>

                    <div className="card-toolbar">
                        <Link href={ route('roles.add') } className='me-3 btn btn-sm btn-primary'>Agregar Rol</Link>
                    </div>
                </div>

                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-0 pb-3 min-w-175px text-start">NOMBRE</th>
                                    <th className="p-0 pb-3 w-50px text-end"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="3"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.name}</p>
                                                            {role_users[item.id] === 1 && (
                                                                <span className="text-gray-400 fw-semibold d-block fs-7">
                                                                    {role_users[item.id]} usuario con este rol
                                                                </span>
                                                            )}
                                                            {role_users[item.id] > 1 && (
                                                                <span className="text-gray-400 fw-semibold d-block fs-7">
                                                                    {role_users[item.id]} usuarios con este rol
                                                                </span>
                                                            )}
                                                            {role_users[item.id] === 0 && (
                                                                <span className="text-gray-400 fw-semibold d-block fs-7">
                                                                    {role_users[item.id]} usuarios con este rol
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-around">
                                                    <div className='mx-2'>
                                                        <Link href={route("roles.permissions", item.id)}>Permisos</Link>
                                                    </div>
                                                    <div className='mx-2'>
                                                        <Link href={route("roles.edit", item.id)}>Editar</Link>
                                                    </div>
                                                    <div className='mx-2'>
                                                        <Link className='text-danger' href={route("roles.delete", item.id)}>Eliminar</Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Roles" />

export default List
