import React from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

const Edit = ({ item, satTaxFactorTypes, satTaxTypes, taxes }) => {

    const { data, setData, post, processing, errors } = useForm({
        name: item.name || "",
        percentage: item.percentage || 0,
        tax: item.tax || 0,
        active: item.active || 1,
        taxes: taxes.map(tax => ({
            id: tax.id,
            sat_tax_type_id: tax.sat_tax_type_id,
            percentage: tax.percentage,
            sat_tax_factor_type_id: tax.sat_tax_factor_type_id,
            is_withholding: tax.is_withholding || false
        })) || [],
    })

    function submit(e) {
        e.preventDefault()
        post(route('taxregimes.update', item.id))
    }

    function addTax() {
        if(data.taxes.length >= 3){
            Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "Solo puedes agregar hasta 3 impuestos",
            });
            return;
        }

        const taxes = [...data.taxes];

        taxes.push({
            sat_tax_type_id: '',
            percentage: '',
            sat_tax_factor_type_id: '',
            is_withholding: false
        })

        setData(data => ({ ...data, 'taxes': taxes }));
    }

    function removeTax(index) {
        const taxes = [...data.taxes];

        taxes.splice(index, 1);

        setData(data => ({ ...data, 'taxes': taxes }));
    }

    function setTaxData(property, index, value) {
        const taxes = [...data.taxes];

        taxes[index][property] = value;

        setData(data => ({ ...data, 'taxes': taxes }));
    }

    return (
        <Row>
            <span className="pb-7 pt-7">
                <Breadcrumbs currentPath="/admin/master_data/taxregimes/add" />
            </span>
            <h2>Editar Régimen Fiscal</h2>
            <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="formAdd">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.name) ? true : false,
                    })}
                        value={data.name} onChange={(e) =>
                            setData("name", e.target.value)
                        } aria-invalid={errors.name ? "true" : "false"} disabled />
                    {errors.name && <div className="mt-2 text-danger">{errors.name}</div>}
                </Form.Group>
                <Row className="mb-6">
                    <Form.Group as={Col} controlId="formGridVeritas">
                        <Form.Label>¿Activo?</Form.Label>
                        <InputGroup>
                            <Form.Check
                                inline
                                name="active"
                                type="radio"
                                label="Si"
                                value="1"
                                id="1"
                                defaultChecked={(data.active == 1 ? true : false)}
                                onChange={e => setData('active', 1)} className={classNames({ 'is-invalid': (errors.active) ? true : false, })} aria-invalid={errors.active ? "true" : "false"} />
                            <Form.Check
                                inline
                                name="active"
                                type="radio"
                                label="No"
                                value="0"
                                id="0"
                                defaultChecked={(data.active == 0 ? true : false)}
                                onChange={e => setData('active', 0)} className={classNames({ 'is-invalid': (errors.active) ? true : false, })} aria-invalid={errors.active ? "true" : "false"} />
                        </InputGroup>
                        {errors.active && <div className="mt-2 text-danger">{errors.active}</div>}
                    </Form.Group>
                </Row>

                <br />

                <Card>
                    <Container className="p-5">
                        <div className="mb-3 text-end">
                            <Button size="sm" onClick={() => addTax()} variant="primary" type="button">Agregar</Button>
                        </div>

                        {errors.taxes && <div className="mt-2 text-danger">{errors.taxes}</div>}

                        <div className="table-responsive">
                            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                <thead>
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="min-w-175px text-start">Tipo Impuesto</th>
                                        <th className="min-w-175px text-start">Porcentaje</th>
                                        <th className="min-w-175px text-start">Tipo Factor</th>
                                        <th className="min-w-175px text-start">¿Retención?</th>
                                        <th className="w-50px text-end"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.taxes.length === 0 && (
                                        <tr>
                                            <td
                                                className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                                colSpan="7"
                                            >
                                                No hay datos.
                                            </td>
                                        </tr>
                                    )}
                                    {data.taxes.map((tax, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                    <Form.Select value={tax.sat_tax_type_id} onChange={e => setTaxData('sat_tax_type_id', index, e.target.value)}>
                                                        <option value="">Seleccionar tipo de impuesto</option>
                                                        {satTaxTypes.map(type => (
                                                            <option key={type.id} value={type.id}>{type.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors[`taxes.${index}.sat_tax_type_id`] && <div className="mt-2 text-danger">{errors[`taxes.${index}.sat_tax_type_id`]}</div>}
                                                </td>
                                                <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                    <Form.Control
                                                        type="text"
                                                        value={tax.percentage}
                                                        placeholder="..."
                                                        onChange={e => setTaxData('percentage', index, e.target.value)}
                                                        className={classNames({ 'is-invalid': errors[`taxes.${index}.percentage`] ? true : false, })}
                                                        aria-invalid={errors[`taxes.${index}.percentage`] ? "true" : "false"}
                                                    />
                                                    {errors[`taxes.${index}.percentage`] && <div className="mt-2 text-danger">{errors[`taxes.${index}.percentage`]}</div>}
                                                </td>
                                                <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                    <Form.Select value={tax.sat_tax_factor_type_id} onChange={e => setTaxData('sat_tax_factor_type_id', index, e.target.value)}>
                                                        <option value="">Seleccionar tipo de factor</option>
                                                        {satTaxFactorTypes.map(factor => (
                                                            <option key={factor.id} value={factor.id}>{factor.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors[`taxes.${index}.sat_tax_factor_type_id`] && <div className="mt-2 text-danger">{errors[`taxes.${index}.sat_tax_factor_type_id`]}</div>}
                                                </td>
                                                <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={tax.is_withholding}
                                                        onChange={e => setTaxData('is_withholding', index, e.target.checked)}
                                                        className={classNames({ 'is-invalid': errors[`taxes.${index}.is_withholding`] ? true : false, })}
                                                        aria-invalid={errors[`taxes.${index}.is_withholding`] ? "true" : "false"}
                                                    />
                                                    {errors[`taxes.${index}.is_withholding`] && <div className="mt-2 text-danger">{errors[`taxes.${index}.is_withholding`]}</div>}
                                                </td>
                                                <td>
                                                    <Button size="sm" onClick={() => removeTax(index)} variant="link" className='text-danger' type="button">Eliminar</Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Container>
                </Card>

                <br /><br />

                <Button variant="primary" className='me-3' disabled={processing} type="submit">Guardar</Button>
                <Button onClick={()=>window.history.back()} variant="secondary" type="normal">Regresar</Button>
            </Form>
        </Row>
    );
}

Edit.layout = page => <Layout children={page} title="Editar Régimen Fiscal" />

export default Edit
