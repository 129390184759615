import AlertDismissible from '../../Shared/Components/AlertDismissible';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InvoiceDetails from './InvoiceDetails';
import InvoiceErrorDetails from './InvoiceErrorDetails';
import Layout from '../../Shared/Layout/LayoutAdmin';
import Nav from 'react-bootstrap/Nav';
import React, { useState, useEffect, useRef } from 'react'
import Row from 'react-bootstrap/Row';
import { Inertia } from '@inertiajs/inertia'
import { Link, usePage } from '@inertiajs/inertia-react';
import { es } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';
import { groupBy } from 'lodash';
import { hasPermission } from '../../utils/Permissions';

const List = ({ items, advisers }) => {
    const { flash } = usePage().props;
    const query = new URL(window.location).searchParams;
    const [adviserId, setAdviserId] = useState(query.get("adviser") || '');
    const [invoice, setInvoice] = useState(query.get("invoice") || '');

    const InvoiceDetailsRef = useRef();
    const InvoiceErrorDetailsRef = useRef();

    const groupedItems = groupBy(items, item => item.adviser_id);
    const [viewMore, setViewMore] = useState(false);

    const [checkedList, setCheckedList] = useState([]);
    const handleCheck = (event, selectedItem) => {
        setCheckedList(checkedList.filter((item) => item.id !== selectedItem.id));

        if(event.target.checked){
            setCheckedList([...checkedList, selectedItem]);
        }
    };

    const [checkedAdviserList, setCheckedAdviserList] = useState([]);
    const handleCheckAdviser = (event, selectedAdviserId) => {
        setCheckedAdviserList(checkedAdviserList.filter((item) => item !== selectedAdviserId));

        if(event.target.checked){
            setCheckedAdviserList([...checkedAdviserList, selectedAdviserId]);
        }

        setCheckedList(checkedList.filter((item) => item.adviser_id !== selectedAdviserId));
        if (event.target.checked) {
            setCheckedList([...checkedList, ...items.filter(item => item.adviser_id === selectedAdviserId)]);
        }
    };

    const [checkAll, setCheckAll] = useState(false);
    const handleCheckAll = (event) => {
        setCheckAll(event.target.checked);

        if (event.target.checked) {
            setCheckedAdviserList(items.filter(item => item.rfc).map(item => item.adviser_id));
            setCheckedList([...items.filter(item => item.rfc)]);
        } else {
            setCheckedAdviserList([]);
            setCheckedList([]);
        }
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    useEffect(() => {
        setAdviserId(query.get("position") || '');
        setInvoice(query.get("invoice") || '');
    }, []);

    function handleChangeAdviser(e) {
        setAdviserId(e.target.value);
    }

    function handleChangeInvoice(e) {
        setInvoice(e.target.value);
    }

    const formatDate = (dateString) => {
        const date = parseISO(dateString);
        return format(date, "d MMMM yyyy", { locale: es });
    };

    function search() {
        Inertia.get(route('commissions.list'),
            {
                invoice: invoice,
                asesor: adviserId,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        );
    }

    function generate() {
        Swal.fire({
            title: "¿Estás seguro que quieres generar las facturas?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {
                window.blockUI.block();

                const list = checkedList.map( ({id, adviser_id}) => ({ id, adviser_id }) );
                const groupedList = groupBy(list, item => item.adviser_id);

                let invoiceBlockUI = new KTBlockUI(document.querySelector("#kt_app_root"), {
                    message: '<div class="blockui-message"><span class="spinner-border text-primary"></span> Facturando, espere un momento por favor...</div>',
                    zIndex: 9999,
                });

                invoiceBlockUI.block();
                Inertia.post(route('commissions.make-invoice'), {
                    orders: groupedList,
                },
                {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true,
                    onError: errors => {
                        Swal.fire({
                            icon: "warning",
                            title: "Oops...",
                            text: "Error de validación",
                        });
                        return;
                    },
                    onFinish: visit => {
                        invoiceBlockUI.release();
                    },
                })
            }
        });
    }

    const maxRecords = 20;

    return (
        <div>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/comissions/financing" />
            </div>

            <Col className="mb-2 px-1">
                <h2>Financiamiento</h2>
            </Col>

            <InvoiceDetails
                ref={InvoiceDetailsRef}
                onChange={() => { }}
            />

            <InvoiceErrorDetails
                ref={InvoiceErrorDetailsRef}
                onChange={() => { }}
            />

            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            {flash.error && (
                <AlertDismissible message={flash.error} type="danger" />
            )}

            <br />

            <Nav variant="tabs" defaultActiveKey={route('commissions.invoice')} className="mb-5">
                <Nav.Item>
                    <Nav.Link href={route('commissions.list')}>Listado General</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href={route('commissions.invoice')}>Folio</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href={route('commissions.response')}>Respuesta</Nav.Link>
                </Nav.Item>
            </Nav>

            <Row className="mb-5">
                {hasPermission('quotes.commissions.view_any') && (
                    <Col sm={4}>
                        <Form.Check
                            label="Seleccionar Todos"
                            inline
                            type="checkbox"
                            name="checkAll"
                            id="checkAll"
                            onChange={handleCheckAll}
                            checked={checkAll}
                        />
                    </Col>
                )}
                {hasPermission('quotes.commissions.edit') && (
                    <Col sm={8} className="text-end">
                        <Button onClick={generate} variant="primary" className='me-3 btn-sm' type="submit" >Facturar</Button>
                    </Col>
                )}
            </Row>

            {Object.keys(groupedItems).length === 0 && (
                <div>No hay datos.</div>
            )}
            {Object.keys(groupedItems).map((key, index) => {
                const list = groupedItems[key];

                if(viewMore || index < maxRecords){
                    return (<div key={key} className="mb-5">
                        {hasPermission('quotes.commissions.view_any') && (
                            <div className="mb-2" >
                                <div className="d-inline-block" data-bs-toggle="tooltip" data-bs-custom-class="tooltip-inverse" data-bs-placement="right" title={!list[0]['rfc'] ? 'Falta el RFC' : ''}>
                                    <Form.Check
                                        label={list[0]['adviser_name']+" "+list[0]['adviser_last_name_paternal']+" "+list[0]['adviser_last_name_maternal']}
                                        inline
                                        type="checkbox"
                                        name="advisers"
                                        id={"adviser_"+list[0]['adviser_id']}
                                        onChange={(e) => handleCheckAdviser(e, list[0]['adviser_id'])}
                                        checked={checkedAdviserList.includes(list[0]['adviser_id'])}
                                        disabled={!list[0]['rfc']}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="card card-flush">
                            <div className="card-body py-4 px-2">
                                <div className="table-responsive">
                                    <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                        <thead>
                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                {hasPermission('quotes.commissions.view_any') && (
                                                    <th className='p-0 pb-3 text-center'></th>
                                                )}
                                                <th className='p-0 pb-3 text-center'>Folio</th>
                                                <th className='p-0 pb-3 text-start'>Fecha de Generación de Folio</th>
                                                <th className='p-0 pb-3 text-start'>Fecha de Generación de Comisión</th>
                                                <th className='p-0 pb-3 text-end'>Subtotal</th>
                                                <th className='p-0 pb-3 text-end'>Impuestos Traslado</th>
                                                <th className='p-0 pb-3 text-end'>Impuestos Retenidos</th>
                                                <th className='p-0 pb-3 text-end'>Comisión final</th>
                                                <th className='p-0 pb-3 text-center'>Prefactura</th>
                                                <th className='p-0 pb-3 text-center'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item) => {
                                                return (
                                                    <tr key={item["id"]}>
                                                        {hasPermission('quotes.commissions.view_any') && (
                                                            <td className="text-gray-800 fw-bold fs-6 text-center">
                                                                <div className="d-inline-block" data-bs-toggle="tooltip" data-bs-custom-class="tooltip-inverse" data-bs-placement="right" title={!item['rfc'] ? 'Falta el RFC' : ''}>
                                                                    <Form.Check
                                                                        label=""
                                                                        inline
                                                                        className="me-0"
                                                                        type="checkbox"
                                                                        name="comissions"
                                                                        id={"comission_"+item['id']}
                                                                        onChange={(e) => handleCheck(e, item)}
                                                                        checked={checkedList.some((obj) => obj.id == item['id'])}
                                                                        disabled={!item['rfc']}
                                                                    />
                                                                </div>
                                                            </td>
                                                        )}
                                                        <td className="text-gray-800 fw-bold fs-6 text-center">{item['order_number']}</td>
                                                        <td className="text-gray-800 fw-bold fs-6">{formatDate(item['created_at'])}</td>
                                                        <td className="text-gray-800 fw-bold fs-6">{formatDate(item['last_commission_created_at'])}</td>
                                                        <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['subtotal'])}</td>
                                                        <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['total_transfer_taxes'])}</td>
                                                        <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['total_retention_taxes'])}</td>
                                                        <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['total'])}</td>
                                                        <td className="text-gray-800 text-center fw-bold fs-6">
                                                            <a href={route('order.cfdi.xml', { id: item['id'] })} className='btn-sm'>
                                                                Descargar XML
                                                            </a>
                                                        </td>
                                                        <td className="text-gray-800 text-center fw-bold fs-6">
                                                            <a href="#" onClick={() => { InvoiceDetailsRef.current.openDialog(item['id']) }}>Ver Detalles</a>
                                                            {hasPermission('quotes.commissions.view_any') && item['status_name'] === 'Failed' && (
                                                                <a
                                                                    href="#"
                                                                    style={{ color: 'red', marginLeft: '10px' }}
                                                                    onClick={() => { InvoiceErrorDetailsRef.current.openDialog(item['id']) }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                        <circle cx="12" cy="12" r="10"/>
                                                                        <path d="m15 9-6 6"/>
                                                                        <path d="m9 9 6 6"/>
                                                                    </svg>
                                                                </a>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>);
                } else {
                    return '';
                }
            })}

            {Object.keys(groupedItems).length > maxRecords && <>
                <div className="text-center">
                    {!viewMore && <Link onClick={(e) => {e.preventDefault(); setViewMore(true)}} variant="primary" className='btn-sm' >Ver Más</Link>}
                    {viewMore && <Link onClick={(e) => {e.preventDefault(); setViewMore(false)}} variant="primary" className='btn-sm' >Ver Menos</Link>}
                </div>
            </>}
        </div>
    );
}

List.layout = page => <Layout children={page} title="Financiamiento" />

export default List
