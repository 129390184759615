import React, { useState, useEffect } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Iframe from 'react-iframe'
import Alert from 'react-bootstrap/Alert';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const PendingWithdrawals = () => {

    const [message, setMessage] = useState('')

    useEffect(() => {
        let intervalId = setInterval(() => {
            if (message == '') endIframePosition();
        }, 100)
        return () => clearInterval(intervalId)
    }, [message])

    React.useEffect(() => {
        const handler = (ev) => {
            if (!ev.data.message) return
            setMessage(ev.data.message)
            endIframePosition();
        }
        window.addEventListener('message', handler)
        return () => window.removeEventListener('message', handler)
    }, [])

    function endIframePosition(){
        let iframe = document.querySelector('iframe');
        iframe.contentWindow.scrollTo(0, iframe.contentDocument.body.scrollHeight);
    }

    return (
        <>
            <Row>
                <div className="mb-7 mt-7">
                    <Breadcrumbs currentPath="/admin/quotes/withdrawal/calculate/pending" />
                </div>
                <Col>
                    <h2>Actualizando Retiros pendientes</h2>
                </Col>
                {message == '' ? (
                    <Alert key="warning" variant="warning">
                        Procesando... puede tardar unos minutos.
                    </Alert>
                    )
                    : (
                        <Alert key="success" variant="success">
                            Procesando terminado
                        </Alert>
                    )}
                <div className='mt-4' style={{ width: "100%" }}>
                    <Iframe url={route('withdrawal.calculate.ammounts')+'?prod=1&withdrawals=1'}
                        display="block"
                        position="relative"
                        width="100%"
                        height="400px"
                    />
                </div>
            </Row>
        </>
    );
}

PendingWithdrawals.layout = page => <Layout children={page} title="Actualizar Retiros pendientes" />

export default PendingWithdrawals
