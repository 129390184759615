import React from 'react';
import classNames from "classnames";
import Pagination from 'react-bootstrap/Pagination';
import { Link } from '@inertiajs/inertia-react';
import ReactHtmlParser from 'react-html-parser';

function PaginationList({ links, filters = {} }) {
    const createUrlWithFilters = (url) => {
        if (!url) return '#';
        const newUrl = new URL(url, window.location.origin);
        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                newUrl.searchParams.set(key, value);
            }
        });
        return newUrl.toString();
    };

    return (
        <>
            {links.length > 3 &&
                <Pagination>
                    {links.map((link, index) => {
                        if (!link.url) {
                            return (
                                <span key={index} className='disabled page-link'>{ReactHtmlParser(link.label)}</span>
                            )
                        }
                        const urlWithFilters = createUrlWithFilters(link.url);
                        return (
                            <Link key={index} href={urlWithFilters} className={classNames({ 'active': link.active, 'page-link': true })} preserveState>
                                {ReactHtmlParser(link.label)}
                            </Link>
                        )
                    })}
                </Pagination>
            }
        </>
    );
}

export default PaginationList;
