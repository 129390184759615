import { Inertia } from '@inertiajs/inertia'
import React, { useState } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)
import "react-datepicker/dist/react-datepicker.css";

const Add = ({ companies }) => {

    const { data, setData, transform, post, processing, errors } = useForm({
        filter_type : 'all',
        company_id : '',
        policy_number : '',
        type_amount: 'percentage',
        amount: '',
        application_date: '',
    })

    function submit(e) {
        e.preventDefault()

        Swal.fire({
            title: "Esta acción cambiará la forma en la que se calcula los fondos de inversiones e inactivará el anterior valor",
            text: "¿Deseas proceder con el cambio?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function(result) {
            if (result.value) {
                transform(data => ({ ...data, application_date: moment(data.application_date).format('YYYY-MM-DD') }))
                post(route('investment_fund_percentage.create'))
            }
        });
    }
    return (
        <Row>
            <span className="pb-7 pt-7">
                <Breadcrumbs currentPath="/admin/master_data/investment_fund_percentage/add" />
            </span>
            <h2 className="mb-5">Agregar Nuevo valor de fondo de inversión</h2>
            <Form onSubmit={submit}>
                <div className="d-flex justify-content-between align-items-end mb-5">
                    <div className="d-flex justify-content-around gap-5">
                        <div>
                            <Form.Check
                                className="mb-3"
                                name="filter_type"
                                type="radio"
                                label="Todas las pólizas"
                                value="all"
                                id="all"
                                defaultChecked={(data.filter_type == 'all' ? true : false)}
                                onChange={e => setData('filter_type', 'all')}
                            />
                        </div>
                        <div>
                            <Form.Check
                                className="mb-3"
                                name="filter_type"
                                type="radio"
                                label="Retenedor"
                                value="company"
                                id="company"
                                defaultChecked={(data.filter_type == 'company' ? true : false)}
                                onChange={e => setData('filter_type', 'company')}
                            />
                            <Form.Select
                                className="form-control mx-2"
                                value={data.company_id}
                                onChange={e => setData('company_id', e.target.value)}
                            >
                                <option key='blankChoice' value={''}>Selecciona el retenedor (Empresa)</option>
                                {companies.length > 0 && (
                                    companies.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.name}</option>;
                                    }))}
                            </Form.Select>
                            {errors.company_id && <div className="mt-2 text-danger">{errors.company_id}</div>}
                        </div>
                        <div>
                            <Form.Check
                                className="mb-3"
                                name="filter_type"
                                type="radio"
                                label="Individual"
                                value="policy"
                                id="policy"
                                defaultChecked={(data.filter_type == 'policy' ? true : false)}
                                onChange={e => setData('filter_type', 'policy')}
                            />
                            <Form.Control
                                value={data.policy_number}
                                placeholder='Número de póliza'
                                type="text"
                                onChange={e => setData('policy_number', e.target.value)}
                                aria-invalid={errors.value ? "true" : "false"}
                            />
                            {errors.policy_number && <div className="mt-2 text-danger">{errors.policy_number}</div>}
                        </div>
                    </div>
                </div>

                <Form.Group className="mb-5" controlId="formAdd">
                    <InputGroup>
                        <Form.Check
                            inline
                            name="type"
                            type="radio"
                            label="Porcentaje"
                            value="percentage"
                            id="percentage"
                            defaultChecked={(data.type_amount == 'percentage' ? true : false)}
                            onChange={e => setData('type_amount', 'percentage')} className={classNames({ 'is-invalid': (errors.type) ? true : false, })} aria-invalid={errors.type_amount ? "true" : "false"} />
                        <Form.Check
                            inline
                            name="type"
                            type="radio"
                            label="Monto"
                            value="amount"
                            id="amount"
                            defaultChecked={(data.type_amount == 'amount' ? true : false)}
                            onChange={e => setData('type_amount', 'amount')} className={classNames({ 'is-invalid': (errors.type) ? true : false, })} aria-invalid={errors.type_amount ? "true" : "false"} />
                    </InputGroup>
                </Form.Group>
                <Row className="mb-3">
                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                        <Form.Label>{data.type_amount=='percentage' ? 'Valor %' : 'Monto'}</Form.Label>
                        <Form.Control
                            type="text"
                            className={classNames({'is-invalid': (errors.amount) ? true : false,})}
                            onChange={e => setData('amount', e.target.value)} aria-invalid={errors.value ? "true" : "false"}
                        />
                        {errors.amount && <div className="mt-2 text-danger">{errors.amount}</div>}
                    </Form.Group>
                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                        <Form.Label>Fecha efectiva desde:</Form.Label>
                        <DatePicker
                            className={"form-control "+classNames({'is-invalid': (errors.application_date) ? true : false,})}
                            locale="es"
                            selected={data.application_date}
                            onChange={(date) => setData('application_date', date)}
                            dateFormat="dd/MM/yyyy"
                        />
                        {errors.application_date && <div className="mt-2 text-danger">{errors.application_date}</div>}
                    </Form.Group>
                </Row>
                <Button variant="primary" className='me-3' disabled={processing} type="submit">Guardar</Button>
                <Button onClick={() => Inertia.visit(route('investment_fund_percentage.list'))} variant="secondary" type="button">Regresar</Button>
            </Form>
        </Row>
    );
}

Add.layout = page => <Layout children={page} title="Agregar Departamento" />

export default Add
