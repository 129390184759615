import React, { useState } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import { hasPermission } from '../../utils/Permissions';
import { Link, usePage } from '@inertiajs/inertia-react';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const List = ({ items }) => {
    const { flash } = usePage().props
    const data = items.data;

    return (
        <Row>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            <span className="pb-7 pt-7">
                <Breadcrumbs currentPath="/admin/master_data/product_types" />
            </span>
            <div className="card card-flush h-md-100">
                <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">Listado de Tipos de Productos</span>
                    </h3>

                    <div className="card-toolbar">
                        {hasPermission('master_data.productTypes.add') && (
                            <Link href={ route('productTypes.add') } className='me-3 btn btn-sm btn-primary'>Agregar Tipo de Produto</Link>
                        )}
                    </div>
                </div>

                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-0 pb-3 min-w-175px text-start">Nombre</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">Descripción</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">Prima</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">¿Descuento por nómina?</th>
                                    <th className="p-0 pb-3 w-50px text-end"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="3"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.name}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.description}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.bonus}%</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">
                                                            {(item.payroll_discount == true) && (
                                                                <span>Si</span>
                                                            )}
                                                            {(item.payroll_discount == false) && (
                                                                <span>No</span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-around">
                                                    <div className='mx-2'>
                                                        {hasPermission('master_data.productTypes.edit') && (
                                                            <Link href={route("productTypes.edit", item.id)}>Editar</Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Tipo de Productos" />

export default List
