import { Inertia } from '@inertiajs/inertia'
import React, { useState, useEffect, useRef } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import { TabContent } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from '@inertiajs/inertia-react'
import ScheduleInterview from './ScheduleInterview';
import { hasPermission } from '../../utils/Permissions';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';


const Edit = ({ item, office_locations, departments, positions_list, leaders_list, offices_list, calendar }) => {

    const [positions, setPositions] = useState(positions_list);
    const [leaders, setLeaders] = useState(leaders_list);
    const [offices, setOffices] = useState(offices_list);

    const ScheduleInterviewRef = useRef();
    function deleteCalendar(id) {
        Swal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {
                axios.post(route('calendar.delete', id))
                    .then((response) => {
                        toastr.success('La entrevista fue eliminada correctamente');
                        Inertia.reload({ only: ['calendar'] });
                    }).catch((error) => {
                        if (error.response.data.success !== undefined && error.response.data.success === false) {
                            toastr.error(error.response.data.message);
                        } else {
                            toastr.error("Algo salió mal, intenta nuevamente");
                        }
                    });
            }
        });
    }

    const { data, setData, post, processing, errors, reset } = useForm({
        active: item.active,
        office_id: item.office_id,
        office_location_id: item.office_location_id,
        department_id: item.department_id,
        position_id: item.position_id,
        leader_id: item.leader_id,
        nombre: item.user.name,
        email: item.user.email,
        lastname_ap: item.lastname_ap,
        lastname_am: item.lastname_am,
        years_old: item.years_old,
        scholarship: item.scholarship,
        contact_medium: item.contact_medium,
        phone_number: item.phone_number,
    })

    const scholarships = [
        "Primaria",
        "Secundaria",
        "Bachillerato general",
        "Estudios superiores (Licenciatura)",
        "Posgrado / Maestría",
        "Doctorado"
    ];

    const mediums = [
        "WhatsApp",
        "Facebook",
        "Telegram"
    ]

    function handleChangeOfficeLocations(e) {
        let office_location_id = e.target.value;
        setOffices([]);
        setData('office_location_id', office_location_id);
        getOffices(office_location_id);
    }

    function getOffices(office_location_id) {
        axios
            .get(route('employees.get.offices', office_location_id), {})
            .then((response) => {
                setOffices(response.data);
            });
    }

    function handleChangeDepartment(e) {
        let department_id = e.target.value;
        setPositions([]);
        setLeaders([]);
        setData('department_id', department_id);
        getPositions(department_id);
        getLeaders();
    }

    function getPositions(department_id) {
        axios
            .get(route('employees.get.positions', department_id), {})
            .then((response) => {
                setPositions(response.data);
            });
    }

    function getLeaders() {
        axios
            .get(route('employees.get.leaders'), {})
            .then((response) => {
                setLeaders(response.data);
            });
    }

    function submit(e) {
        e.preventDefault();
        const submitter = e.nativeEvent.submitter.name
        if (submitter == "submitAsAdviser") {
            post(route('advisers.convert', item.id))
        } else if (submitter == "submitAsEmployee") {
            post(route('employees.convert', item.id))
        } else {
            post(route('prospects.update', item.id))
        }
    }

    const [registerProspectModal, setRegisterProspectModal] = useState(false);
    const closeRegisterProspectModal = () => setRegisterProspectModal(false);
    const showRegisterProspectModal = () => setRegisterProspectModal(true);

    function registerProspect() {
        showRegisterProspectModal();
    }

    return (
        <>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/corporate_structure/prospects/edit" />
            </div>
            <h2 className="mb-4">Editar Prospecto</h2>
            <Tabs >
                <Tab eventKey="prospect" title="Prospecto" className="pt-4">
                    <TabContent className="tab-container pb-5">
                        <Form onSubmit={submit} id="prospect-form">
                            <Card className="mb-3">
                                <Container className="pt-2 pb-2">
                                    <Card.Title className="mb-4">Datos de Usuario</Card.Title>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} sm={4} controlId="formGridEmail">
                                            <Form.Label>Correo electrónico</Form.Label>
                                            <Form.Control defaultValue={data.email} disabled={true} readOnly={true} />
                                        </Form.Group>
                                        {hasPermission('corporate_structure.prospects.activate') && (
                                            <Form.Group as={Col} sm={4} controlId="formGridActive">
                                                <Form.Label>¿Activo?</Form.Label>
                                                <Form.Select
                                                    defaultValue={data.active}
                                                    name="active"
                                                    onChange={e => setData('active', e.target.value)}
                                                    className={classNames({ 'is-invalid': (errors.active) ? true : false, })} aria-invalid={errors.active ? "true" : "false"}
                                                >
                                                    <option value={1}>Sí</option>
                                                    <option value={0}>No</option>
                                                </Form.Select>
                                                {errors.active && <div className="mt-2 text-danger">{errors.active}</div>}
                                            </Form.Group>
                                        )}
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} sm={4} controlId="formGridName">
                                            <Form.Label>Nombre(s)</Form.Label>
                                            <Form.Control type="text" defaultValue={data.nombre} onChange={e => setData('nombre', e.target.value)} className={classNames({ 'is-invalid': (errors.nombre) ? true : false, })} aria-invalid={errors.nombre ? "true" : "false"} />
                                            {errors.nombre && <div className="mt-2 text-danger">{errors.nombre}</div>}
                                        </Form.Group>
                                        <Form.Group as={Col} sm={4} controlId="formGridAp">
                                            <Form.Label>Apellido Paterno</Form.Label>
                                            <Form.Control type="text" defaultValue={data.lastname_ap} onChange={e => setData('lastname_ap', e.target.value)} className={classNames({ 'is-invalid': (errors.lastname_ap) ? true : false, })} aria-invalid={errors.lastname_ap ? "true" : "false"} />
                                            {errors.lastname_ap && <div className="mt-2 text-danger">{errors.lastname_ap}</div>}
                                        </Form.Group>

                                        <Form.Group as={Col} sm={4} controlId="formGridAm">
                                            <Form.Label>Apellido Materno</Form.Label>
                                            <Form.Control type="text" defaultValue={data.lastname_am} onChange={e => setData('lastname_am', e.target.value)} className={classNames({ 'is-invalid': (errors.lastname_am) ? true : false, })} aria-invalid={errors.lastname_am ? "true" : "false"} />
                                            {errors.lastname_am && <div className="mt-2 text-danger">{errors.lastname_am}</div>}
                                        </Form.Group>
                                    </Row>
                                </Container>
                            </Card>

                            <Card className="mb-3">
                                <Container className="pt-2 pb-2">
                                    <Card.Title className="mb-4">Puesto del Empleado</Card.Title>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridOfficeLocation">
                                            <Form.Label>Estado</Form.Label>
                                            <Form.Select defaultValue={data.office_location_id} onChange={handleChangeOfficeLocations} className={classNames({ 'is-invalid': (errors.department_id) ? true : false, })} aria-invalid={errors.department_id ? "true" : "false"} >
                                                <option key='blankChoice' hidden value />
                                                {office_locations.map((office_location, index) => {
                                                    return <option key={index} value={office_location.id}>{office_location.nombre}</option>;
                                                })}
                                            </Form.Select>
                                            {errors.office_location_id && <div className="mt-2 text-danger">{errors.office_location_id}</div>}
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridOffice">
                                            <Form.Label>Oficina</Form.Label>
                                            <Form.Select defaultValue={data.office_id} onChange={e => setData('office_id', e.target.value)} className={classNames({ 'is-invalid': (errors.office_id) ? true : false, })} aria-invalid={errors.office_id ? "true" : "false"} >
                                                <option key='blankChoice' hidden value />
                                                {offices.map((office, index) => {
                                                    return <option key={index} value={office.id}>{office.nombre}</option>;
                                                })}
                                            </Form.Select>
                                            {errors.office_id && <div className="mt-2 text-danger">{errors.office_id}</div>}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridDepartment">
                                            <Form.Label>Departamento</Form.Label>
                                            <Form.Select defaultValue={data.department_id} onChange={handleChangeDepartment} className={classNames({ 'is-invalid': (errors.department_id) ? true : false, })} aria-invalid={errors.department_id ? "true" : "false"} >
                                                <option key='blankChoice' hidden value />
                                                {departments.map((department, index) => {
                                                    return <option key={index} value={department.id}>{department.nombre}</option>;
                                                })}
                                            </Form.Select>
                                            {errors.department_id && <div className="mt-2 text-danger">{errors.department_id}</div>}
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridPosition">
                                            <Form.Label>Puesto</Form.Label>
                                            <Form.Select defaultValue={data.position_id} onChange={e => setData('position_id', e.target.value)} className={classNames({ 'is-invalid': (errors.position_id) ? true : false, })} aria-invalid={errors.position_id ? "true" : "false"} >
                                                <option key='blankChoice' value={''}>Selecciona una opción</option>
                                                {positions.length > 0 && (
                                                    positions.map((position, index) => {
                                                        return <option key={index} value={position.id}>{position.nombre}</option>;
                                                    }))}
                                            </Form.Select>
                                            {errors.position_id && <div className="mt-2 text-danger">{errors.position_id}</div>}
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridLeader">
                                            <Form.Label>Lider</Form.Label>
                                            <Form.Select defaultValue={data.leader_id} onChange={e => setData('leader_id', e.target.value)} className={classNames({ 'is-invalid': (errors.leader_id) ? true : false, })} aria-invalid={errors.leader_id ? "true" : "false"} >
                                                <option key='blankChoice' hidden value />
                                                {leaders.length > 0 && (
                                                    leaders.map((leader, index) => {
                                                        return <option key={index} value={leader.id}>{leader.name}</option>;
                                                    }))}
                                            </Form.Select>
                                            {errors.leader_id && <div className="mt-2 text-danger">{errors.leader_id}</div>}
                                        </Form.Group>
                                    </Row>
                                </Container>
                            </Card>

                            <Card className="mb-5">
                                <Container className="pt-2 pb-2">
                                    <Card.Title className="mb-4">Información Personal</Card.Title>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} sm={3} controlId="formGridYears">
                                            <Form.Label>Edad</Form.Label>
                                            <Form.Control type="number" defaultValue={data.years_old} min="1" max="150" onChange={e => setData('years_old', e.target.value)} className={classNames({ 'is-invalid': (errors.years_old) ? true : false, })} aria-invalid={errors.years_old ? "true" : "false"} />
                                            {errors.years_old && <div className="mt-2 text-danger">{errors.years_old}</div>}
                                        </Form.Group>
                                        <Form.Group as={Col} sm={3} controlId="formGridScholarship">
                                            <Form.Label>Escolaridad</Form.Label>
                                            <Form.Select defaultValue={data.scholarship} name="scholarship" onChange={e => setData('scholarship', e.target.value)} className={classNames({ 'is-invalid': (errors.scholarship) ? true : false, })} aria-invalid={errors.scholarship ? "true" : "false"} >
                                                <option key='blankChoice' hidden value />
                                                {scholarships.map((scholarship, index) => {
                                                    return <option key={index} value={scholarship}>{scholarship}</option>;
                                                })}
                                            </Form.Select>
                                            {errors.scholarship && <div className="mt-2 text-danger">{errors.scholarship}</div>}
                                        </Form.Group>

                                        <Form.Group as={Col} sm={3} controlId="formGridContact">
                                            <Form.Label>Medio de contacto</Form.Label>
                                            <Form.Select defaultValue={data.contact_medium} name="contact_medium" onChange={e => setData('contact_medium', e.target.value)} className={classNames({ 'is-invalid': (errors.contact_medium) ? true : false, })} aria-invalid={errors.contact_medium ? "true" : "false"} >
                                                <option key='blankChoice' hidden value />
                                                {mediums.map((medium, index) => {
                                                    return <option key={index} value={medium}>{medium}</option>;
                                                })}
                                            </Form.Select>
                                            {errors.contact_medium && <div className="mt-2 text-danger">{errors.contact_medium}</div>}
                                        </Form.Group>

                                        <Form.Group as={Col} sm={3} controlId="formGridPhone">
                                            <Form.Label>Teléfono de contacto</Form.Label>
                                            <Form.Control type="tel" defaultValue={data.phone_number} onChange={e => setData('phone_number', e.target.value)} className={classNames({ 'is-invalid': (errors.phone_number) ? true : false, })} aria-invalid={errors.phone_number ? "true" : "false"} />
                                            {errors.phone_number && <div className="mt-2 text-danger">{errors.phone_number}</div>}
                                        </Form.Group>
                                    </Row>
                                </Container>
                            </Card>

                            {/* <!--begin:Register Modal--> */}
                            <Modal show={registerProspectModal} onHide={closeRegisterProspectModal} scrollable={true} size="lg" id="register-prospect">
                                <Modal.Header closeButton>
                                    <Modal.Title>Guardar Prospecto</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <div className="pl-3 mb-3">
                                        <h3 className='font-weight-normal text-center pb-2'>¿Como deseas registrar este Prospecto?</h3>
                                        <div className="d-flex justify-content-between pb-2 row">
                                            <div className='col-8'>
                                                <h5 className='font-weight-bold'>Asesor</h5>
                                                <p>Convertir a este prospecto en Asesor y completar la información en el siguiente formulario.</p>
                                            </div>
                                            <div className="col-3 text-center pt-2">
                                                <Button variant="info" size="sm" type="submit" name="submitAsAdviser" form="prospect-form">Convertir a Asesor</Button>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between py-2 row">
                                            <div className='col-8'>
                                                <h5 className='font-weight-bold'>Empleado</h5>
                                                <p className='col-md'>Convertir a este contacto en Empleado y completar la información en el siguiente formulario.</p>
                                            </div>
                                            <div className='col-3 text-center pt-2'>
                                                <Button variant="info" size="sm" type="submit" name="submitAsEmployee" form="prospect-form">Convertir a Empleado</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            {/* <!--end:Register Modal--> */}

                            <div className="mt-3 text-center">
                                <Button className='me-3' onClick={() => window.history.back()} variant="secondary" type="normal">Regresar</Button>
                                <Button variant="primary" disabled={processing} type="submit">Actualizar</Button>
                            </div>
                            {hasPermission('quotes.appointments.prospects.convert') && (
                                <div className="mt-3 pb-5 text-center">
                                    <Button onClick={registerProspect} variant="info" size="sm" >Registrar como Asesor/Empleado</Button>
                                </div>
                            )}
                        </Form>
                    </TabContent>
                </Tab>
                {hasPermission('quotes.appointments.prospects.view') && (
                    <Tab eventKey="calendar" title="Entrevista" className="pt-4">
                        <TabContent className="tab-container">
                            <Form className="pb-5">
                                <Card className="mb-3">
                                    <Container className="pt-2 pb-2">
                                        <Row>
                                            <Col>
                                                <Card.Title className="mb-4 mt-4">Entrevistas programadas</Card.Title>
                                            </Col>
                                            <Col className="text-end">
                                                <ScheduleInterview ref={ScheduleInterviewRef} onChange={() => { Inertia.reload({ only: ['calendar'] }); }}></ScheduleInterview>
                                                {hasPermission('quotes.appointments.prospects.create') && (
                                                    <Button onClick={() => { ScheduleInterviewRef.current.newInterviewDialog(item.id) }} size="sm" variant="primary">Agendar Entrevista</Button>
                                                )}
                                            </Col>
                                        </Row>

                                        <div className="card-body pt-6">
                                            <div className="table-responsive">
                                                <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                                                    <thead>
                                                        <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                            <th className="p-0 pb-3 w-100px text-start">ENTREVISTADOR</th>
                                                            <th className="p-2 pb-3 w-100px text-start">FECHA</th>
                                                            <th className="p-2 pb-3 w-100px text-start">HORARIO</th>
                                                            <th className="p-2 pb-3 w-50px text-start">ESTATUS</th>
                                                            <th className="p-0 pb-3 w-50px text-center">ACCIONES</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {calendar.length === 0 && (
                                                            <tr>
                                                                <td
                                                                    className="py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                                                    colSpan="6"
                                                                >
                                                                    No hay datos.
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {
                                                            calendar.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <p className="text-gray-800 fw-bold mb-1 fs-6">{item.user?.name}</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="text-gray-800 fw-bold mb-1 fs-6">{moment(item.date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="text-gray-800 fw-bold mb-1 fs-6">{moment(item.time_start, 'HH:mm').format('hh:mm A')} - {moment(item.time_end, 'HH:mm').format('hh:mm A')}</p>
                                                                        </td>
                                                                        <td>
                                                                            {item.status == "open" && <span className="badge badge-light-success">Abierta</span>}
                                                                            {item.status == "close" && <span className="badge badge-light-warning">Cerrada</span>}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {hasPermission('quotes.appointments.prospects.edit') && (
                                                                                <Link className="me-3" onClick={(e) => { e.preventDefault(); ScheduleInterviewRef.current.editInterviewDialog(item) }}>Editar</Link>
                                                                            )}
                                                                            {hasPermission('quotes.appointments.prospects.delete') && (
                                                                                <Link onClick={(e) => { e.preventDefault(); deleteCalendar(item.id); }}>Borrar</Link>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Container>
                                </Card>
                            </Form>
                        </TabContent>
                    </Tab>
                )}

            </Tabs>
            <style type="text/css">
                {`
                    .tab-container {
                        background-color: white;
                    }

                    [role=tabpanel] {
                        padding-top: 0rem!important;
                    }
                `}
            </style>
        </>
    );
}

Edit.layout = page => <Layout children={page} title="Editar Prospecto" />

export default Edit
