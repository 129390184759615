import { Inertia } from '@inertiajs/inertia'
import React, { useState } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../../Shared/Layout/LayoutAdmin';

const Edit = ({ item, positions }) => {

    const { data, setData, post, processing, errors } = useForm({
        nombre: item.nombre || "",
        orden: item.orden,
        activo: item.activo,
        position_id: item.position_id,
    })

    function submit(e) {
        e.preventDefault()
        post(route('departments.positions.update', item.id))
    }
    return (
        <Row>
            <h2>Editar Puesto</h2>
            <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="formAdd">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.nombre) ? true : false,
                    })}
                        defaultValue={data.nombre} onChange={(e) =>
                            setData("nombre", e.target.value)
                        } aria-invalid={errors.nombre ? "true" : "false"} />
                    {errors.nombre && <div className="mt-2 text-danger">{errors.nombre}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Puesto al que reporta</Form.Label>
                    <Form.Select defaultValue={data.position_id}  onChange={e => setData('position_id', e.target.value)}>
                        <option value="">Ninguno</option>
                        {positions.map((item, index) => {
                            return (
                                <option key={index} value={item.id}>{item.nombre}</option>
                            );
                        })}
                    </Form.Select>
                    {errors.position_id && <div className="mt-2 text-danger">{errors.position_id}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Órden</Form.Label>
                    <Form.Control defaultValue={data.orden} type="number" className={classNames({
                        'is-invalid': (errors.orden) ? true : false,
                    })}
                        onChange={e => setData('orden', e.target.value)} aria-invalid={errors.orden ? "true" : "false"} />
                    {errors.orden && <div className="mt-2 text-danger">{errors.orden}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Activo?</Form.Label>
                    <Form.Select defaultValue={data.activo} onChange={e => setData('activo', e.target.value)}>
                        <option value={1}>Sí</option>
                        <option value={0}>No</option>
                    </Form.Select>
                    {errors.activo && <div className="mt-2 text-danger">{errors.activo}</div>}
                </Form.Group>
                <Button variant="primary" className='me-3' disabled={processing} type="submit">Guardar</Button>
                <Button onClick={()=>window.history.back()} variant="secondary" type="normal">Regresar</Button>
            </Form>
        </Row>
    );
}

Edit.layout = page => <Layout children={page} title="Editar Puesto" />

export default Edit
