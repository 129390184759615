require('./bootstrap');
import { createRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/inertia-react'
import { InertiaProgress } from '@inertiajs/progress'

if (document.getElementById('app-react')) {
    const app = createRoot(document.getElementById('app-react'))

    createInertiaApp({
        id: 'app-react',
        resolve: name => require(`./Pages/${name}`),
        setup({ App, props }) {
            app.render(<App {...props} />)
        },
    })

    InertiaProgress.init()
}
