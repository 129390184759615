import { Inertia } from '@inertiajs/inertia'
import React, { useState } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const Edit = ({ item }) => {

    const { data, setData, post, processing, errors } = useForm({
        name: item.name || "",
        description: item.description || "",
        bonus: item.bonus || "",
        payroll_discount: item.payroll_discount || "",
    })

    function submit(e) {
        e.preventDefault()
        post(route('productTypes.update', item.id))
    }
    return (
        <Row>
            <span className="pb-7 pt-7">
                <Breadcrumbs currentPath="/admin/master_data/product_types/edit" />
            </span>
            <h2>Editar Tipo de Producto</h2>
            <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="formAdd">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.name) ? true : false,
                    })}
                        value={data.name} onChange={(e) =>
                            setData("name", e.target.value)
                        } aria-invalid={errors.name ? "true" : "false"} />
                    {errors.name && <div className="mt-2 text-danger">{errors.name}</div>}
                </Form.Group>
                <Form.Group className="mb-5 mt-2" controlId="">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.description) ? true : false,
                    })}
                    value={data.description} onChange={e => setData('description', e.target.value)} aria-invalid={errors.description ? "true" : "false"} />
                    {errors.description && <div className="mt-2 text-danger">{errors.description}</div>}
                </Form.Group>

                <Form.Group  as={Col} sm={3} className="mb-3">
                    <Form.Label>Prima</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" min=".01" step=".01" max="100" value={data.bonus} onChange={e => setData('bonus', e.target.value)} className={classNames({ 'is-invalid': (errors.bonus) ? true : false, })} aria-invalid={errors.bonus ? "true" : "false"} />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    {errors.bonus && <div className="mt-2 text-danger">{errors.bonus}</div>}
                </Form.Group>
                <Row className="mb-6">
                    <Form.Group as={Col} controlId="formGridVeritas">
                        <Form.Label>¿Descuento por nómina?</Form.Label>
                        <InputGroup>
                            <Form.Check
                                inline
                                name="payroll_discount"
                                type="radio"
                                label="Si"
                                value="1"
                                id="1"
                                defaultChecked={(data.payroll_discount == 1 ? true : false)}
                                onChange={e => setData('payroll_discount', 1)} className={classNames({ 'is-invalid': (errors.payroll_discount) ? true : false, })} aria-invalid={errors.payroll_discount ? "true" : "false"} />
                            <Form.Check
                                inline
                                name="payroll_discount"
                                type="radio"
                                label="No"
                                value="0"
                                id="0"
                                defaultChecked={(data.payroll_discount == 0 ? true : false)}
                                onChange={e => setData('payroll_discount', 0)} className={classNames({ 'is-invalid': (errors.payroll_discount) ? true : false, })} aria-invalid={errors.payroll_discount ? "true" : "false"} />
                        </InputGroup>
                        {errors.payroll_discount && <div className="mt-2 text-danger">{errors.payroll_discount}</div>}
                    </Form.Group>
                </Row>

                <Button variant="primary" className='me-3' disabled={processing} type="submit">Guardar</Button>
                <Button onClick={()=>window.history.back()} variant="secondary" type="normal">Regresar</Button>
            </Form>
        </Row>
    );
}

Edit.layout = page => <Layout children={page} title="Editar Tipo de Producto" />

export default Edit
