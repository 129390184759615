import React from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';

const Home = ({ user }) => {
  return (
    <>
      <h1>Bienvenido</h1>
      <p>Dashboard Admin</p>
    </>
  )
}

Home.layout = page => <Layout children={page} title="Dashboard Admin" />

export default Home
