import React, { useState } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import { hasPermission } from '../../utils/Permissions';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import { Link, usePage } from '@inertiajs/inertia-react'

const List = ({ items, department_positions, latest_update}) => {
    const { flash } = usePage().props
    const data = items.data;

    return (
        <Row>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            <span className="p-7">
                <Breadcrumbs currentPath="/admin/master_data/departments" />
            </span>
            <div className="card card-flush h-md-100">
                <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">Listado de Departamentos</span>
                        <span className="text-gray-400 mt-1 fw-semibold fs-6">Ultima Actualización: {latest_update}</span>
                    </h3>

                    <div className="card-toolbar">
                        <a href={ route('departments.export') } className='me-3 btn btn-sm btn-secondary'><i className="bi bi-file-earmark-excel"></i> Exportar a Excel</a>
                        <Link href={ route('departments.add') } className='me-3 btn btn-sm btn-primary'>Agregar Departamento</Link>
                    </div>
                </div>
                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-0 pb-3 min-w-175px text-start">NOMBRE</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">ACTIVO</th>
                                    <th className="p-0 pb-3 w-50px text-end"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="3"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.nombre}</p>
                                                        <span className="text-gray-400 fw-semibold d-block fs-7">
                                                            {department_positions[item.id]} puestos
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-0">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        {item.activo === 1 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-success">Si</span>
                                                        )}
                                                        {item.activo === 0 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-warning">No</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-around">
                                                    <div className='mx-2'>
                                                        <Link href={route("departments.edit", item.id)}>Editar</Link>
                                                    </div>
                                                    <div className='mx-2'>
                                                        {hasPermission('master_data.positions.view') && (
                                                            <Link href={route("departments.positions.list", item.id)}>Puestos</Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Departamentos" />

export default List
