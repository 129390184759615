import React from 'react';
import { Inertia } from '@inertiajs/inertia'
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const Permissions = ({ role, permissions }) => {
    const { data, setData, post, processing } = useForm({
        name: role.name || "",
        role_id: role.id,
        permissions: permissions || []
    });

    const handlePermissionChange = (categoryId, permissionId) => {
        const updatedPermissions = data.permissions.map(category => {
            if (category.name === categoryId) {
                return {
                    ...category,
                    permissions: category.permissions.map(permission => {
                        if (permission.id === permissionId) {
                            return { ...permission, associated: !permission.associated };
                        }
                        return permission;
                    })
                };
            }
            return category;
        });

        setData('permissions', updatedPermissions);
    };

    const handleToggleAllPermissions = (categoryId, isChecked) => {
        const updatedPermissions = data.permissions.map(category => {
            if (category.name === categoryId) {
                return {
                    ...category,
                    permissions: category.permissions.map(permission => ({
                        ...permission, associated: isChecked
                    }))
                };
            }
            return category;
        });

        setData('permissions', updatedPermissions);
    };

    function submit(e) {
        e.preventDefault();
        post(route('roles.update.permissions', role.id), data);
    }

    return (
        <Row>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/corporate_structure/roles/permissions" />
            </div>
            <h3 className="card-title align-items-start flex-column mb-3">
                <div className="card-label fw-bold text-gray-800">Permisos de rol de {data.name}</div>
                <div className="text-gray-400 mt-1 fw-semibold fs-6">Selecciona los permisos para el rol seleccionado</div>
            </h3>
            <Form onSubmit={submit}>
                {data.permissions.map((category, catIndex) => (
                    <Card className="mb-3" key={catIndex}>
                        <Container className="pt-4 pb-4">
                            <Card.Title className="mt-5 mx-5 fs-6 text-gray-800">{category.name}</Card.Title>
                            <Card.Body className="px-0 py-0">
                                <div className="row d-flex justify-content-between">
                                    <Form.Group className="mt-5 mb-5 mx-5 col-md-4" controlId={`formAdd-${category.name}`}>
                                        <Form.Check
                                            type="switch"
                                            key={`all-${category.name}`}
                                            id={`all-${category.name}`}
                                            label="Todos"
                                            checked={category.permissions.every(permission => permission.associated)}
                                            className="mb-6"
                                            onChange={(e) => handleToggleAllPermissions(category.name, e.target.checked)}
                                        />
                                        {category.permissions.map((permission, permIndex) => (
                                            <Form.Check
                                                type="switch"
                                                key={permIndex}
                                                id={`permission-${permission.id}`}
                                                label={permission.label}
                                                checked={permission.associated}
                                                onChange={() => handlePermissionChange(category.name, permission.id)}
                                                className="mb-6"
                                            />
                                        ))}
                                    </Form.Group>
                                </div>
                            </Card.Body>
                        </Container>
                    </Card>
                ))}
                <Button variant="primary" className='me-3' disabled={processing} type="submit">Guardar</Button>
                <Button onClick={() => window.history.back()} variant="secondary" type="button">Regresar</Button>
            </Form>
        </Row>
    );
}

Permissions.layout = page => <Layout children={page} title="Permisos" />

export default Permissions;