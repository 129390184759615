import React, { useEffect } from 'react'
import { Link, Head } from '@inertiajs/inertia-react'
import { usePage } from '@inertiajs/inertia-react'
import LayoutAdminHeader from './LayoutAdminHeader';
import LayoutAdminSidebar from './LayoutAdminSidebar';
import LayoutAdminFooter from './LayoutAdminFooter';

export default function LayoutAdmin({ title, children }) {
    const { auth } = usePage().props
    //{auth.user.name}

    return (
        <>
            <Head>
                <title>{title ? `${title}` : 'Yireh & Brokers'}</title>
            </Head>
            <LayoutAdminHeader auth={auth}></LayoutAdminHeader>

            {/* <!--begin::Wrapper--> */}
            <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <LayoutAdminSidebar auth={auth}></LayoutAdminSidebar>
                {/* <!--begin::Main--> */}
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                    {/* <!--begin::Content wrapper--> */}
                    <div className="d-flex flex-column flex-column-fluid">
                        {/* <!--begin::Toolbar--> */}
                        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                            {/* <!--begin::Toolbar container--> */}
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                                {/* <!--begin::Page title--> */}
                                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                    {/* <!--begin::Title--> */}
                                    <h1 className="d-none page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{title ? `${title}` : ''}</h1>
                                    {/* <!--end::Title--> */}
                                </div>
                                {/* <!--end::Page title--> */}
                            </div>
                            {/* <!--end::Toolbar container--> */}
                        </div>
                        {/* <!--end::Toolbar--> */}

                        {/* <!--begin::Content--> */}
						<div id="kt_app_content" className="app-content flex-column-fluid">
                            {/* <!--begin::Content container--> */}
                            <div id="kt_app_content_container" className="app-container container-xxl">
                                { children }
                            </div>
                            {/* <!--end::Content container--> */}
                        </div>
                        {/* <!--end::Content--> */}
                    </div>
                    {/* <!--end::Content wrapper--> */}
                    <LayoutAdminFooter></LayoutAdminFooter>
                </div>
                {/* <!--end::Main--> */}
            </div>
            {/* <!--end::Wrapper--> */}
        </>
    )
}
