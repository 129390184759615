import React, { useState } from 'react'
import Layout from '../../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { hasPermission } from '../../../utils/Permissions';
import Table from 'react-bootstrap/Table';
import Breadcrumbs from '../../../Shared/Components/Breadcrumbs';
import AlertDismissible from '../../../Shared/Components/AlertDismissible';
import { Link, usePage } from '@inertiajs/inertia-react'


const List = ({ items, department }) => {
    const { flash } = usePage().props
    const data = items.data;

    return (
        <Row>
            <span className="p-7">
                <Breadcrumbs currentPath="/admin/master_data/departments/positions" />
            </span>
            <Col>
                <h2></h2>
                <h3></h3>
            </Col>
            <Col style={{ textAlign: 'right' }}>
            </Col>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}

            <div className="card card-flush h-md-100">
                <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">Listado de Puestos</span>
                        <span className="text-gray-400 mt-1 fw-semibold fs-6">{ department.nombre }</span>
                    </h3>

                    <div className="card-toolbar">
                        <a href={ route('departments.positions.export', department.id) } className='me-3 btn btn-sm btn-secondary'><i className="bi bi-file-earmark-excel"></i> Exportar a Excel</a>
                        {hasPermission('master_data.positions.add') && (
                            <Link href={ route('departments.positions.add', department.id) } className='me-3 btn btn-sm btn-primary'>Agregar Puesto</Link>
                        )}
                        <Button href={route('departments.list')} className='btn-sm' variant="secondary" type="normal">Regresar</Button>
                    </div>
                </div>

                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-0 pb-3 min-w-125px text-start">NOMBRE</th>
                                    <th className="p-0 pb-3 min-w-125px text-start">PUESTO AL QUE REPORTA</th>
                                    <th className="p-0 pb-3 min-w-100px text-start">ÓRDEN</th>
                                    <th className="p-0 pb-3 min-w-100px text-start pe-12">ACTIVO</th>
                                    <th className="p-0 pb-3 w-50px text-end"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="4"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.nombre}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{(item.position!= null)?item.position.nombre:''}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.orden}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-0">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        {item.activo === 1 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-success">Si</span>
                                                        )}
                                                        {item.activo === 0 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-warning">No</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {hasPermission('master_data.positions.edit') && (
                                                    <Link href={route("departments.positions.edit", item.id)}>Editar</Link>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Puestos" />

export default List
