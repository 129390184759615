import { Inertia } from '@inertiajs/inertia'
import React, { useState } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const Edit = ({ role }) => {

    const { data, setData, post, processing, errors } = useForm({
        name: role.name || "",
    })

    function submit(e) {
        e.preventDefault()
        post(route('roles.update', role.id))
    }
    return (
        <Row>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/corporate_structure/roles/edit" />
            </div>
            <h2>Editar Rol</h2>
            <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="formAdd">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.name) ? true : false,
                    })}
                        value={data.name} onChange={(e) =>
                            setData("name", e.target.value)
                        } aria-invalid={errors.name ? "true" : "false"} />
                    {errors.name && <div className="mt-2 text-danger">{errors.name}</div>}
                </Form.Group>
                <Button variant="primary" className='me-3' disabled={processing} type="submit">Guardar</Button>
                <Button onClick={()=>window.history.back()} variant="secondary" type="normal">Regresar</Button>
            </Form>
        </Row>
    );
}

Edit.layout = page => <Layout children={page} title="Editar Rol" />

export default Edit
