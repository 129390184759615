import { Inertia } from '@inertiajs/inertia'
import React, { useState, useEffect } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';

const Convertion = ({ states, office_locations, departments, positions_list, leaders_list, offices_list, adviser, roles}) => {

    const [positions, setPositions] = (positions_list != null) ? useState(positions_list) : useState([[]]);
    const [leaders, setLeaders] = (leaders_list != null) ? useState(leaders_list) : useState([[]]);
    const [offices, setOffices] = (offices_list != null) ? useState(offices_list) : useState([[]]);

    const { data, setData, post, processing, errors, reset } = useForm({
        office_id: adviser.office_id,
        office_location_id: adviser.office_location_id,
        department_id: adviser.department_id,
        position_id: adviser.position_id,
        leader_id: adviser.leader_id,
        email: adviser.user.email,
        nombre: adviser.user.name,
        lastname_ap: adviser.lastname_ap,
        lastname_am: adviser.lastname_am,
        years_old: adviser.years_old,
        scholarship: adviser.scholarship,
        contact_medium: adviser.contact_medium,
        phone_number: adviser.phone_number,
        street: adviser.phone_number,
        suburb: adviser.suburb,
        ext_number: adviser.ext_number,
        int_number: adviser.int_number,
        state_id: adviser.state_id,
        municipality: adviser.municipality,
        zip_code: adviser.zip_code,
        account_number: adviser.account_number,
        bank: adviser.bank,
        clabe: adviser.clabe,
        commission_scheme: adviser.commission_scheme,
        veritas: adviser.veritas,
        rol: adviser.user.roles[0]?.name,
    })

    const banks = [
        "ABC CAPITAL",
        "ACTINVER",
        "AFIRME",
        "ARCUS",
        "ASP INTEGRA OPC",
        "AUTOFIN",
        "AZTECA",
        "BaBien",
        "BAJIO",
        "BANAMEX",
        "BANCO COVALTO",
        "BANCOMEXT",
        "BANCOPPEL",
        "BANCO S3",
        "BANCREA",
        "BANJERCITO",
        "BANKAOOL",
        "BANK OF AMERICA",
        "BANK OF CHINA",
        "BANOBRAS",
        "BANORTE",
        "BANREGIO",
        "BANSI",
        "BANXICO",
        "BARCLAYS",
        "BBASE",
        "BBVA MEXICO",
        "BMONEX",
        "CAJA POP MEXICA",
        "CAJA TELEFONIST",
        "CB INTERCAM",
        "CIBANCO",
        "CI BOLSA",
        "CLS",
        "CoDi Valida",
        "COMPARTAMOS",
        "CONSUBANCO",
        "CREDICAPITAL",
        "CREDIT SUISSE",
        "CRISTOBAL COLON",
        "DONDE",
        "FINAMEX",
        "FINCOMUN",
        "FOMPED",
        "FONDO (FIRA)",
        "GBM",
        "HIPOTECARIA FED",
        "HSBC",
        "ICBC",
        "INBURSA",
        "INDEVAL",
        "INMOBILIARIO",
        "INTERCAM BANCO",
        "INVERCAP",
        "INVEX",
        "JP MORGAN",
        "KUSPIT",
        "LIBERTAD",
        "MASARI",
        "MIFEL",
        "MIZUHO BANK",
        "MONEXCB",
        "MUFG",
        "MULTIVA BANCO",
        "MULTIVA CBOLSA",
        "NAFIN",
        "NU MEXICO",
        "NVIO",
        "OPM",
        "PAGATODO",
        "PROFUTURO",
        "SABADELL",
        "SANTANDER",
        "SCOTIABANK",
        "SHINHAN",
        "STP",
        "TACTIV CB",
        "UNAGRA",
        "VALMEX",
        "VALUE",
        "VECTOR",
        "VE POR MAS",
        "VOLKSWAGEN"
    ];

    const scholarships = [
        "Primaria",
        "Secundaria",
        "Bachillerato general",
        "Estudios superiores (Licenciatura)",
        "Posgrado / Maestría",
        "Doctorado"
    ];

    const mediums = [
        "WhatsApp",
        "Facebook",
        "Telegram"
    ]

    const style = { color: "#FFCC33", fontSize: "7em" }

    function handleChangeOfficeLocations(e) {
        let office_location_id = e.target.value;
        setOffices([]);
        setData('office_location_id', office_location_id);
        getOffices(office_location_id);
    }

    function getOffices(office_location_id) {
        axios
            .get(route('employees.get.offices', office_location_id), {})
            .then((response) => {
                setOffices(response.data);
            });
    }

    function handleChangeDepartment(e) {
        let department_id = e.target.value;
        setPositions([]);
        setLeaders([]);
        setData('department_id', department_id);
        getPositions(department_id);
        getLeaders();
    }

    function getPositions(department_id) {
        axios
            .get(route('employees.get.positions', department_id), {})
            .then((response) => {
                setPositions(response.data);
            });
    }

    function getLeaders() {
        axios
            .get(route('employees.get.leaders'), {})
            .then((response) => {
                setLeaders(response.data);
            });
    }

    function submit(e) {
        e.preventDefault()
        const submitter = e.nativeEvent.submitter.name
        if (submitter == "convertAdviser") {
            post(route('employees.convert.adviser', adviser.id))
        }
    }

    const [registerAsEmployeeModal, setRegisterAsEmployeeModal] = useState(false);
    const closeRegisterAsEmployeeModal = () => setRegisterAsEmployeeModal(false);
    const showRegisterAsEmployeeModal = () => setRegisterAsEmployeeModal(true);

    function registerAsEmployee(){
        showRegisterAsEmployeeModal();
    }

    return (
        <Row>
            <h2 className="mb-4">Convertir Asesor a Empleado</h2>
            <Container>
                <Form onSubmit={submit} id="adviser-to-employee-form">
                    <Card className="mb-3">
                        <Container className="pt-2 pb-2">
                            <Card.Title className="mb-4">Datos de Usuario</Card.Title>

                            <Row className="mb-3">
                                <Form.Group as={Col} sm={4} controlId="formGridEmail">
                                    <Form.Label>Correo electrónico</Form.Label>
                                    <Form.Control defaultValue={data.email} disabled={true} readOnly={true} />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} sm={4} controlId="formGridName">
                                    <Form.Label>Nombre(s)</Form.Label>
                                    <Form.Control defaultValue={data.nombre} type="text" onChange={e => setData('nombre', e.target.value)} className={classNames({ 'is-invalid': (errors.nombre) ? true : false, })} aria-invalid={errors.nombre ? "true" : "false"} />
                                    {errors.nombre && <div className="mt-2 text-danger">{errors.nombre}</div>}
                                </Form.Group>
                                <Form.Group as={Col} sm={4} controlId="formGridAp">
                                    <Form.Label>Apellido Paterno</Form.Label>
                                    <Form.Control defaultValue={data.lastname_ap} type="text" onChange={e => setData('lastname_ap', e.target.value)} className={classNames({ 'is-invalid': (errors.lastname_ap) ? true : false, })} aria-invalid={errors.lastname_ap ? "true" : "false"} />
                                    {errors.lastname_ap && <div className="mt-2 text-danger">{errors.lastname_ap}</div>}
                                </Form.Group>

                                <Form.Group as={Col} sm={4} controlId="formGridAm">
                                    <Form.Label>Apellido Materno</Form.Label>
                                    <Form.Control defaultValue={data.lastname_am} type="text" onChange={e => setData('lastname_am', e.target.value)} className={classNames({ 'is-invalid': (errors.lastname_am) ? true : false, })} aria-invalid={errors.lastname_am ? "true" : "false"} />
                                    {errors.lastname_am && <div className="mt-2 text-danger">{errors.lastname_am}</div>}
                                </Form.Group>
                            </Row>
                        </Container>
                    </Card>

                    <Card className="mb-3">
                        <Container className="pt-2 pb-2">
                            <Card.Title className="mb-4">Puesto del Asesor</Card.Title>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridOfficeLocation">
                                    <Form.Label>Estado</Form.Label>
                                    <Form.Select defaultValue={data.office_location_id} onChange={handleChangeOfficeLocations} className={classNames({ 'is-invalid': (errors.office_location_id) ? true : false, })} aria-invalid={errors.office_location_id ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {office_locations.map((office_location, index) => {
                                            return <option key={index} value={office_location.id}>{office_location.nombre}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.office_location_id && <div className="mt-2 text-danger">{errors.office_location_id}</div>}
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridOffice">
                                    <Form.Label>Oficina</Form.Label>
                                    <Form.Select defaultValue={data.office_id} onChange={e => setData('office_id', e.target.value)} className={classNames({ 'is-invalid': (errors.office_id) ? true : false, })} aria-invalid={errors.office_id ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {offices.map((office, index) => {
                                            return <option key={index} value={office.id}>{office.nombre}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.office_id && <div className="mt-2 text-danger">{errors.office_id}</div>}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridDepartment">
                                    <Form.Label>Departamento</Form.Label>
                                    <Form.Select defaultValue={data.department_id} onChange={handleChangeDepartment} className={classNames({ 'is-invalid': (errors.department_id) ? true : false, })} aria-invalid={errors.department_id ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {departments.map((department, index) => {
                                            return <option key={index} value={department.id}>{department.nombre}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.department_id && <div className="mt-2 text-danger">{errors.department_id}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPosition">
                                    <Form.Label>Puesto</Form.Label>
                                    <Form.Select defaultValue={data.position_id} onChange={e => setData('position_id', e.target.value)} className={classNames({ 'is-invalid': (errors.position_id) ? true : false, })} aria-invalid={errors.position_id ? "true" : "false"} >
                                        <option key='blankChoice' value={''}>Selecciona una opción</option>
                                        {positions.length > 0 && (
                                            positions.map((position, index) => {
                                                return <option key={index} value={position.id}>{position.nombre}</option>;
                                            }))}
                                    </Form.Select>
                                    {errors.position_id && <div className="mt-2 text-danger">{errors.position_id}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridLeader">
                                    <Form.Label>Lider</Form.Label>
                                    <Form.Select defaultValue={data.leader_id} onChange={e => setData('leader_id', e.target.value)} className={classNames({ 'is-invalid': (errors.leader_id) ? true : false, })} aria-invalid={errors.leader_id ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {leaders.length > 0 && (
                                            leaders.map((leader, index) => {
                                                return <option key={index} value={leader.id}>{leader.name}</option>;
                                            }))}
                                    </Form.Select>
                                    {errors.leader_id && <div className="mt-2 text-danger">{errors.leader_id}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridLeader">
                                    <Form.Label>Rol</Form.Label>
                                    <Form.Select defaultValue={data.rol} onChange={e => setData('rol', e.target.value)} className={classNames({ 'is-invalid': (errors.leader_id) ? true : false, })} aria-invalid={errors.leader_id ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {roles.length > 0 && (
                                            roles.map((rol, index) => {
                                                return <option key={index} value={rol}>{rol}</option>;
                                            }))}
                                    </Form.Select>
                                    {errors.rol && <div className="mt-2 text-danger">{errors.rol}</div>}
                                </Form.Group>
                            </Row>
                        </Container>
                    </Card>

                    <Card className="mb-3">
                        <Container className="pt-2 pb-2">
                            <Card.Title className="mb-4">Información Personal</Card.Title>
                            <Row className="mb-3">
                                <Form.Group as={Col} sm={3} controlId="formGridYears">
                                    <Form.Label>Edad</Form.Label>
                                    <Form.Control defaultValue={data.years_old} type="number" min="1" max="150" onChange={e => setData('years_old', e.target.value)} className={classNames({ 'is-invalid': (errors.years_old) ? true : false, })} aria-invalid={errors.years_old ? "true" : "false"} />
                                    {errors.years_old && <div className="mt-2 text-danger">{errors.years_old}</div>}
                                </Form.Group>
                                <Form.Group as={Col} sm={3} controlId="formGridScholarship">
                                    <Form.Label>Escolaridad</Form.Label>
                                    <Form.Select defaultValue={data.scholarship} name="scholarship" onChange={e => setData('scholarship', e.target.value)} className={classNames({ 'is-invalid': (errors.scholarship) ? true : false, })} aria-invalid={errors.scholarship ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {scholarships.map((scholarship, index) => {
                                            return <option key={index} value={scholarship}>{scholarship}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.scholarship && <div className="mt-2 text-danger">{errors.scholarship}</div>}
                                </Form.Group>

                                <Form.Group as={Col} sm={3} controlId="formGridContact">
                                    <Form.Label>Medio de contacto</Form.Label>
                                    <Form.Select defaultValue={data.contact_medium} name="contact_medium" onChange={e => setData('contact_medium', e.target.value)} className={classNames({ 'is-invalid': (errors.contact_medium) ? true : false, })} aria-invalid={errors.contact_medium ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {mediums.map((medium, index) => {
                                            return <option key={index} value={medium}>{medium}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.contact_medium && <div className="mt-2 text-danger">{errors.contact_medium}</div>}
                                </Form.Group>

                                <Form.Group as={Col} sm={3} controlId="formGridPhone">
                                    <Form.Label>Teléfono de contacto</Form.Label>
                                    <Form.Control defaultValue={data.phone_number} type="tel" onChange={e => setData('phone_number', e.target.value)} className={classNames({ 'is-invalid': (errors.phone_number) ? true : false, })} aria-invalid={errors.phone_number ? "true" : "false"} />
                                    {errors.phone_number && <div className="mt-2 text-danger">{errors.phone_number}</div>}
                                </Form.Group>
                            </Row>
                        </Container>
                    </Card>

                    <Card className="mb-3">
                        <Container className="pt-2 pb-2">
                            <Card.Title className="mb-4">Domicilio</Card.Title>
                            <Row className="mb-3">
                                <Form.Group as={Col} sm={3} controlId="formGridStreet">
                                    <Form.Label>Calle</Form.Label>
                                    <Form.Control defaultValue={data.street} type='text' onChange={e => setData('street', e.target.value)} className={classNames({ 'is-invalid': (errors.street) ? true : false, })} aria-invalid={errors.street ? "true" : "false"} />
                                    {errors.street && <div className="mt-2 text-danger">{errors.street}</div>}
                                </Form.Group>

                                <Form.Group as={Col} sm={3} controlId="formGridExtNum">
                                    <Form.Label>Número externo</Form.Label>
                                    <Form.Control defaultValue={data.ext_number} type="number" min="1" onChange={e => setData('ext_number', e.target.value)} className={classNames({ 'is-invalid': (errors.ext_number) ? true : false, })} aria-invalid={errors.ext_number ? "true" : "false"} />
                                    {errors.ext_number && <div className="mt-2 text-danger">{errors.ext_number}</div>}
                                </Form.Group>

                                <Form.Group as={Col} sm={3} controlId="formGridIntNum">
                                    <Form.Label>Número Interno</Form.Label>
                                    <Form.Control defaultValue={data.int_number} type="number" min="1" onChange={e => setData('int_number', e.target.value)} className={classNames({ 'is-invalid': (errors.int_number) ? true : false, })} aria-invalid={errors.int_number ? "true" : "false"} />
                                    {errors.int_number && <div className="mt-2 text-danger">{errors.int_number}</div>}
                                </Form.Group>

                                <Form.Group as={Col} sm={3} controlId="formGridASuburb">
                                    <Form.Label>Colonia</Form.Label>
                                    <Form.Control defaultValue={data.suburb} type='text' onChange={e => setData('suburb', e.target.value)} className={classNames({ 'is-invalid': (errors.suburb) ? true : false, })} aria-invalid={errors.suburb ? "true" : "false"} />
                                    {errors.suburb && <div className="mt-2 text-danger">{errors.suburb}</div>}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Estado</Form.Label>
                                    <Form.Select defaultValue={data.state_id} onChange={e => setData('state_id', e.target.value)} className={classNames({ 'is-invalid': (errors.state_id) ? true : false, })} aria-invalid={errors.state_id ? "true" : "false"} >
                                        {states.map((state, index) => {
                                            return <option key={index} value={state.id}>{state.name}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.state_id && <div className="mt-2 text-danger">{errors.state_id}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Municipio</Form.Label>
                                    <Form.Control defaultValue={data.municipality} onChange={e => setData('municipality', e.target.value)} className={classNames({ 'is-invalid': (errors.municipality) ? true : false, })} aria-invalid={errors.municipality ? "true" : "false"} />
                                    {errors.municipality && <div className="mt-2 text-danger">{errors.municipality}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label>Código postal</Form.Label>
                                    <Form.Control defaultValue={data.zip_code} type="number" min="1" onChange={e => setData('zip_code', e.target.value)} className={classNames({ 'is-invalid': (errors.zip_code) ? true : false, })} aria-invalid={errors.zip_code ? "true" : "false"} />
                                    {errors.zip_code && <div className="mt-2 text-danger">{errors.zip_code}</div>}
                                </Form.Group>
                            </Row>
                        </Container>
                    </Card>

                    <Card className="mb-5">
                        <Container className="pt-2 pb-2">
                            <Card.Title className="mb-4">Información Bancaria</Card.Title>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridAccountNumber">
                                    <Form.Label>Número de cuenta</Form.Label>
                                    <Form.Control defaultValue={data.account_number} type="number" min="1" onChange={e => setData('account_number', e.target.value)} className={classNames({ 'is-invalid': (errors.account_number) ? true : false, })} aria-invalid={errors.account_number ? "true" : "false"} />
                                    {errors.account_number && <div className="mt-2 text-danger">{errors.account_number}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridClabe">
                                    <Form.Label>Clabe</Form.Label>
                                    <Form.Control defaultValue={data.clabe} type="number" min="1" onChange={e => setData('clabe', e.target.value)} className={classNames({ 'is-invalid': (errors.clabe) ? true : false, })} aria-invalid={errors.clabe ? "true" : "false"} />
                                    {errors.clabe && <div className="mt-2 text-danger">{errors.clabe}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridBank">
                                    <Form.Label>Banco</Form.Label>
                                    <Form.Select defaultValue={data.bank} onChange={e => setData('bank', e.target.value)} className={classNames({ 'is-invalid': (errors.bank) ? true : false, })} aria-invalid={errors.bank ? "true" : "false"} >
                                        <option key='blankChoice' hidden value />
                                        {banks.map((bank) => {
                                            return <option key={bank} value={bank}>{bank}</option>;
                                        })}
                                    </Form.Select>
                                    {errors.bank && <div className="mt-2 text-danger">{errors.bank}</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCommissionScheme">
                                    <Form.Label>Esquema de comisiones</Form.Label>
                                    <InputGroup>
                                        <Form.Control defaultValue={data.commission_scheme} type="number" min=".5" step=".5" onChange={e => setData('commission_scheme', e.target.value)} className={classNames({ 'is-invalid': (errors.commission_scheme) ? true : false, })} aria-invalid={errors.commission_scheme ? "true" : "false"} />
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                    {errors.commission_scheme && <div className="mt-2 text-danger">{errors.commission_scheme}</div>}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridVeritas">
                                    <Form.Label>Veritas</Form.Label>
                                    <InputGroup>
                                        <Form.Check
                                            inline
                                            name="veritas"
                                            type="radio"
                                            label="Viable"
                                            value="1"
                                            id="1"
                                            defaultChecked={(data.veritas == 1 ? true : false)}
                                            onChange={e => setData('veritas', 1)} className={classNames({ 'is-invalid': (errors.veritas) ? true : false, })} aria-invalid={errors.veritas ? "true" : "false"} />
                                        <Form.Check
                                            inline
                                            name="veritas"
                                            type="radio"
                                            label="No Viable"
                                            value="0"
                                            id="0"
                                            defaultChecked={(data.veritas == 0 ? true : false)}
                                            onChange={e => setData('veritas', 0)} className={classNames({ 'is-invalid': (errors.veritas) ? true : false, })} aria-invalid={errors.veritas ? "true" : "false"} />
                                    </InputGroup>
                                    {errors.veritas && <div className="mt-2 text-danger">{errors.veritas}</div>}
                                </Form.Group>
                            </Row>
                        </Container>
                    </Card>

                    {/* <!--begin:Register Modal--> */}
                    <Modal show={registerAsEmployeeModal} onHide={closeRegisterAsEmployeeModal} scrollable={true} size="md" id="register-prospect">
                        <Modal.Header closeButton>
                            <Modal.Title>Convertir Prospecto</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="pl-3 mb-3">
                                <h3 className='font-weight-normal text-center mb-0'>¿Deseas convertir este asesor en empleado?</h3>
                                <div className='text-center mb-0 mt-0'>
                                    <span className="bi bi-exclamation-triangle" style={style}></span>
                                </div>
                                <p className="font-weight-light text-center pb-2">Se eliminara este registro del Asesor y lo convertira a Empleado, esta acción es irreversible una vez realizada.</p>

                                <div className="mt-3 text-center">
                                    <Button size="sm" onClick={closeRegisterAsEmployeeModal} className='me-3' variant="secondary">Cancelar</Button>
                                    <Button size="sm" onClick={closeRegisterAsEmployeeModal} variant="primary" type="submit" name="convertAdviser" form="adviser-to-employee-form">Convertir</Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* <!--end:Register Modal--> */}

                    <div className="mt-3 text-center">
                        <Button onClick={() => window.history.back()} className='me-3' variant="secondary" type="normal">Regresar</Button>
                        <Button onClick={registerAsEmployee} variant="primary">Continuar</Button>
                    </div>
                </Form>
            </Container>
        </Row>
    );
}

Convertion.layout = page => <Layout children={page} title="Convertir Prospecto a Asesor"/>

export default Convertion
